import { Action } from "../actions";
import { StateManagerType } from "../store";

export function userReducer(store: StateManagerType) {
    // -- Load clients list when current user -- //
    store.stateSub('currentUser', async ({ currentUser }) => {
        if (!currentUser) {
            store.setState({ clients: null, selectedClient: null });
            return;
        }

        // "If user is a provider load client data"
        if ((currentUser?.clients || []).length) {
            store.action({ type: Action.clientsLoad });
        } else {
            store.setState({ clients: currentUser?.clients ? [] : null })
        }
    }, true);

    store.actionSub(Action.userLoad, (a) => {
        // a.data
        a
    })
}
