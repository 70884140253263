import { UserObj } from "@myalyce/common/models/user.model";
import { Dict, hasKey, hours, isType, minAppend, rand, min, msToSec, msToMin, msToHrs, arrToDict, wait, objKeyVals, nonValue } from "@giveback007/util-lib";
import { fitbitTokenApi } from "frontend/apis/fitbit-auth.api";
import { browserHistPush, store } from "frontend/store/store";
import { userApi } from "frontend/apis/user.api";
import { addNotification, removeNotification } from "./store.utils";
import { AllActions } from "frontend/store/actions";
import { toDate } from "@myalyce/common/";
import { AnyDate } from "@myalyce/common/common-types";
/** Get last - n item in the array */
export const arrLastOfN = <T>(arr: T[], lastOfN: number) => arr[(arr.length - 1) - lastOfN];

export function handleFitBitUrlParams(code: string, urlState: string) {
    
    const urlStateDict: Dict<string> = { };

    if (urlState) urlState.split(',').forEach((stateParam) => {
        const param = stateParam.split('=');
        urlStateDict[param[0]] = param[1]
    });

    const { localhost_redirect, path = '' } = urlStateDict;

    if (localhost_redirect) return window.location
        .replace('http://' + localhost_redirect + path + `?code=${code}&state=is_fitbit=true`);

    // wait for the UserObj to be assigned (after login & userObj initialization)
    const userSub = store.stateSub('currentUser', async ({ currentUser }) => {
        if (!currentUser) return;
        userSub.unsubscribe();

        // Create ID if Nonexistent
        if (!currentUser.id && currentUser._id) currentUser.id = currentUser._id
        
        // Authorizes code on our servers
        addNotification({ id: 'fitbit...1', text: 'Authorizing Fitbit ...', type: 'primary' });
        const newUserObj = await fitbitTokenApi.authorizeCode({ fitbitAuthCode: code, realmId: currentUser._id });
        removeNotification('fitbit...1');

        if (hasKey(newUserObj, 'errors')) {
            console.error(newUserObj);
            addNotification({ text: 'Failed to authorize fitbit', type: 'error' });
        } else {
            addNotification({ text: 'Successfully authorize fitbit', type: 'success' }, 7500);
            store.setState({ currentUser: getUserCodes(newUserObj, true) });

            // Removes auth code from url
            browserHistPush({ params: { code: null, state: null } })
        }
    }, true);
}

export function genRandText(length: number | { min: number, max: number }, useSpaces: boolean = true) {
    const char = (code = rand(97, 122)) => String.fromCharCode(code);

    const { min, max } = isType(length, 'number') ? { min: length, max: length } : length;
    const arr: string[] = [char()];
    const l = rand(min, max);

    for (let i = 1; i < l; i++)
        arr[i] = !useSpaces ? char() : rand(0, 5) ? char() : char(32);

    return arr.join('');
}

/** Takes any string and uses it as a hash code to generate a color, consistently generating the same color off of the same string */
export function stringToColour(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

export function getDictFromUrlParams(url = window.location) {
    const paramDict: Dict<string | undefined> = {};
    const searchParams = new URLSearchParams(url.search);
    searchParams.forEach((val, key) => paramDict[key] = val);

    return paramDict;
}

/** Turns a dictionary obj into url params
 * 
 * @param dict eg: `{ key1: 1, key2: 'val2' }`
 * @returns eg: `"?key1=1&key2=val2"`
 */
export function dictToUrlParams(dict: Dict<string | number>) {
    let search = '';

    objKeyVals(dict).forEach(({ key, val }, i) =>
        !nonValue(val) && (search += `${i ? '&' : '?'}${key}=${val}`));

    return search;
}

/** Capitalize the first letter of the string */
export const capFirst = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

export function getUserCodes(user: UserObj, returnWholeUser?: true): UserObj & { initials: string; profColor: string; codeName: string }
export function getUserCodes(user: UserObj, returnWholeUser: boolean = false): { initials: string; profColor: string; codeName: string } {
        
    const fi = user.firstName[0] || user.email[0];
    const li = user.lastName[0] || user.email[1];

    const initials = fi.toUpperCase() + li.toUpperCase();
    const profColor = stringToColour(user._id);

    const splitEmail = user.email.split('@').map((str) => capFirst(str));

    const first = (user.firstName || splitEmail[0]).slice(0, 4);
    const last = (user.lastName || splitEmail[1]).slice(0, 5);

    const obj = { initials, profColor, codeName: `${first}-${last}` };
    return returnWholeUser ? { ...user, ...obj } : obj;
}

const month = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
export const genDateStr = (date: AnyDate, format: 'default' | 'fitbit' = 'default') => {
    const dt = toDate(date);
    if (!dt) return null;

    const f = (n: number) => minAppend(n, 2, '0')

    const y = dt.getFullYear();
    const m = dt.getMonth();
    const d = dt.getDate();
    


    // '2021-10-16' format
    if (format === 'fitbit'){
        return `${y}-${f(m + 1)}-${f(d)}`;
    } 

    // 'October 16' format
    else {
        const cy = new Date().getFullYear()
        return `${capFirst(month[m])} ${f(d)}${(y != cy) ? `, ${y}` : ''}`;
    }
}

export const genChatTime = (date: AnyDate, showTime = true) => {
    const dt = toDate(date);
    if (!dt) return null;
    const now = new Date();
    const dif = now.getTime() - dt.getTime();

    let getDate = (dt:any) => {
        const dateStr = genDateStr(dt);

        if (!dateStr) throw new Error('Invalid Date');
        if (!showTime) return dateStr;

        let h = dt.getHours();
        const meridiem: 'AM' | 'PM' = h < 12 ? 'AM' : 'PM';
        h = h < 13 ? h : h - 12;

        const finalDate = dateStr + ` ${h}:${minAppend(dt.getMinutes(), 2, '0')} ${meridiem}`;
        return finalDate
    }

    if (dif < 0) return getDate(dt)
    else if (dif < min(1)) {
        const s = Math.ceil(msToSec(dif));
        return `${s} seconds ago`
    } else if (dif < hours(1)) {
        const m = Math.round(msToMin(dif));
        return `${m} minute${m > 1 ? 's' : ''} ago`
    } else if (dif < hours(24) && dt.getDate() === now.getDate()) {
        const h = Math.round(msToHrs(dif));
        return `${h} hour${h > 1 ? 's' : ''} ago`
    } else return getDate(dt);
}

export const regexpUtil = {
    name: /^[a-z ,.'-]+$/i,
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
} as const;

//partial reimplementation of initClientData for getting just one client
export async function getUserFitBitToken(parentUser: any) {
    let userRes;
    if(parentUser._id) userRes = await userApi.getIds([parentUser._id]);
    else userRes = await userApi.getIds([parentUser._id]);
    
    if (userRes.type === 'ERROR') {
        console.error('Failed to load client data!', parentUser)
        return;
    }

    const clients = userRes.data.found.map(u => getUserCodes(u, true));
    const clientDict = arrToDict(clients, '_id');

    // TODO: in the future this will be bypassed by automagick token refreshing
    // ! this way works for now, but has potential issues when multiple people are refreshing same token
    const statusRes = await fitbitTokenApi.checkAuths(clients.map(x => x._id));

    if (hasKey(statusRes, 'errors')) {
        console.error('Failed to load client data!', parentUser)
        return;
    }

    const promises = statusRes.map(async (x) => {
        const user = clientDict[x.userId];
        if (x.fitbitIsActive && !x.tokenIsActive) {
            const result = await fitbitTokenApi.refresh(x.userId);
            if (hasKey(result, 'errors')) {
                clientDict[x.userId].fitbit = null;
                console.error('Failed to load client data!', user.email);
            }
            clientDict[x.userId] = getUserCodes((result as any), true);
        } 
    })

    await Promise.all(promises);
    if(parentUser._id) return clientDict[parentUser._id];
    else return clientDict[parentUser._id]; 
    //this.setState({fitbit:something});
}

type btnFct = {
    type: 'action';
    data: AllActions;
} | {
    type: 'function';
    data: () => any;
} | {
    type: 'route';
    data: string;
}
export function navBtnHandler(fct: btnFct, closeNavOnClick?: boolean) {
    const closeOnClick = isType(closeNavOnClick, 'undefined') ? true : closeNavOnClick;
    
    if (fct.type === 'action') store.action(fct.data);
    if (fct.type === 'function') fct.data();
    if (fct.type === 'route') browserHistPush({ route: fct.data });

    if (closeOnClick)
        wait(125).then(() => store.setState({ drawerOpen: false }));
}

/** Mins to hours rounded to 1 decimal places */
export const minToHr = (mins: number) => Math.round(mins * 10 / 60) / 10;