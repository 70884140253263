import { platform } from 'frontend/shared';
import React, { Component } from 'react'
import ChatroomsContainer from 'frontend/components/UserPlatformTemplates/Chatroom.components';
import { linker, UserObj_S } from 'frontend/store/store';


interface Props {
    selectedClient?: UserObj_S | null;
}
interface State {
    
}

class Chatrooms extends Component<Props, State> {
    state = {};

    componentDidMount() {}

    render() {

        return (
            <div className={`p-2`}> 
                <ChatroomsContainer 
                    platform={platform}
                    parentUser={this.props.selectedClient}
                />
            </div>
        )
    }
}

export default linker((s) => ({ selectedClient: s.selectedClient}), Chatrooms);
