import { initModel } from "..";
import { BaseObj } from "./_base.model";

export const type = 'comment';
export class CommentObj extends BaseObj<typeof type> {
        message: string = '';
        authorId: string = '';
        replyTo: string = '';
        attachments: string[] = new Array();
        replies: string[] = new Array();
        users: string[] = new Array();

    constructor(p?: Partial<CommentObj>) {
        super(type, p);
        initModel(this, p);
    }
}
