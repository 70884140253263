/**
 * 
 * Create a ReadableStream of sensor data modeled after the Insertable Streams API
 * 
 */

export default class DataStreamTrackProcessor {

    constructor({track}){

        this.track = track
        this.subid = null

        this.readable = new ReadableStream({
            start: this.start,
            pull: this.pull,
            cancel: this.cancel,
            // type,
            // autoAllocateChunkSize
        }, {
            // highWaterMark,
            // size()
        })
    }


// --------------------------- Readable Stream Functions ---------------------------
    start = (controller) => {
    
        // Start Placing Track Data into the ReadableStream
        if (this.track){
    
            this.track.data.forEach(val => {
                controller.enqueue(val)
            })
    
            this.subid = this.track.subscribe((val) => {
                controller.enqueue(val)
            })
        }
    }

    pull = (controller) => {

    }

    cancel = () => {
        if (this.track) this.track.unsubscribe(this.subid)
    }
}