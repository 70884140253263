import Router from "./Router.js";

export default class Embedder extends Router{

    get [Symbol.toStringTag]() { return 'Embedder' }

    constructor(settings) {
        super('device', settings)

    }


    // Note: Must actually embed on hardware here
    process = async (args) => {
        if (!Array.isArray(args)) args = [args]
        let data = this.settings.function(...args) // unfold arguments
        this.ondata({data}) // pass to subscriptions
        return data
    }

    // Hook for Insertable Streams Transform
    transform = async (chunk, controller) => {
        let res = this.settings.function(chunk) // apples function on hardware (to implement)
        controller.enqueue(res)
    }

}