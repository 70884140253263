import WebSocket from "./WebSocket.js";
import randomId from "../utils/id.js";

export default class Router extends EventTarget{

    get [Symbol.toStringTag]() { return 'Router' }

    constructor(type, settings) {
        super()
        
        this.id = randomId()
        this.type = type
        this.settings = settings

        // Create Socket
        let needsSocket = ['server', 'stream']
        if (needsSocket.includes(type)){
            this.socket = settings.socket ?? new WebSocket(settings.server, settings.auth) // server and stream needs WebSocket
            if (type === 'stream') this.socket.addEventListener('message', this.ondata) // only stream needs an ambient ondata listener
        }

        this.callback = (settings.callback instanceof Function) ? settings.callback : () => {}

    }

    process = async (data) => {return data}

    // Largely Replaced by Child-Specific Event Listeners
    subscribe = (callback) => {
        this.callback = callback
    }

    unsubscribe = () => {
        this.callback = null
    }

    ondata = (data) => {
        if (!('service' in data))  this.callback(data) // ignore service callbacks
    }

    
}