import { UserObj } from "@myalyce/common/models/user.model";
import { StateManager, stateManagerReactLinker } from "@giveback007/browser-utils";
import { Dict, nonValue, nullOrEmpty, objKeyVals } from "@giveback007/util-lib";
import { Notify } from "frontend/types/notify.types";
import { createBrowserHistory } from "history";
import { AllActions } from "./actions";
import { ClientEventObj } from "@myalyce/common/models/client-event.model";
import { initReducers } from "./reducers";
import { ModalState } from "frontend/components/TopModal.component";
import { getDictFromUrlParams } from "frontend/utils/general";

export type UserObj_S = UserObj & { initials: string; profColor: string; codeName: string };

export type State = {
    appIsInitialized: boolean;
    drawerOpen: boolean;
    useEruda: boolean;

    /** innerWidth and innerHeight in px of window,
     *  auto updated 200ms after all window resize events finish  */
    windowSize: { height: number; width: number; }

    /** Modal state for the main UI modal */
    topModal: ModalState | null;
    
    /** Current logged in user */
    currentUser: UserObj_S | null;
    isLoggedIn: boolean;

    /** Clients that correspond to the current logged in provider, `null` indicates the lists hasn't tried to load yet. */
    clients: UserObj_S[] | null;

    /** Client selected for viewing by provider.*/
    selectedClient: UserObj_S | null;

    /** Client events dict, `undefined` indicates the events haven't been loaded yet */
    clientEvents: Dict<ClientEventObj[] | 'loading' | undefined>;

    /** Allows for dynamically loading users. `null` indicates userId didn't return a user obj. `undefined` indicates didn't try to load said user yet */
    users: Dict<UserObj_S | 'loading' | undefined | null>;

    /** Listen to the UserPlatform Map.*/
    // notifications: Dict<UserObj_S | 'loading' | undefined | null>;
    chatroom: any[];
    comment: any[];
    dataInstance: any[];
    discussion: any[];
    group: any[];
    authorization: any[];
    notification: any[];
    event: any[];


    /** Stored redirect for when the user wasn't able to access due to not being logged in. */
    redirectURL: string | null;

    /** For displaying errors on the UI. */
    displayNotify: Notify[];
    loaders: {
        logout: boolean;
        clients: boolean;
        fitbitAuth: boolean;
    };
}

const initState: State = {
    appIsInitialized: false,
    drawerOpen: false,
    useEruda: false,
    topModal: null,
    isLoggedIn: false,
    currentUser: null,
    selectedClient: null,
    clients: null,
    clientEvents: {},
    windowSize: { height: globalThis.innerHeight, width: globalThis.innerWidth },
    users: {},
    // TODO: implement and check if it works as intended in edge case
    redirectURL: null,
    displayNotify: [],
    loaders: {
        logout: false,
        clients: false,
        fitbitAuth: false,
    },

    // UserPlatform Objects to Update
    chatroom: [],
    comment: [],
    dataInstance: [],
    discussion: [],
    group: [],
    event: [],
    authorization: [],
    notification: [],
}

// TODO:
// -- STORE --
// 1. Url param link (auto-magic)
    // 1.1 - After spreading localStorage keys spread linked params
    // 1.2 - Edit the url params on store changes
    // 1.3 - Listen for url param changes so 'back' function would reflect in store
    // 1.4 - Add browserHist and browserHistPush() to store to maintain store link when navigating
// 2. Auto management of pulling data (auto-magic)
    // Dict<{ type: 'data', data: ClientEventObj[] } | { type: 'loading' } | { type: 'error', message: 'error message here' } | undefined>

export const browserHist = createBrowserHistory();
export function browserHistPush(opts: { route?: string, params?: Dict<string | number | null> }) {
    if (nullOrEmpty(opts)) return;

    let didChange = false;
    let { pathname, search } = browserHist.location;

    if (opts.route) {
        const newPath = new URL(opts.route, location.href).pathname;
        if (pathname !== newPath) didChange = true;

        pathname = newPath;
    }

    if (opts.params) {
        search = '';

        const old = getDictFromUrlParams();
        const dict = { ...old, ...opts.params };

        objKeyVals(dict).forEach(({ key, val }, i) => {
            if (old[key] !== val) didChange = true;

            if (!nonValue(val))
                search += `${i ? '&' : '?'}${key}=${val}`;
        });
    }
    
    if (didChange)
        browserHist.push({ search, pathname });
}

export type StateManagerType = StateManager<State, AllActions>;
export const store: StateManagerType = new StateManager(initState, {
    id: 'MyAlyce-V1',
    useKeys: ['redirectURL']
});

export const linker = stateManagerReactLinker<State, AllActions>(store);
initReducers(store)
