import React, { Component } from 'react'
import { randomId } from '@myalyce/common';
import {comState} from 'frontend/shared'
import { ContainerProps, AuthorizationProps, CreatorProps, removeContainerRefresh, setupContainerRefresh } from "./Templates.shared";
import { linker, store} from 'frontend/store/store';
import { UserObj } from "@myalyce/common/models/user.model";
import { getUserCodes } from "frontend/utils/general";

interface S {}

export class AuthCreator extends Component<CreatorProps, S> {
        
    state = {
        id:randomId('authCreator'),
        deleted:false,
        parentUser:this.props.parentUser
    }

    constructor(props:CreatorProps) {
        super(props);   
    }

    componentDidMount() {
        //console.log(this.props.parentUser)
        
    }

    componentWillUnmount() {

    }

    _submit = () => {
        let select1 = (document.getElementById(this.state.id+'_requesting') as HTMLSelectElement);
        let requesting = select1.options[select1.selectedIndex].value;
        let otherId = (document.getElementById(this.state.id+'_userId') as HTMLInputElement)?.value;
        let otherName = (document.getElementById(this.state.id+'_userName') as HTMLInputElement)?.value;
        let select2 = (document.getElementById(this.state.id+'_authorizations') as HTMLSelectElement);
        let auths = select2.options[select2.selectedIndex].value;
        let structs = (document.getElementById(this.state.id+'_structs') as HTMLInputElement)?.value.split(',');
        let groups = (document.getElementById(this.state.id+'_groups') as HTMLInputElement)?.value.split(',');
        let expires = (document.getElementById(this.state.id+'_expires') as HTMLInputElement)?.value;
        let authorizerId :string | undefined, authorizedId: string | undefined,  authorizerName: string | undefined,  authorizedName: string | undefined;
        if(!expires) expires = 'NEVER';

        if(requesting === 'request') {
            authorizerId = otherId;
            authorizerName = otherName;
            authorizedId = this.props.parentUser?._id;
            authorizedName = this.props.parentUser?.name;
            if(!authorizedName) authorizedName = this.props.parentUser?.email;
            if(!authorizedName) authorizedName = 'Me';
        } else if (requesting === 'give') {
            authorizerId = this.props.parentUser?._id;
            authorizerName = this.props.parentUser?.name;
            authorizedId = otherId;
            authorizedName = otherName;
            if(!authorizerName) authorizerName = this.props.parentUser?.email;
            if(!authorizedName) authorizedName = 'Me';
        }

        console.log(this.props.parentUser);
        if(authorizerId && authorizerName && authorizedId && authorizedName) {
            this.props.platform.authorizeUser(
                this.props.parentUser,
                authorizerId,
                authorizerName,
                authorizedId,
                authorizedName,
                [auths],
                structs,
                groups,
                undefined,
                expires
            );
        } else {
            console.log('incomplete form data', authorizerId, authorizerName, authorizedId, authorizedName);
        }

        this.props.updateParent();
    }

    render() {
        return (
            <div id={this.state.id} className={this.props.editing ? "modal active" : "modal"} >
            <a  className="modal-overlay" aria-label="Close" onClick={() => {this.props.toggleEditor()}}></a>
            <div className="modal-container">
                <div className="modal-header">
                <a className="btn btn-clear float-right" aria-label="Close" onClick={() => {this.props.toggleEditor()}}></a>
                <div className="modal-title h5">New Authorization</div>
                </div>
                <div className="modal-body">
                <div className="form-group">
                    <label className="form-label">Request Type</label>
                    <select id={this.state.id+'_requesting'} className="form-select" >
                            <option value={undefined} selected>None</option>
                            <option value='give'>Granting</option>
                            <option value='request'>Requesting</option>
                    </select>    
                </div>
                <div className="form-group">
                    <label className="form-label">Other User Id</label>
                    <input id={this.state.id+'_userId'} className="form-input" type="text"/>
                </div>
                <div className="form-group">
                    <label className="form-label">Other User Name (can be anything)</label>
                    <input id={this.state.id+'_userName'} className="form-input" type="text"/>
                </div>
                <div className="form-group">
                    <label className="form-label">Authorizations</label>
                    <select id={this.state.id+'_authorizations'} className="form-select">
                            <option value={undefined} selected>None</option>
                            <option value='provider'>Provider (full access)</option>
                        </select>
                </div>
                <div className="form-group">
                    <label className="form-label">Only Specific Data (ids, separated by commas)</label>
                    <input id={this.state.id+'_structs'} className="form-input" type="text"/>
                </div>
                <div className="form-group">
                    <label className="form-label">Groups (separated by commas)</label>
                    <input id={this.state.id+'_groups'} className="form-input" type="text"/>
                </div>
                <div className="form-group">
                    <label className="form-label">Expires)</label>
                    <input type='date' id={this.state.id+'_expires'} className="form-input"/>
                </div>
            </div>
            <div className='modal-footer'>
                <button id={this.state.id+'_submit'} className="btn btn-primary input-group-btn" onClick={this._submit}>Submit</button>
            </div>
        </div>
        </div>
        )
    }
}



export class Authorization extends Component<AuthorizationProps, S> {
        
    state = {
        id:randomId('authorization'),
        auth:this.props.platform.authorizationProps,
        deleted:false
    }

    sub: number = 0;

    constructor(props:AuthorizationProps) {
        super(props);  
        this.state.auth = this.props.auth; 
    }

    componentDidMount() {
        if(this.state.auth?._id) {
            comState.setState({[this.state.auth._id]: this.state.auth});
            comState.subscribe(this.state.auth._id,()=>{
                this.render(); //state updated re-render component
            });

            let s = comState.subscribeTrigger('socketResult',(res:any)=> {
                if(res.msg === 'deleted' && res.data === this.state.auth._id) {
                    this.setState({deleted:true});
                }
                if(Array.isArray(res.data)) {
                    res.data.forEach((struct:any) => {
                        if(struct.structType === 'notification') {
                            if(struct.parent._id === this.state.auth._id) {
                                this.props.platform.resolveNotifications([struct],true,this.props.platform.currentUser);
                            }
                        }
                    });
                }
            });

            this.sub = (s as number);
        }
    }

    componentWillUnmount() {
        if(this.state.auth?._id) {
            comState.unsubscribeAll(this.state.auth._id);
            comState.unsubscribeTrigger('socketResult',this.sub);
        }
    }

    _click = async () => {

        // // Josh System
        // await this.props.platform.getUserDataByAuthorizationFromServer(this.state.auth); //pull that user's data

        this.props.platform.getUserDataByAuthorizationFromServer(this.state.auth).then(res => {
            const client = new UserObj(res.data.user)
            const selectedClient = getUserCodes(client, true)
            store.setState({selectedClient})
        })
    }

    _deleteAuth = () => {
        this.props.platform.deleteAuthorizationOnServer(this.state.auth);
        this.setState({deleted:true});
    }

    render() {

        let additionalData:any = 'none';
        if(Array.isArray(this.state.auth.structs)) additionalData = [...this.state.auth.structs];
        let subtitle = `${this.state.auth.authorizations} · ${additionalData} · ${this.state.auth.expires.toLowerCase()} · ${this.state.auth.status.toLowerCase()}`


        return (
            <div id={this.state.id}>
                { !this.state.deleted &&

                <div className="tile tile-centered p-2">
                <div className="tile-icon">
                <div className="example-tile-icon">
                    <i className="icon icon-file centered"></i>
                </div>
                </div>
                <div className="tile-content">
                <div className="tile-title">{this.state.auth.authorizerName} to {this.state.auth.authorizedName}</div>
                <small className="tile-subtitle text-gray">{subtitle}</small>
                <small className="tile-subtitle text-gray">{...this.state.auth.groups}</small>
                </div>
                <div className="tile-action">
                    { this.state.auth.authorizerId !== this.props.platform?.currentUser?._id && this.state.auth.status === 'OKAY' && this.state.auth.authorizations.includes('provider') &&
                            <button id={this.state.id+'_access'} onClick={this._click} className="btn btn-link">Access</button>
                        }
                        <button id={this.state.id+'_delete'} onClick={this._deleteAuth} className="btn btn-link">Delete</button>                
                        </div>
                </div>
                }
            </div>
        )
    }
}



class AuthorizationsContainer extends Component<ContainerProps, S> {
        
    state = {
        id:randomId('authorizations'),
        structs:this.props.structs,
        editing:false,
        parentUser:this.props.parentUser
    };

    editorRef = React.createRef<AuthCreator>()
    structRefs = [React.createRef<Authorization>()]

    sub:number|undefined = 0

    constructor(props:ContainerProps) {
        super(props);   
    }

    componentDidMount() {
        this.check();
        setupContainerRefresh(this,'authorization',this.sub);
    }

    componentWillUnmount() {
        removeContainerRefresh(this.sub);
    }


    check = async() => {
        // let structs = [];
        // if(this.state.structs.length === 0) {
        //     structs = await authApi.search({'ownerId': this.props.parentUser._id})
        // }

        // if(structs.length > 0) {
        //     this.structRefs = [];
        //     structs.forEach(()=>{
        //         this.structRefs.push(React.createRef<Authorization>());
        //     });
        //     this.setState({structs:structs});
        // }
    }

    _click = () => {
        if(this.state.editing) this.setState({editing:false});
        else this.setState({editing:true});
        this.check();
    }

    _refresh = () => {
        this.check();
    }

    _setuprole = () => {
        this.props.platform.setAuthorizationsByGroupOnServer(this.props.parentUser)
    }

    render() {

        const authsToView = (this.props.structs) ? this.props.structs.filter(o => o.ownerId === this.props.parentUser?._id) : []

        return (
            <div className="panel">
                <div className="panel-header">
                    <div className="panel-title h6 float-left">Current Authorizations</div>
                    <div className="float-right">
                        <button className="btn btn-primary mr-2 btn-sm" onClick={this._click}>New</button>
                        { this.props.structs && this.props.structs.length === 0 && 
                            <button className="btn btn-secondary btn-sm"  onClick={this._refresh}>Refresh</button>
                        }
                    </div>
                </div>
                <div className="panel-body">
                    <div>
                        { authsToView.length > 0 &&
                            authsToView.map((item: any, idx:number) => 
                                <Authorization 
                                    platform = {this.props.platform}
                                    parentUser = {this.props.parentUser}
                                    auth = {item}
                                    ref = {this.structRefs[idx]}
                                />
                            )
                        }
                    </div>
                    { this.props.parentUser?.userRoles?.length > 0 &&
                        <div>
                            {this.props.parentUser.userRoles.map((role:any) => {
                                <div>Role: {role}</div>
                            })}
                            <button id={this.state.id+'_rolebutton'} onClick={this._setuprole}>Refresh Role Auths</button>
                        </div>
                    }
                </div>
                {this.state.editing &&
                    <AuthCreator 
                        platform = {this.props.platform}
                        parentUser = {this.props.parentUser}
                        ref = {this.editorRef}
                        updateParent={this._click}
                        editing = {this.state.editing}
                        toggleEditor = {this._click}
                    />
                }
                <div className="panel-footer"/>
            </div>
        )
    }
}

export default linker((s) => ({ structs: s.authorization}), AuthorizationsContainer);
