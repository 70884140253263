import React from 'react'
import { linker } from "frontend/store/store";
import { Notify } from 'frontend/types/notify.types';
import { removeNotification } from 'frontend/utils/store.utils';

import css from './DisplayNotify.module.scss';

function DisplayNotify(p: { displayNotify: Notify[] }) {
    const notifications = p.displayNotify.map(({ type, text, id }) => (
        <div className={`toast toast-${type} ${css.notify_toast}`}>
            <div className={css.notify_toast_text}>{text}</div>
            <button
                className='btn btn-clear float-right'
                onClick={() => removeNotification(id)}
            ></button>
        </div>
    ));
    
    return <div
        className={css.notify_container}
    >{notifications}</div>
};

export default linker(({ displayNotify }) => ({ displayNotify }), DisplayNotify);
