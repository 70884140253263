import { platform } from 'frontend/shared';
import React, { Component } from 'react'
import RealTimeContainer from 'frontend/components/realtime/stream.components';
import { linker, UserObj_S } from 'frontend/store/store';


interface Props {
    selectedClient?: UserObj_S | null;
    currentUser?: UserObj_S | null;
}
interface State {
    
}

class Realtime extends Component<Props, State> {
    state = {};

    componentDidMount() {}

    render() {

        return (
            <div className={`p-2`}> 
                <RealTimeContainer 
                    platform={platform}
                    parentUser={this.props.selectedClient}
                    currentUser={this.props.currentUser}
                />
            </div>
        )
    }
}
export default linker((s) => ({ selectedClient: s.selectedClient, currentUser: s.currentUser}), Realtime);
