import { wait } from "@giveback007/util-lib";
import { Auth } from "frontend/auth";
// import { addNotification, browserHist, toggleLoader } from "../store";
import { initializeCurrentUser } from "frontend/utils/init-user";
import { Action } from "../actions";
import { platform } from 'frontend/shared'
import { browserHistPush, StateManagerType } from "../store";
import { addNotification, toggleLoader } from "frontend/utils/store.utils";

export function authReducer(store: StateManagerType) {
    // -- LOGIN/LOGOUT Reducers -- //
    store.actionSub([
        Action.loginRefresh,
        Action.loginSuccess,
        Action.logOut,
    ], async (a, s) => {
        const f = store.setState;
        switch (a.type) {
            case 'LOGIN_REFRESH':
                const res = await Auth.login();
                if (res.type === 'REFRESH') {
                    // successful refresh run here 
                    return store.action({ type: Action.loginSuccess, data: res.data });
                } else {
                    // handle unsuccessful refresh
                    return f({ isLoggedIn: false, currentUser: null, appIsInitialized: true });
                }
            case 'LOGIN_SUCCESS':
                const currentUser = await initializeCurrentUser(a.data);
                if (currentUser) {
                    if (s.redirectURL) {
                        const path = s.redirectURL
                        wait(0).then(() => browserHistPush({ route: path }));
                    }

                    await f({ currentUser: currentUser });

                    // Preset selectedClient
                    const state = store.getState()
                    if (!state.selectedClient)  f({selectedClient: state.currentUser})

                    if(!platform.currentUser) {   //initializes UserPlatform
                        platform.setup(currentUser,SERVER_URI);
                    }

                    return f({
                        // currentUser: getUserCodes(currentUser, true),
                        isLoggedIn: true,
                        appIsInitialized: true,
                        redirectURL: null,
                    })
                } else {
                    return f({ isLoggedIn: false, currentUser: null, appIsInitialized: true });
                }
            case 'LOGOUT':
                toggleLoader('logout', true);
                
                const resOut = await Auth.logout();
                if (resOut.type === 'FAIL') {
                    log(resOut);
                    addNotification({ text: 'FAILED TO LOGOUT', type: 'error' });
                }

                // wait because realm-view sometimes doesn't register before view render
                await wait(500);
                toggleLoader('logout', false);

                return f({ isLoggedIn: false, currentUser: null, appIsInitialized: true });
        }
    });
}