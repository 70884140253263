import * as parseutils from "../utils/parse.browser.js";
import randomId from "../utils/id.js";

export default class Websocket {

    constructor(url='http://localhost', auth){
        url = new URL(url) 

        if (url.protocol === 'http:') this.ws = new WebSocket(`ws://` + url.host, [auth])
        else if (url.protocol === 'https:') this.ws = new WebSocket(`wss://` + url.host, [auth]);
        else {console.log('invalid protocol'); return;}

        this.sendBuffer = []
        this.callbacks = new Map()

        this.ready = false

        this.ws.onopen = this._onopen
        this.ws.onerror = this._onerror
        this.ws.onmessage = this._onmessage
        this.ws.onclose = this._onclose

        window.onunload = window.onbeforeunload = () => {
            this.ws.onclose = () => {}
            this.close()
        }

    }

    _onopen = (res) => {
        this.ready = true
        this.sendBuffer.forEach(msg => this.ws.send(msg))

        this.onopen()
    }
    
    _onclose = () => {
        this.ready = false
        this.onclose()
    }

    _onerror = (e) => { 
        console.error(e)
        this.onerror(e)
    }
    _onmessage = (res) => { 


        try {
            let parsed = parseutils.safeParse(res.data)

            if (parsed.error) console.error(parsed.error)
            else {
                let callbackId = parsed.callbackId
                let data = parsed

                // Run Callback
                if (callbackId){
                    data = data.data
                    let callback = this.callbacks.get(callbackId)
                    if (callback) callback(data)
                }
                
                // Parse Stripped Data Message
                if (data) this.onmessage(data)
            }

        } catch (e) {
            console.error('Error parsing WebSocket message from server: ', res.data , e)
        }
    }

    onopen = () => {}
    onclose = () => {}
    onerror = () => {}
    onmessage = () => {}

    addEventListener = (name, callback) => {
        if (name === 'message') this.ws.addEventListener(name, (res) => {callback(JSON.parse(res.data))}) // parse messages
        else this.ws.addEventListener(name, callback) // otherwise pass raw response
    }

    close = () => {
        this.ws.close();
    }

    send = (data, service) => {

        return new Promise(resolve => {

            // Allow Awaiting WebSocket Calls
            let callbackId = randomId()
            let callback = (data) => {
                resolve(data)
                this.callbacks.delete(callbackId)
            }

            this.callbacks.set(callbackId, callback)

            // Create Message with Proper Stringification
            let o = {data, callbackId, service}
            let msg = (service === 'offload') ? parseutils.safeStringify(o) : JSON.stringify(o) 

            if (this.ready){

                // Actually Send
                this.ws.send(msg)

            } else this.sendBuffer.push(msg)

        })


    }

}