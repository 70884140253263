import React, { useState } from 'react';
import { objExtract, sec } from '@giveback007/util-lib';
import { userApi } from 'frontend/apis/user.api';
import { Action } from 'frontend/store/actions';
import { store, UserObj_S } from 'frontend/store/store';
import { regexpUtil } from 'frontend/utils/general';
import { SubmitHandler, useForm } from 'react-hook-form';
// import { errCss, ErrMsg } from '../dashboard.util';
import { closeTopModal } from 'frontend/components/TopModal.component';
import { addNotification } from 'frontend/utils/store.utils';
import { errCss, ErrMsg } from 'frontend/views/dashboard/dashboard.util';

export const openClientInfoFormModal = (opts: { client: boolean}) => store.setState({ topModal: {
    title: opts.client ? 'Edit Client Info' : 'Edit Your Info',
    body: <AddClientInfoForm
        selectedClient={opts.client ? store.getState().selectedClient : store.getState().currentUser}
        onSubmitSuccess={closeTopModal}
        onCancel={closeTopModal}
        notes={opts.client}
    />,
    setUrlId: opts.client ? 'add_client_event' : undefined
} });

type FormData = {
    firstName: string;
    lastName: string;
    fullName: string;
    birthday: string;
    sex: 'male' | 'female';
    clientInfo: string;
}

type addInfoProps = { selectedClient: UserObj_S | null; onSubmitSuccess: () => any; onCancel: () => any; notes: boolean; };
export function AddClientInfoForm(p: addInfoProps) {
    const sc = p.selectedClient;
    if (!sc) return null;

    const [loading, loadState] = useState(false);
    const onSubmit: SubmitHandler<FormData> = (data) => {
        const { firstName, lastName, fullName } = data;
        const name = [firstName, fullName, lastName].map(nm => nm.trim()).filter(nm => nm).join(' ');
        const send = { ...data, fullName: name};
        loadState(true);
    
        userApi.patch(sc._id, send).then((res) => {
            loadState(false);
            if (res.type === 'ERROR') {
                addNotification({ text: 'Failed to update info, please check for errors and try again', type: 'error' }, sec(15));
            } else {
                addNotification({ text: `Updated information for "${res.data.fullName}"`, type: 'success' }, sec(7.5));
                p.onSubmitSuccess();
                store.action({ type: Action.clientsLoad })
            }
        });
    };
    
    const bd = sc.birthday || '';
    const fullName = (() => {
        const nameArr = sc.fullName.split(' ');
        return nameArr.slice(1, nameArr.length - 1).join(' ');
    })();

    const { register, handleSubmit, formState, watch } = useForm<FormData>({
        defaultValues: {
            ...objExtract(sc, ['firstName', 'lastName', 'clientInfo', 'sex']),
            fullName, birthday: bd,
        },
    });
    
    const { errors: err } = formState;
    const fName = watch('firstName');
    const lName = watch('lastName');

    return <>
        <div className="form-horizontal">

            {/* First Name */}
            <div className={errCss('form-group', err.firstName)}>
                <div className="col-3">
                    <label className="form-label">First Name *</label>
                </div>
                <input
                    className="form-input col-9"
                    placeholder="First Name"
                    disabled={loading}
                    type="text"
                    {...register('firstName', {
                        required: 'First name is required',
                        pattern: {
                            value: regexpUtil.name,
                            message: 'Please use valid characters for first name.'
                        }
                    })}
                />
                <ErrMsg err={err.firstName} />
            </div>

            {/* Last Name */}
            <div className={errCss('form-group', err.lastName)}>
                <div className="col-3">
                    <label className="form-label">Last Name *</label>
                </div>
                <input
                    className="form-input col-9"
                    placeholder="Last Name"
                    disabled={loading}
                    type="text"
                    {...register('lastName', {
                        required: 'Last name is required.',
                        pattern: {
                            value: regexpUtil.name,
                            message: 'Please use valid characters for last name.'
                        }
                    })}
                />
                <ErrMsg err={err.lastName} />
            </div>

            {/* Full Name */}
            <div className={errCss('form-group', err.fullName)}>
                <div className="col-3">
                    <label className="form-label">Full Name</label>
                </div>
                <div className="input-group col-9">
                    <span className="input-group-addon">{fName}</span>
                    <input
                        type="text"
                        className="form-input"
                        disabled={loading}
                        placeholder="...other parts of name"
                        autoComplete="none"
                        {...register('fullName', {
                            required: false, pattern: {
                                value: regexpUtil.name,
                                message: 'Please use valid characters for full name.'
                            }
                        })}
                    />
                    <span className="input-group-addon">{lName}</span>
                </div>
                <ErrMsg err={err.fullName} />
            </div>

            {/* DOB */}
            <div className={errCss('form-group', err.birthday)}>
                <div className="col-3">
                    <label className="form-label">Date Of Birth *</label>
                </div>
                <input
                    className="form-input col-9"
                    disabled={loading}
                    type="date"
                    {...register('birthday', { required: 'Please input date of birth' })}
                />
                <ErrMsg err={err.birthday} />
            </div>

            {/* SEX */}
            <div className={errCss('form-group', err.sex)}>
                <div className="col-3">
                    <label className="form-label">Sex *</label>
                </div>
                <select
                    className="form-select col-9"
                    disabled={loading}
                    {...register('sex', { required: 'This field is required, please make a selection' })}
                >
                    <option></option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                </select>
                <ErrMsg err={err.sex} />
            </div>

            {/* NOTES */}
            {p.notes ? <div className='form-group'>
                <label className="form-label">Notes</label>
                <textarea
                    className="form-input"
                    placeholder="Client Related Notes"
                    disabled={loading}
                    {...register('clientInfo', { required: false })}
                />
            </div> : null}
        </div>

        <button
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
            className={`btn btn-primary${loading ? ' loading' : ''}`}
        >Submit</button>
        <button
            className="btn btn-link"
            aria-label="Cancel"
            disabled={loading}
            onClick={p.onCancel}
        >Cancel</button>
    </>;
}
