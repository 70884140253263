import React from 'react'

import { UserPlatform } from "frontend/js/UserPlatform";
//import { randomId } from '@myalyce/common';
import {comState, platform} from 'frontend/shared'
import { authApi } from 'frontend/apis/authorization.api';
import { groupApi } from 'frontend/apis/group.api';
import { getUserCodes } from 'frontend/utils/general';
import { UserObj } from '@myalyce/common/models/user.model';

export type UserProps = {platform: UserPlatform, parentUser: any};
export interface ContainerProps {platform: UserPlatform; parentUser: any; currentUser?: any; structs?: any[];}; // structs passed through linker
export type CreatorProps = {updateParent:any, editing:boolean, toggleEditor:Function, onSubmitSuccess?:Function, onCancel?: Function} & UserProps
export type CommentParentProps = {room: any, parent:any} & UserProps;
export type CommentProps = {comment:any, setting:string} & CommentParentProps
export type CommentCreatorProps = {updateParent:any, onsubmit?:Function} & CommentParentProps;
export type DiscussionProps = {discussion:any} & UserProps;
export type ChatroomProps = {chatroom:any, onsubmit?:Function} & UserProps;
export type ScheduleTileProps = {schedule: any} & UserProps;
export type DateProps = {schedule:any|undefined, date:any} & UserProps;
export type DateCreatorProps = {updateParent:any} & DateProps;
export type AuthorizationProps = {auth:any} & UserProps;
export type GroupProps = {group:any} & UserProps;
export type NotificationProps = {notification:any} & UserProps;
export type DataTileProps = {dataInstance:any} & UserProps;
export type EventTileProps = {event:any} & UserProps;
export type AttachmentProps = {updateParent:Function} & UserProps;


export function createUserSelect(u:any = platform.currentUser, id='') {
    if (!u) return;
    
    let auths = authApi.search({'ownerId': u._id})
    let html = [] as any;

    if (auths && !(auths instanceof Promise)){
        auths.forEach((a:any) => {
            let otherUser = undefined;
            if(a.authorizedId !== u._id) otherUser = a.authorizedId;
            if(otherUser) html.push(<option value={otherUser}>{a.authorizedName}</option>);
        });
    }

    return (
        <select id={id} className="form-select">
            {html}
        </select>
    );
}   

//authorized is the 
export function getAuths(parent:any, authorized='authorized', authType='provider') {

    // TODO: There is not always a parent user here...
    let auths = authApi.search({'ownerId': parent.props.parentUser?._id})

    let found: any[] = [];

    if (auths  && !(auths instanceof Promise)){
        auths.forEach((auth:any) => {
            if(auth[authorized+'Id'] !== parent.props.parentUser && auth.authorizations.indexOf(authType) > -1) {
                found.push(auth);
            }
        });
    }

    return found;
}

export function renderAuthSelect(parent:any, authorized='authorized', authType='provider') {
    let found = getAuths(parent, authorized, authType);

    let html;
    found.forEach((auth) => {
        <option id={auth[authorized+'Id']}>{auth[authorized+'Name']}</option>
    });

    return (
        <select id={parent.state.id+'_userSelect'} className="form-select">
            {html}
        </select>
    );
}

export function renderGroupSelectOptions(parent:any) {
    let groupObjs = groupApi.search({'ownerId': parent.props.parentUser._id})
    let groups = groupObjs.filter((struct:any)=>{if(struct.users.indexOf(parent.props.parentUser._id) > -1) return true; else return false;});
    let html: any = [];
    groups.forEach((group:any) => {
        html.push(<option value={group._id}>{group.name}</option>)
    });

    return html;
}

// export function renderGroupSelectOptions(parent:any) {
//     let html: any = [];
//     groupApi.search({'ownerId': parent.props.parentUser._id}).then(groupObjs => {
//         let groups = groupObjs.filter((struct:any)=>{if(struct.users.indexOf(parent.props.parentUser._id) > -1) return true; else return false;});
//         groups.forEach((group:any) => {
//             html.push(<option value={group._id}>{group.name}</option>)
//         });
//     })
//     return html;

// }

export function getUserById(parent:any, userId:string) {
    let user = parent.props.platform.getLocalData('profile',{'id':userId});
    if (user && !Array.isArray(user)) { // ensure array isn't passed back
        user = getUserCodes(user, true)
        return user;
    }
    else { 
        parent.props.platform.getUsersByIdsFromServer([userId],(res:any) => {
            parent.props.platform.baseServerCallback(res);
            if(Array.isArray(res.data)) {
                let user = res.data[0];
                if(user) {
                    console.log('Not actually returned',user)
                    // name = user.fullName; if(!name) name = user.firstName; if(!name) name = user.username; if(!name) name = user.email; if(!name) name = user._id;
                    // let element = (document.getElementById(nameHtmlId) as HTMLDivElement);
                    // if(element) element.innerHTML = name;
                }
            }
        });
        return new UserObj()
    }
}

export function getUserNameById(parent:any, userId:string, nameHtmlId?:string) {
    let name;

    let user = parent.props.platform.getLocalData('profile',{'id':userId});
    if (user) {
        name = user.fullName; if(!name) name = user.firstName; if(!name) name = user.username; if(!name) name = user.email; if(!name) name = user._id;
        return name;
    }
    else { 
        let name = userId;
        parent.props.platform.getUsersByIdsFromServer([userId],(res:any) => {
            parent.props.platform.baseServerCallback(res);
            if(Array.isArray(res.data)) {
                let user = res.data[0];
                if(user && nameHtmlId) {
                    name = user.fullName; if(!name) name = user.firstName; if(!name) name = user.username; if(!name) name = user.email; if(!name) name = user._id;
                    let element = (document.getElementById(nameHtmlId) as HTMLDivElement);
                    if(element) element.innerHTML = name;
                }
            }
        });
        return name
    }
}

export function setupComponentRefresh(parent:any,structType:string) {
    if(!parent) return false;
    if(!parent.state[structType]) return false;
    comState.setState({[parent.state[structType]._id]: parent.state[structType]});
    comState.subscribe(parent.state[structType]._id,()=>{
        parent.setState({}); //state updated re-render component
    });

    let s = comState.subscribeTrigger('socketResult',(res:any)=> {
        if(res.msg === 'deleted' && res.data === parent.state[structType]._id) {
            parent.setState({deleted:true});
        }
        if(Array.isArray(res.data)) {
            res.data.forEach((struct:any) => {
                if(struct.structType === 'notification') {
                    if(struct.parent._id === parent.state[structType]._id) {
                       platform.resolveNotifications([struct],true,parent.props.platform.currentUser);
                    }
                }
            });
        }
    });

    let sub = (s as number);
    parent.sub = sub
    return sub;
}

export function removeComponentRefresh(parent:any,structType:string,sub=parent.sub) {
    if(!parent) return false;
    if(!parent.state[structType]) return false;
    if(parent.state[structType]?._id) {
        comState.unsubscribeAll(parent.state[structType]._id);
        comState.unsubscribeTrigger('socketResult',sub);
    }
    return true;
}


export function setupContainerRefresh(parent:any,structType:string,sub=parent.sub) {
    sub = comState.subscribeTrigger('socketResult',(res) => {
        if(Array.isArray(res.data)) {
            let newdata = false;
            res.data.forEach((struct:any) => {
                if(struct.structType === structType && struct.ownerId === parent.props.parentUser._id) {
                    newdata = true;
                }
            });
            if(newdata) {
                parent.setState({structs:parent.props.platform.getLocalData(structType,{ownerId:parent.props.parentUser._id})});
            }
        }
    });

    return sub;
}

export function removeContainerRefresh(sub:number|undefined) {
    comState.unsubscribeTrigger('socketResult',sub);
}