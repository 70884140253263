import { UserObj } from "@myalyce/common/models/user.model";
import { isType } from "@giveback007/util-lib";
import { browserHist } from "frontend/store/store";

const baseUrl = `${SERVER_URI}`;
const fitBitTokenApiUrl = new URL('fitbit', baseUrl).href;

export const fitbitTokenApi = {
    /** Authorize fitbitAuthCode to given realmId on server `code=` */
    authorizeCode: async (obj: { fitbitAuthCode: string, realmId: string }) => {
        const response = await fetch(fitBitTokenApiUrl + '/authorize', {
            method: 'POST',
            headers: { "Content-Type": "application/json", },
            body: JSON.stringify(obj),
        });
        
        return await response.json() as (UserObj | { errors: any[], message: string });
    },

    /** Redirect to authorize this app on `fitbit.com/oauth2` */
    authorizeRedirect: () => {
        const appRedirect = "https://app.myalyce.com"; // DON'T TOUCH. fitbit wont authorize on 'localhost'.
        const clientId = '22C7QK';
        const scope = [ "activity", "heartrate", "location", "nutrition", "profile", "settings", "sleep", "social", "weight"] as const;
        let reState = `is_fitbit=true,path=${browserHist.location.pathname}`;

        if (ENV === 'DEV') if (window.location.hostname === 'localhost') {
            const { hostname, port } = window.location;
            reState = reState + `,localhost_redirect=${hostname}:${port}`;
        }

        // TODO: https://dev.fitbit.com/build/reference/web-api/oauth2/#redirect-uris
        // state: Fitbit strongly recommend including an anti-forgery token in this parameter and confirming its value in the redirect
        // 1. Make server request -> server returns obj id it generates for "preFitBitAuthObj: { realmId, _id, time: Date.now() }"
        // 2. redirect with state=re_fitbit_auth
        // 3. fitbitTokenApi.authorizeCode({ code, realmId, preAuthId });
        // 4. server validates that (preAuthId === preFitBitAuthObj._id) => send fitbit_auth_token

        const redirectUrl = `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${appRedirect}&scope=${scope.join('%20')}&expires_in=604800&${reState ? 'state=' + reState : ''}`;
        window.location.replace(redirectUrl);
    },

    /** Refresh the token on the given realmId user */
    refresh: async (realmId: string) => {
        const response = await fetch(fitBitTokenApiUrl + '/refresh/' + realmId, {
            method: 'POST',
            headers: { "Content-Type": "application/json", }
        });

        return await response.json() as (UserObj | { errors: any[], message: string });
    },

    /** Remove fitbit data access to this app */
    revokeAuth: async (realmId: string) => {
        const response = await fetch(fitBitTokenApiUrl + '/revoke/' + realmId, {
            method: 'POST',
            headers: { "Content-Type": "application/json", }
        });
        
        return await response.json() as (UserObj | { errors: any[], message: string });
    },

    /** Check users by userId, their fitbit+access-token status */
    checkAuths: async (realmIds: string[] | string) => {
        if (isType(realmIds, 'string')) realmIds = [realmIds];

        const response = await fetch(fitBitTokenApiUrl + '/check-tokens/', {
            method: 'POST',
            headers: { "Content-Type": "application/json", },
            body: JSON.stringify(realmIds),
        });

        return await response.json() as ({
            userId: string;
            isUser: boolean;
            fitbitIsActive: boolean;
            tokenIsActive: boolean;
        }[] | { errors: any[], message: string });
    },

    getPreAuthCodes: async (realmId: string) => {
        realmId;
        throw new Error('NOT IMPLEMENTED');
        return 'preFitBitAuthObj._id';
    },
}
