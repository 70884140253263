import { objKeys } from "@giveback007/util-lib";

let gapiResolve: (val: () => gapi.auth2.GoogleAuth) => any;

const addGlobals = {
  log: console.log,
  // resolved only after it's initialized:
  gAuth: new Promise<() => gapi.auth2.GoogleAuth>((res) => gapiResolve = res),
}

objKeys(addGlobals).forEach((key) => {
  (window as any)[key] = addGlobals[key];
});

if (ENV === 'PROD' && "serviceWorker" in navigator) addEventListener('load', () => {
    navigator.serviceWorker
      .register("/sw.js")
      .catch((err) => console.log("Service worker registration failed", err));
});

if (ENV === 'DEV') setTimeout(() => {
    const src = '/browser-sync/browser-sync-client.js';

    const hasBS = Array.from(document.querySelectorAll('script'))
      .find((x) => x.src.search(src) > -1)

    if (!hasBS) {
      const browserSyncScript = document.createElement('script');
      browserSyncScript.src = src;
      document.body.appendChild(browserSyncScript);
    }
}, 1000);

(function initGapiAuth() {
  if (location.hostname === 'localhost' && location.port !== '4000') return;
  
  function onGapiLoad() {
    // https://developers.google.com/identity/sign-in/web/reference
    gapi.load('auth2', () => {
      const GoogleAuth = gapi.auth2.init({
        // for more config options:
        // https://developers.google.com/identity/sign-in/web/reference#gapiauth2clientconfig
        client_id: GOOGLE_CLIENT_ID,
        scope: `https://www.googleapis.com/auth/drive`
      });
  
      GoogleAuth.then((gAuth) => {
        gapiResolve(() => gAuth);
      }, (err) => {
        console.log('UNHANDLED');
        console.error(err);
      });
    });
  }

  
  const script = document.createElement('script');
  script.type = "text/javascript";
  script.src = "https://apis.google.com/js/platform.js";
  script.onload = onGapiLoad;
  document.head.appendChild(script);
})();
