import React, { useState } from 'react'
import { SubmitHandler, useForm } from "react-hook-form";
import { FcGoogle } from 'react-icons/fc';
import { Auth } from 'frontend/auth';
import { store } from 'frontend/store/store';
import { Action } from 'frontend/store/actions';

import squareLogo from "../assets/squarelogo.png";
import css from './Login.module.scss';
import { regexpUtil } from 'frontend/utils/general';

type FormData = {
    email: string;
    pass: string;
}

export function Login() {
    const { register, handleSubmit, formState, reset, setValue } = useForm<FormData>();
    const { errors: err, submitCount } = formState;
    const [s, setState] = useState({ loading: false, failedLoginNotify: false });
    
    const onSubmit: SubmitHandler<FormData> = data => {
        reset();
        setValue('email', data.email);
        setValue('pass', data.pass);
        setState({ ...s, loading: true });

        const credentials = Auth.getCredentials(data);
        Auth.login(credentials).then((x) => {
            setState({ ...s, loading: false });
            if (x.type === 'FAIL')
                return setState({ ...s, loading: false, failedLoginNotify: true });

            store.action({ type: Action.loginSuccess, data: x.data });
        });
    };
    
    const emailErr = submitCount > 0 && err.email;
    const passErr = submitCount > 0 && err.pass;
    
    return <div className={css.login_view}><form
        className={`form-group ${css.login_form}`}
        onSubmit={handleSubmit(onSubmit)}
    >
        <div className={css.squareLogo}>
            <img src={squareLogo} />
        </div>
        
        <h5>Login to MyAlyce</h5>
        <div className={"form-group" + (emailErr ? " has-error" : '')}>
            <input
                className="form-input"
                placeholder="Email"
                disabled={s.loading}
                // type="email" // don't use this
                {...register("email", { required: true, pattern: regexpUtil.email })}
            />
            <div className="form-input-hint">&#8205;{emailErr ? 'Please enter a valid email' : ''}</div>
        </div>

        <div className={"form-group" + (passErr ? " has-error" : '')}>
            <input
                className="form-input"
                placeholder="Password"
                disabled={s.loading}
                type="password"
                {...register("pass", { required: true })}
            />
            <div className="form-input-hint">&#8205;{passErr ? 'Please enter your password' : ''}</div>
        </div>

        <button
            type="submit"
            className={`btn input-group-btn btn-primary${s.loading ? ' loading' : ''}`}
        >Log In</button>

        <div className="divider" />
        <button
            className={"btn input-group-btn" + css.g_login_btn}
            disabled={s.loading}
            onClick={async (e) => {
                e.preventDefault();
                setState({ ...s, loading: true });
                
                const credentials = await Auth.getGoogleCredentials();
                Auth.login(credentials).then((x) => {
                    setState({ ...s, loading: false });
                    if (x.type === 'FAIL') return setState({
                        ...s,
                        loading: false,
                        failedLoginNotify: true
                    });
                    
                    store.action({ type: Action.loginSuccess, data: x.data })
                });
            }}
        >
            <div className={css.g_logo}><FcGoogle/></div>
            <div>Sign In With Google</div>
        </button>
    </form>

    {s.failedLoginNotify ? <div className={`toast toast-primary toast-error ${css.login_fail_notify}`}>
        Failed To Login, Please Check Your Email and Password.
        <button
            className="btn btn-clear toast-error float-right"
            onClick={() => setState({ ...s, failedLoginNotify: false })}
        ></button>
    </div> : <div/> /* Keep <div/> or stuff will break */} 

    </div>
}
