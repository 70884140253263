import React, { Component } from 'react';
import { capFirst } from "frontend/utils/general";
import { UserObj_S } from 'frontend/store/store';

const str = (s?: string | null, cap = false) =>
    s ? (cap ? capFirst(s) : s) : 'NONE-PROVIDED (edit info)';

interface P {
    selectedClient: UserObj_S;
    editClientInfo: () => any;
    loadingClients: boolean;
}

interface S {
    tab: 'profile' | 'biometrics' | 'events' | 'files';
    tabs: S['tab'][];
}

const memState: Partial<S> = { };
export default class ClientInfo extends Component<P, S> {
    state: S = {
        tabs: [
            // 'profile', 
            'events', 
            // 'biometrics', 
            // 'files'
        ],
        tab: 'events',
        ...memState
    }

    selectTab = (tab: S['tab']) => this.setState({ tab });

    componentWillUnmount() {
        memState.tab = this.state.tab;
    }

    render() {
        const p = this.props;
        const sc = p.selectedClient;

        return <div className={`panel`}>
                {/* TITLE */}
                <div className="panel-header">
                    <figure 
                        className="avatar avatar-lg"
                        data-initial={sc.initials}
                        style={{backgroundColor: sc.profColor}}
                    />
                    <div className="panel-title h5 mt-10">{str(sc.fullName, true)}</div>
                    <div className="panel-subtitle text-gray">{sc.email}</div>
                    {/* <div className="panel-subtitle">Fitbit: <span style={{ color: sc.fitbit ? 'green' : 'red' }}>{sc.fitbit ? '✓' : '✗'}</span></div> */}
                </div>
            </div>
    }
}
