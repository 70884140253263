import React, { Component } from 'react';
// import SleepChart from 'frontend/components/SleepChart.component';
import { linker, UserObj_S } from 'frontend/store/store';
// import { IoIosArrowForward } from 'react-icons/io';
import ClientInfo from './components/ClientInfo.component';
// import { openClientInfoFormModal } from './components/ClientInfoForm.component';
// import { ClientNotes } from './components/ClientNotes.component';

import css from './Dashboard.module.scss';
import { openClientInfoFormModal } from 'frontend/components/forms/ClientInfoForm.component';
import { platform } from 'frontend/shared';

import ChatroomsContainer from '../../components/UserPlatformTemplates/Chatroom.components'
// import  EventTileContainer  from 'frontend/components/UserPlatformTemplates/Events.component';
// import DataTileContainer from 'frontend/components/UserPlatformTemplates/Data.components';
import DataIntake from './components/DataIntake.component';
import DataSummaries from './components/DataSummaries.component';
import { Timeline } from '../../components/timeline/timeline.components';
// import { EventTileContainer } from 'frontend/components/UserPlatformTemplates/Events.component';

interface P {
    selectedClient: UserObj_S | null;
    loadingClients: boolean
}

class Dashboard extends Component<P> {

    render() {

        const sc = this.props.selectedClient;
        return <>

            {sc ? <div className={`p-2 ${css.dashboard}`}>
                {/* ACTIONS */}
                {/* <Actions selectedClient={sc} /> */}

                {/* CLIENT INFO */}
                <div className={`column ${css.primarydashcol}`}>
                {/* <div className="column col-6 col-lg-12"> */}
                    <div className={`p-2 ${css.dashobj}`}>
                        <ClientInfo
                            selectedClient={sc}
                            loadingClients={this.props.loadingClients}
                            editClientInfo={() => openClientInfoFormModal({ client: true })}
                        />
                    </div>
                    <div className={`p-2 ${css.dashobj}`}>
                        <DataIntake
                            platform={platform}
                            selectedClient={sc}
                            loadingClients={this.props.loadingClients}
                            editClientInfo={() => openClientInfoFormModal({ client: true })}
                        />
                    </div>
                </div>
                {/* <ClientNotes selectedClient={sc} /> */}
                

                {/* RIGHT SIDE*/}
                <div className={`column ${css.dashcol}`}>
                    <div className={`p-2 ${css.dashobj}`}>
                        <ChatroomsContainer 
                            platform={platform}
                            parentUser={this.props.selectedClient}
                            // currentUser={this.props.currentUser}
                        />
                    </div>
                    <div className={`p-2 ${css.dashobj}`}>

                        <Timeline
                            platform={platform}
                        />
                        
                        {/* <DataSummaries
                            selectedClient={sc}
                            loadingClients={this.props.loadingClients}
                            editClientInfo={() => openClientInfoFormModal({ client: true })}
                        /> */}
                    </div>
                </div>

                {/* SLEEP CHART */}
                {/* <div className={`columns`}>
                    <div className={`column`}>
                        <div className={`panel`}>
                        {sc ? <SleepChart fitbit={sc.fitbit} zoom={{ nDaysBack: 14 }} /> : null}
                        </div>
                    </div>
                </div> */}


            </div> : <h1 style={{textAlign: 'center'}}>No Client Selected</h1>}
        </>
    }
}

export default linker((s) => ({ selectedClient: s.selectedClient, loadingClients: s.loaders.clients}), Dashboard);

// function Actions(p: { selectedClient: UserObj_S; }) {
//     return p.selectedClient && <header className={`navbar ${css.dashboard_actions}`}>
//         <section className="navbar-section">
//             <button
//                 className="btn btn-primary"
//                 onClick={openClientEventFormModal}
//             >Add Event</button>
//         </section>
        
//         <section className="navbar-section">
//             <div className="input-group input-inline">
//                 <input className="form-input" type="text" placeholder="search" disabled={true}/>
//                 <button className="btn btn-primary input-group-btn" disabled={true}>Search</button>
//             </div>
//         </section>
//     </header>
// }

// TODO:
    // IN FITBIT API, when pulling data if token expires expose refresh token to api to allow it do it automagically
        // check if token already refreshed by pulling userObj again
    // If no user added to provider only show yourself
    // Have a loader for dashboard, sleep data, and hr data
    // Adding events button
