import DataTrackSettings from "./DataStreamSettings.js"
import randomId from "./utils/id.js";

// NOTE: This class allows the conversion of independent data coming from Device classes
// to a ReadableStream format.

export default class DataStreamTrack extends EventTarget {

    get [Symbol.toStringTag]() { return 'DataStreamTrack' }

    constructor (device={}) {
        super()

        this.contentHint = ''
        this.enabled = ''
        this.id = device.id ?? randomId()
        this.kind = device.constraints?.kind
        if (this.kind) this.kind = this.kind.replace('input','').replace('output', '')
        this.label = device.constraints?.label
        this.muted = ''
        this.readyState = ''
        this.remote = ''
        this.callbacks = new Map()
        this.data = []

        this.pipeline = []
    }

    deinit = () => {
        this.dispatchEvent(new Event('ended'))
    }

    applyConstraints = (constraint) => {
        // if (constraint.mute) this.dispatchEvent(new Event('mute'))
        // else this.dispatchEvent(new Event('unmute'))
    }

    clone = () => {

    }

    getCapabilities = () => {

    }

    getConstraints = () => {

    }

    getSettings = () => {
        return new DataTrackSettings(this)
    }

    stop = () => {

    }

    addData = (val) => {
        if (Array.isArray(val)) this.data.push(...val)
        else this.data.push(val)
        this.ondata(val)
    }

    // Data Readout
    ondata = (data) => {
        this.callbacks.forEach((f) => {
            f(data)
        })
    }

    subscribe = (callback) => {
        let id = randomId()
        this.callbacks.set(id,callback)
        return id
    }
}