import React, { Component } from 'react'
import { randomId } from '@myalyce/common';
import { ContainerProps, setupContainerRefresh, removeContainerRefresh, getUserById } from "../UserPlatformTemplates/Templates.shared";
import { linker } from 'frontend/store/store';
// import { BiRefresh, BiTrash } from 'react-icons/bi';
import css from './Stream.module.scss'
import * as datastreams from '@myalyce/common/libraries/datastreams/src'
import DataChannel from '@myalyce/common/libraries/datastreams/src/DataChannel';
import { WebglLinePlotUtils } from '@myalyce/common/libraries/webglplotutil/webgl-plot-utils';
import { ChatroomObj } from '@myalyce/common/models/chatroom.model';
import { Chatroom } from '../UserPlatformTemplates/Chatroom.components';
import { CommentObj } from '@myalyce/common/models/comment.model';
import { Avatar } from '../utils/component.utils';

interface S { }

class RealtimeContainer extends Component<ContainerProps, S> {


    reference: any = {
        chatroom: null,
        auth: null, 
        streamer: null
    }

    chatChannels: any = new Map()

    state = {
        id: randomId('realtime'),
        editing: false,
        peers: new Map(),
        replies: [],
    };

    previousParent: any = null

    // streamContext: any = new datastreams.StreamContext()
    dataDevices: any = new datastreams.DataDevices()

    sub: number | undefined = 0

    video: any;
    readout: any;
    peer: any;
    joined: any = null;
    canvas: any;

    // Declare Custom Socket for Streamer
    streamer: any = null  // Open extra socket for WebRTC handling;
    stream: any = new datastreams.DataStream()

    // Plotting
    spss: any[] = [];
    trackBuffers: any[] = [];
    animating: boolean = false

    constructor(props: ContainerProps) {
        super(props);
        this.init()
    }

    componentDidMount() {
        setupContainerRefresh(this, 'realtime', this.sub);
    }

    componentWillUnmount() {
        removeContainerRefresh(this.sub);
        this.streamer.socket.close() // Close extra socket
        
    }

    addComment = (info:any) => {
        
        this.reference.chatroom.replies.push(new CommentObj(info))

        // Update State
        this.setState({replies: this.reference.chatroom.replies})
    }


    init = async () => {

        let dataChannels: any[] = []

        // List Supported Devices to Stream
        // this.dataDevices.getSupportedDevices().then((devices: any) => {
        //     console.log(devices)
        // })

            // Init Streamer if Required
        if (!this.streamer){
            let url = new URL(SERVER_URI)
            this.streamer = new datastreams.Streamer({ server: `${url.protocol}//${url.hostname}` })
        }

        // Add Stream to Streamer
        this.streamer.add(this.stream)

        // Join your Personal Room
        this.streamer.onroom = (ev: CustomEvent) => {
            if (ev.detail.room) this.checkRooms([ev.detail.room])
        }

        this.streamer.onpeerdisconnect = async (ev: CustomEvent) => {

            // Locally Notify of Disconnection
            this.state.peers.delete(ev.detail.id)
            this.setState({peers: this.state.peers}) 
            this.setState({peers: this.state.peers}) // Remove bubble
        }
    
        this.streamer.onpeerconnect = async (ev: CustomEvent) => {

            // Locally Notify of Connection
            this.state.peers.set(ev.detail.id, ev.detail)
            this.setState({peers: this.state.peers}) // Render bubble
            
            this.streamer.openDataChannel({ peer: ev.detail.id, name: 'chat' }).then((o: DataChannel) => {
                ev.detail.channel = o
                this.chatChannels.set(o.id, o)

                o.subscribe((msg: string) => {
                    // console.log('Receiving comment over WebRTC', msg)
                    let comment = JSON.parse(msg)
                    this.reference.chatroom.replies.push(comment)

                    // Set State to Update UI
                    this.setState({replies: this.reference.chatroom.replies})
                }) 
            })
        }

        this.streamer.ondatachannel = async (ev: CustomEvent) => {
            if (ev.detail.label !== 'chat'){
                // ev.detail.subscribe((msg: string) => this.readout.innerHTML = msg) // Data Stream Stuff
                let channel = ev.detail
                if (channel.label.includes('DataStreamTrack')){
                    dataChannels.push(channel)
                    this.startPlot(dataChannels)
                }
            }
        }
}

    startPlot = (dataChannels:any[] = this.stream.getDataTracks()) => {
        let plotutil = new WebglLinePlotUtils(this.canvas, false)

        let initPlot = (currentTracks:datastreams.DataStreamTrack[]) => {

            // Plot the Lines
            let nSec = 10;
            let nPointsRenderedPerSec = 60;
            let sps = nSec * nPointsRenderedPerSec

            let length = currentTracks.length
            this.spss = Array.from({ length }, () => sps)
            this.trackBuffers = Array.from({ length }, () => [])
            plotutil.initPlot(length, this.spss, nSec, nPointsRenderedPerSec);
            
            currentTracks.forEach((track, i) => {
                track.subscribe((data:any) => {
                    if (this.trackBuffers[i].length === 0) this.trackBuffers[i]  = Array.from({length:sps}, () => data)
                    else {
                        this.trackBuffers[i].pop()
                        this.trackBuffers[i].unshift(data)
                    }
                })
            })
        }


        initPlot(dataChannels)

        // Listen to Self
        this.stream.addEventListener('track', () => {
            initPlot(this.stream.getDataTracks())
        })

        this.stream.addEventListener('removetrack', () => {
            initPlot(this.stream.getDataTracks())
        })

        if (!this.animating){

            this.animating = true
            // Listen to Self
            let newFrame = () => {
                if (this.trackBuffers.length > 0) {
                    plotutil.updateAllLines(this.trackBuffers, this.spss, true);
                    plotutil.update();
                }
                requestAnimationFrame(newFrame);
            }

            requestAnimationFrame(newFrame);
        }
    }

    checkRooms = (rooms: any[] = []) => {

        let found = rooms.find((r: any) => {
            return r.restrictions?.users?.length === 1 && r.restrictions?.users[0] === this.reference.auth
        })

        // Create Personal Room if Doesn't Exist
        if (!found) {
            this.streamer.createRoom({
                restrictions: { users: [this.reference.auth] }
            })
        }

        // Choose a room (here we default to the first)
        else if (!this.joined && found) {

            this.streamer.joinRoom(
                found, // room to join
                {id: this.props.currentUser?._id}, // Arbitrary info about yourself
                this.reference.auth // Authorization to connect to this room
            ).then((room:any) => {
                this.joined = room
            })
        }
    }

    check = async () => { }

    _click = () => {
        if (this.state.editing) this.setState({ editing: false });
        else this.setState({ editing: true });
    }

    _refresh = () => {
        this.check();
    }

    _updateFromChild = () => {
        this._click();
        this._refresh();
    }

    _connectfNIRS = () => {
        this.dataDevices.getUserStream({ fnirs: true }).then((stream: datastreams.DataStream) => {
            this._processStream(stream)
        })
    }

    _processStream = (stream: datastreams.DataStream) => {
        // const streamer = streamContext.streamer

        stream.addEventListener('track', (ev) => {
            createPipeline(ev.detail)
        })

        // Begin Processing Tracks
        let dataTracks = stream.getDataTracks()

        let createPipeline = async (track: datastreams.DataStreamTrack | MediaStreamTrack) => {

            let pipeline = new datastreams.DataPipeline({ thread: false })
            pipeline.setSource(track)

            pipeline.add((v: any) => {
                // TODO: Add a filter to the HEG data
                // console.log(v);
                // if (this.readout) this.readout.innerHTML = v
                return v;
            })

            pipeline.setSink()

            this.stream.addTrack(pipeline.output)
        }

        // Add Transforms
        dataTracks.forEach(createPipeline)

        this.startPlot()
    }


    _connectMedia = () => {
        this.dataDevices.getUserStream({
            // audio: true,
            video: true
        }).then((stream: datastreams.DataStream) => {
            this._processMedia(stream)
        })
    }

    _processMedia = (stream: datastreams.DataStream) => {
        if (this.video) this.video.srcObject = stream // replace original video

        stream.getTracks().forEach(this.stream.addTrack)// stream your video
    }

    updateReference = () => {

        // Collect Static References for the current parentUser
        if ( this.props.parentUser != null && (this.previousParent == null || this.previousParent != this.props.parentUser)){
            this.reference.auth = this.props.parentUser.id ?? this.props.parentUser._id
            this.reference.chatroom = new ChatroomObj({
                authorId: this.reference.auth,
                ownerId: this.reference.auth,
                message: 'P2P Chat',
                webrtc: true
            })
            this.state.replies = this.reference.chatroom.replies // update replies
            this.animating = false 
            
            if (this.joined){
                this.streamer.leaveRoom(this.joined).then(() => {
                    this.joined = null
                    this.streamer.getRooms(this.reference.auth).then((rooms: any) => this.checkRooms(rooms)) // join room for new parent user
                })
            } else this.streamer.getRooms(this.reference.auth).then((rooms: any) => this.checkRooms(rooms)) // join room for new parent user
        }

        this.previousParent = this.props.parentUser
    }

    render() {

        this.updateReference()

        return <div id={this.state.id} className={"panel"}>
            <div className="panel-header">
                <div className="panel-title h6 float-left">Real-Time Streaming</div>
                <div className="float-right">
                    {this.props.currentUser?._id === this.props.parentUser?._id && <button className="btn btn-primary mr-2 btn-sm" onClick={() => { this._connectfNIRS() }}>Connect Device</button>}
                    <button className="btn btn-secondary mr-2 btn-sm disabled" onClick={() => { this._connectMedia() }}>Videoconference</button>
                </div>
            </div>
            <div id={`${this.state.id}body`} className="panel-body columns">
                {/* <video ref={video => { this.video = video }} autoPlay width={300} height={150} /> */}
                <div className="column col-lg-auto container p-2">
                    <canvas ref={canvas => { this.canvas = canvas }} className={css.datacanvas}/>
                </div>
                <div className="column col-md-auto container p-2">
                <div className="float-right p-2">
                    {Array.from(this.state.peers).map(([,p]) => {
                        let avatar = getUserById(this, p.info.id)
                        return <Avatar
                            img = {{initials: avatar.initials}}
                            size = {'sm'}
                            style={{backgroundColor: avatar.profColor}}
                        />
                    })}
                </div>
                    <Chatroom 
                        platform = {this.props.platform}
                        parentUser = {this.props.parentUser}
                        chatroom = {this.reference.chatroom}
                        onsubmit={(newReply:any) => {
                            this.chatChannels.forEach((o: any) => o.sendMessage(JSON.stringify(newReply)))
                        }}
                    />
                </div>
                <div>
                </div>
            </div>
            <div className="panel-footer">
            </div>
        </div>
    }
}

export default linker((s) => ({ structs: s.chatroom }), RealtimeContainer);


type PeerInfoProps = {peerInfo:any, chatChannels?: any}

export class Peer extends Component<PeerInfoProps, S> {

    constructor(props: PeerInfoProps) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {

        return (
            <div >
                    <div className={`card`}>
                        <div className="card-header">
                            <div className="float-left">
                                <h6 className="card-title .h5 mb-0">{this.props.peerInfo.id}</h6>
                                <small className="card-subtitle text-gray">Connected</small>
                            </div>
                        </div>
                        <div className="card-body"></div>
                        <div className="card-footer"></div>
                    </div>
            </div>
        )
    }
}
