import React from 'react';
import { linker, UserObj_S } from "frontend/store/store";
import { capFirst } from 'frontend/utils/general';

import css from './ProfileInfo.module.scss';
import { PanelSliceTitle } from './utils/component.utils';

const str = (s?: string | null, cap = false) => s ? (cap ? capFirst(s) : s) : 'NONE-PROVIDED (edit info)';

// TODO: MAKE FORM MODAL GENERIC!

type P = { selectedClient: UserObj_S; editInfo?: () => any; loadingClients?: boolean; showNotes: boolean };
function Component(p: P) {
    const { selectedClient: sc, loadingClients: loading, editInfo, showNotes } = p;

    return <>
        <div className="panel-body">
            <PanelSliceTitle title="E-mail" subtitle={sc.email} />
            <PanelSliceTitle title="Sex" subtitle={str(sc.sex, true)} />
            <PanelSliceTitle title="DOB" subtitle={str(sc.birthday)} />

            {showNotes && <div className="tile tile-centered">
                <div className="tile-content">
                    <div className="tile-title text-bold">General Notes</div>
                    <div className={`tile-subtitle ${css.client_info}`}>{str(sc.clientInfo)}</div>
                </div>
            </div>}
        </div>

        {editInfo ? <div className="panel-footer">
            <button
                className={`btn btn-primary btn-block${loading ? ' loading' : ''}`}
                disabled={loading}
                onClick={editInfo}
            >Edit Profile</button>
        </div> : null}
    </>
}

export const ProfileInfo = linker((s) => ({ loadingClients: s.loaders.clients }), Component)
