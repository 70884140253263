// Ensure Proper Pipeline Format
let pipeline = []

// Bind Pipeline
let bound = []

export let addSource = (source, bound) => bound.push(source) // Push source at the beginning
export let addSink = (sink, bound) => {
    bound.push(bound[bound.length - 1].pipeTo(sink)) // Push sink at the end
}
export let addTransform = (o, pipeline, bound) => {
    pipeline.push(o)
    bound.push(bound[bound.length - 1].pipeThrough(o))
}

// Sets up an array of StreamTransforms 
self.onmessage = async (e) => {

    // Initialize Pipeline
    if (e.data.cmd === 'init') e.data.data.source.pipeThrough(transformer).pipeTo(e.data.data.sink)
    if (e.data.cmd === 'add') addTransform(e.data.data, pipeline, bound)
    if (e.data.cmd === 'source') addSource(e.data.data, bound)
    if (e.data.cmd === 'sink') addSink(e.data.data, bound)
}

export default self