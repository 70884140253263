import React, { useState } from 'react';
import { Chip, ProfileBadge } from 'frontend/components/utils/component.utils';
import { linker, store, UserObj_S } from 'frontend/store/store';

import css from './SelectedClient.module.scss';
import { wait } from '@giveback007/util-lib';
import { platform } from 'frontend/shared';

function SelectedClient(p: { selectedClient: UserObj_S | null; currentUser?: UserObj_S;}) {
    const [open, setOpen] = useState(false);
    const sc = p.selectedClient;
    
    return <>
        <div className={css.spacer}></div>
        {sc && <div
            id="active-user"
            className={css.active_user}
            onClick={() => setOpen(true)}
        >
            <div
                data-tooltip="Selected client, click to change"
                className="tooltip tooltip-top link-btn-hover"
            >
                <ProfileBadge user={sc} className={css.badge} />
            </div>
        </div>}
        <div
            className={`${css.client_selector} ${(!open && sc) ? css.closed : ''}`}
        >
            {sc && <button
                className={`btn btn-clear ${css.client_selector_close_btn}`}
                onClick={(e) => {
                    setOpen(false)
                    e.stopPropagation();
                }}
            ><a
                aria-label="Close"
                role="button"
            /></button>}

            <Tabs clientClick={(client) => {
                let auth = platform.hasLocalAuthorization(client._id,platform.currentUser._id);
                if(auth) platform.getUserDataByAuthorizationFromServer(auth);
                store.setState({ selectedClient: client });
                wait(120).then(() => setOpen(false));
            }} />
        </div>
    </>
}

export default linker((s) => ({ selectedClient: s.selectedClient, currenDSAtUser: s.currentUser }), SelectedClient);

console.log('RERENDERING TABS')
const Tabs = linker((s) => ({ clients: s.clients, selectedClient: s.selectedClient }),
    (p: { clients: UserObj_S[] | null; selectedClient: UserObj_S | null; clientClick: (client: UserObj_S) => any }) => {
    
    return <div className="column">
        <ul className="tab tab-block">
            {(p.clients && p.clients.length) ? p.clients.map((client) => {
                const { initials, profColor, codeName, fullName, email } = client;
                return <li
                    className={`tab-item${p.selectedClient?._id === client._id ? ' active' : ''} ${css.tab}`}
                    // onClick={}
                ><a
                    className="tooltip tooltip-top"
                    data-tooltip={`${fullName || email}`}
                    onClick={() => p.clientClick(client)}
                >
                    <Chip
                        img={{ initials, backgroundColor: profColor }}
                        label={codeName}
                    />
                </a></li>
            })
            : 
            // TODO: make it so it's a link automatically open a list of clients in the group
            <h5>No clients added to the list of selectable.</h5>}
        </ul>
    </div>;
});

// Autocomplete:
// https://picturepan2.github.io/spectre/experimentals/autocomplete.html
