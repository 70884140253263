import React, { Component } from 'react'
import { randomId } from '@myalyce/common';
import { CommentCreatorProps, CommentProps, ContainerProps, getUserById, getUserNameById, removeComponentRefresh, removeContainerRefresh, setupComponentRefresh, setupContainerRefresh } from "./Templates.shared";
import { DataTile } from './Data.components'
import {AttachmentCreator} from './Attachments.components'
import {genChatTime} from 'frontend/utils/general';
import { commentApi } from 'frontend/apis/comment.api';
import { EventTile } from './Events.component';
import { Api } from 'frontend/apis/_socket.api';
import { linker } from 'frontend/store/store';
import css from './Comment.module.scss'
import { IoMdAdd } from 'react-icons/io';

interface S {}

/**
 * TODO:
 * Make just a comment view like dovy did that pulls the latest comments in general (platform.getUserComments)
 */

export class CommentCreator extends Component<CommentCreatorProps, S> {
        
    state = {
        id:randomId('commentCreator'),
        attachments:[] as any,
        attaching: false,
        parentUser:this.props.parentUser
    }

    updateAttachments = (attachment:any) => {
        //console.log(this.state.attachments, attachment);
        let updated = [...this.state.attachments,attachment];
        this.setState({attachments:updated});
    }


    constructor(props:CommentCreatorProps) {
        super(props);   
    }


    componentDidMount() {

    }

    componentWillUnmount() {

    }

    _submit = () => {
        let msg = (document.getElementById(this.state.id+'_message') as HTMLInputElement)?.value;
        let att = this.state.attachments;

        
        // Pass through Server
        let comment = this.props.platform.addComment(
            this.props.parentUser,
            this.props.room,
            this.props.parent,
            this.props.platform.currentUser?._id,
            msg,
            att,
            !this.props.parent.webrtc // whether to update the server or not
        );

        if (this.props.onsubmit) this.props.onsubmit(comment)
        this.props.updateParent(comment);
    }

    _attach = () => {
        this.setState({attaching:!this.state.attaching});
    }

    render() {

        return (
            <>
            <div id={this.state.id} className="input-group">
                <button id={this.state.id+'_attach'} className="btn btn-secondary input-group-btn" onClick={this._attach}><IoMdAdd/></button>
                <input id={this.state.id+'_message'} className="form-input" type="text" placeholder={`Message ${this.props.room.users.map((name:string) => getUserNameById(this, name)).join(' and ')}`}/>
                <button id={this.state.id+'_submit'} className="btn btn-primary input-group-btn" onClick={this._submit}>Submit</button>
            </div>

            {/* Attachment Editor */}
            <div>
                    { this.state.attaching && 
                        <AttachmentCreator
                            platform={this.props.platform}
                            parentUser={this.props.parentUser}
                            updateParent={this.updateAttachments}
                        />
                    }   
                    <div id={this.state.id+'_attachments'}>
                        {   this.state.attachments.map((item:any) =>{

                            if (item.structType === 'dataInstance'){ return <DataTile 
                                platform={this.props.platform}
                                parentUser={this.props.parentUser}
                                dataInstance={item}
                                />
                            }
                            else if (item.structType === 'event') {
                            return <EventTile 
                                platform={this.props.platform}
                                parentUser={this.props.parentUser}
                                event={item}
                                />
                            }
                            return
                        })
                        }
                    </div>
                </div>
            </>
        )
    }
}



//leave room undefined if only displaying single comments
export class Comment extends Component<CommentProps, S> {
        
    state = {
        id:randomId('comment'),
        comment:this.props.platform.commentProps,
        room:this.props.room, //not really necessary for now 
        replies:[] as any,
        viewing:false,
        replying:false,
        deleted:false,
        parentUser:this.props.parentUser
    }

    editorRef = React.createRef<CommentCreator>()
    structRefs = [React.createRef<Comment>()]
    sub = 0

    constructor(props:CommentProps) {
        super(props);   
        // console.log(this.props, this.props.comment)
        this.state.comment = this.props.comment; // RERENDER PROPERLY

        //console.log('creating comment');

        this.check();
        
    }
    
    check = (init=false) => {
        //console.log(this.props.comment)
        let replies = this.props.platform.getLocalReplies(this.props.comment);
        //console.log(this.props.comment)
        //console.log(replies);
        
        this.structRefs=[];
        replies.forEach(() => {this.structRefs.push(React.createRef<Comment>())});
        this.setState({replies:replies});

        if(init) this.state.replies = replies;
        else
            this.setState({replies:replies});

    }

    componentDidMount() {
        if(this.state.comment?._id) {
            setupComponentRefresh(this,'comment');
        }
    }

    componentWillUnmount() {
        if(this.state.comment?._id) {
            removeComponentRefresh(this,'comment');
        }
    }

    _delete = () => { //need to add a case for when you only have the partial reply chain (maybe just do this server side)
        this.props.platform.deleteCommentOnServer(this.props.comment);
        this.setState({deleted:true});
    }

    _view = () => {
        if(this.state.viewing) this.setState({viewing:false});
        else this.setState({viewing:true});
    }

    //pull the comment head if you are not currently getting it
    _access = () => {
        this.props.platform.getStructParentDataFromServer(this.state.comment);
    }

    _reply = () => {
        if(this.state.replying) this.setState({replying:false});
        else this.setState({replying:true});
    }

    _updateFromChild = () => {
        this._reply();
        this.check();
    }


    render() {

        this.state.comment = this.props.comment;
        let author = getUserById(this, this.state.comment.authorId)

        return (
            <div id={this.state.id}>
            { !this.state.deleted && 
            <div className="tile p-2">
                <div className={`tile-icon text-center pr-2`}>
                    <figure 
                        className="avatar avatar-lg"
                        data-initial={author.initials}
                        style={{backgroundColor: author.profColor}}
                    />
                </div>
                <div className="tile-content">
                    <div className="float-right">
                        {/* Discussion Buttons */}
                        { this.props.setting === 'discussion' && this.props.room !== undefined &&
                        <button id={this.state.id+'_view'} className="btn btn-primary" onClick={this._view}>View Replies</button>
                        } 
                        { this.props.setting === 'discussion' && <div>
                        <button id={this.state.id+'_reply'} className="btn btn-primary" onClick={this._reply}>Reply</button> 
                        {this.state.replying && 
                        <CommentCreator 
                            platform={this.props.platform}
                            parentUser={this.props.parentUser}
                            room={this.props.room}
                            parent={this.props.parent}
                            ref={this.editorRef}
                            updateParent={this._updateFromChild}
                        />
                        }</div>}

                        {/* Room Access */}
                        { this.props.room === undefined &&
                        <button id={this.state.id+'_access'} className="btn btn-primary" onClick={this._access}>View Room</button>
                        }

                        {/* Delete Button */}
                        <button id={this.state.id+'_button'} className="btn btn-primary" onClick={this._delete}>Delete</button>
                    </div>

                    <div className={css.commentbody}>
                    <span className="tile-title h6" id={this.state.id+this.state.comment.authorId}>{getUserNameById(this,this.state.comment.authorId,this.state.id+this.state.comment.authorId)}</span>
                    <span className="subtitle text-gray text-tiny pl-2">{genChatTime(this.state.comment.timestamp, false)}</span>

                    <p className="tile-subtitle">{this.state.comment.message}</p>
                    <div id={this.state.id+'_data'}>
                        {this.state.comment.attachments?.map((item:any) => {

                            let d = Api(undefined).search({'_id': item}) // expect local data
                            
                            if(!d) {
                                // // // this.props.platform.getDataFromServer('dataInstance',this.state.comment.ownerId,{'_id':item},undefined,undefined,(res:any) => { //does async work here? we'll find out!
                                Api(undefined).search({'_id': item, 'ownerId':this.state.comment.ownerId}).then((res:any) => {

                                    this.props.platform.baseServerCallback(res);
                                    if(res.data[0]) d = res.data[0];
                                    setTimeout(()=>{this.render()},100);

                                    if (d.structType === 'dataInstance')
                                    return <DataTile 
                                            platform={this.props.platform}
                                            parentUser={this.props.parentUser}
                                            dataInstance={d}
                                            />
                                    
                                    else if (d.structType === 'event') {
                                        return <div className="card"><EventTile 
                                            platform={this.props.platform}
                                            parentUser={this.props.parentUser}
                                            event={d}
                                            /></div>
                                    }
                                    return
                                });
                            } else {
                                if (Array.isArray(d)) d = d[0]
                                if (!!d){
                                    if (d.structType === 'dataInstance')
                                        { return <DataTile 
                                                platform={this.props.platform}
                                                parentUser={this.props.parentUser}
                                                dataInstance={d}
                                                />
                                        }
                                    else if (d.structType === 'event') {
                                        return <div className="card"><EventTile 
                                            platform={this.props.platform}
                                            parentUser={this.props.parentUser}
                                            event={d}
                                            /></div>
                                    }
                                }
                            }
                            return
                        })}
                    </div>
                    {this.props.setting === 'discussion' &&
                    <div>
                        <div id={this.state.id+'_comments'}>
                        {this.state.viewing &&
                            this.state.replies.map((item: any, i:number) => 
                                <Comment 
                                    platform={this.props.platform}
                                    parentUser={this.props.parentUser}
                                    comment={item}
                                    room={this.props.room}
                                    parent={this.props.parent}
                                    ref={this.structRefs[i]}
                                    setting={'discussion'}
                                />
                            )
                        }
                    </div>
                    </div>}
                    </div>

                    </div>
                    </div>}
            </div>
        )
    }
}



//a way to list a bunch of comments. Should add 'go to' to go to the conversation block source
class CommentContainer extends Component<ContainerProps, S> {
        
    state = {
        id:randomId('comments'),
        structs:this.props.structs,
        editing:false,
        parentUser:this.props.parentUser,
        limit:10, //initial limit
        skip:0
    };
    
    structRefs = [React.createRef<Comment>()]

        
    sub:number|undefined = 0

    constructor(props:ContainerProps) {
        super(props);   
    }

    componentDidMount() {
        setupContainerRefresh(this,'comment',this.sub);
        this.check();

    }

    componentWillUnmount() {
        removeContainerRefresh(this.sub);
    }



    check = async () => {
        let structs = [];
        if (this.props.structs){
            if(this.props.structs.length === 0) {
                structs = await commentApi.search({'ownerId': this.props.parentUser._id})
                //console.log(structs)
            }
            if(structs.length > 0) {
                this.structRefs = [];
                structs.forEach(()=>{
                    this.structRefs.push(React.createRef<Comment>());
                });
                this.setState({structs:structs});
            }
        }
    }

    //get comments [newest,to,oldest]
    retrieveComments = (skip=this.state.skip,limit=this.state.limit) => {
        // this.props.platform.getDataFromServer('comment',this.props.parentUser._id,undefined,limit,skip,(res:any)=>{
        commentApi.search({'ownerId':this.props.parentUser._id}, limit, skip).then((res:any) => {
            this.props.platform.baseServerCallback(res);
            if(res.data.length > 0) this.setState({structs:[...this.state.structs ?? [],...res.data], limit:limit, skip:skip});
        });
    }

    _next = () => {
        let skip = this.state.skip + 10;
        this.retrieveComments(skip);
    }

    _prev = () => {
        let skip = this.state.skip - 10;
        this.retrieveComments(skip);
    }

    _refresh = () => {
        this.check();
    }

    render() {
        let skipped = 0;
        return (
            <div id={this.state.id} className="card">
                Comment History
                {this.props.structs && this.props.structs.length === 0 && 
                    <button className="btn btn-secondary"  onClick={this._refresh}>Refresh</button>
                }
                {this.props.structs &&  this.props.structs.length > this.state.limit &&
                    <div>
                        Search Comment History
                        <button id={this.state.id+'_next'} onClick={this._next}>Next</button>
                        <button id={this.state.id+'_prev'} onClick={this._prev}>Prev</button>
                    </div>
                }
                <div>
                {this.state.structs && this.state.structs.length > 0 &&
                    this.state.structs.map((item: any, idx:number) => {
                        if(item.author === this.props.parentUser._id) skipped++;
                        if((idx+skipped >= this.state.skip && idx < this.state.limit+this.state.skip && item.authorId !== this.props.platform.currentUser._id) 
                            || (this.state.structs && idx+skipped+this.state.limit > this.state.structs.length && item.authorId !== this.props.platform.currentUser._id)) 
                            return <Comment 
                                platform = {this.props.platform}
                                parentUser = {this.props.parentUser}
                                comment = {item}
                                room = {this.props.platform.getLocalData(undefined,{'_id':item.replyTo})}
                                parent = {this.props.platform.getLocalData(undefined,{'_id':item.replyTo})}
                                ref = {this.structRefs[idx]}
                                setting={'chatroom'}
                            />
                        else return;
                    })
                }
                </div>
            </div>
        )
    }
}

export default linker((s) => ({ structs: s.comment}), CommentContainer);

