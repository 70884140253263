import { randomId } from '@myalyce/common/';
import React, { Component } from 'react';
import { linker, store} from 'frontend/store/store';
import { userApi } from 'frontend/apis/user.api';
import { ContainerProps, CreatorProps, getUserNameById, GroupProps, removeComponentRefresh, removeContainerRefresh, renderAuthSelect, renderGroupSelectOptions, setupComponentRefresh, setupContainerRefresh, UserProps } from './Templates.shared';
import { Action } from 'frontend/store/actions';
import { addNotification } from 'frontend/utils/store.utils';
import { getUserCodes } from 'frontend/utils/general';
import { UserObj } from '@myalyce/common/models/user.model';

import { arrToBoolDict, sec } from '@giveback007/util-lib';
import { groupApi } from 'frontend/apis/group.api';
import { authApi } from 'frontend/apis/authorization.api';
interface S {}

export class GroupCreator extends Component<CreatorProps,S> {

    state = {
        id:randomId('groupCreator'),
        admininput:false,
        providerinput:false,
        clientinput:false,
        admins:new Array(),
        providers:new Array(),
        clients:new Array()
    }


    constructor(props:CreatorProps) {
        super(props)
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _adminOnChange = () => {
        let select = (document.getElementById(this.state.id+'_admin') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        this.setState({admininput: value === 'input'});
    }

    _providerOnChange = () => {
        let select = (document.getElementById(this.state.id+'_provider') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        this.setState({providerinput: value === 'input'});
    }

    _clientOnChange = () => {
        let select = (document.getElementById(this.state.id+'_client') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        this.setState({clientinput: value === 'input'});
    }

    _adminOnClick = () => {
        let select = (document.getElementById(this.state.id+'_admin') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        if(value === 'input') {
            let input = (document.getElementById(this.state.id+'_admininput') as HTMLInputElement).value;
            if(input.length > 0) {
                if(input === 'me' || input === 'Me' || input === 'loggedin' || input === 'owner') input = this.props.parentUser._id;
                this.setState({admins:[...this.state.admins,input]});
            }
        } else if (value !== 'none')  this.setState({admins:[...this.state.admins,value]});
    }

    _providerOnClick = () => {
        let select = (document.getElementById(this.state.id+'_provider') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        if(value === 'input') {
            let input = (document.getElementById(this.state.id+'_providerinput') as HTMLInputElement).value;
            if(input.length > 0) {
                if(input === 'me' || input === 'Me' || input === 'loggedin' || input === 'owner') input = this.props.parentUser._id;
                this.setState({providers:[...this.state.providers,input]});
            }
        } else if (value !== 'none')  this.setState({providers:[...this.state.providers,value]});
    }

    _clientOnClick = () => {
        let select = (document.getElementById(this.state.id+'_client') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        if(value === 'input') {
            let input = (document.getElementById(this.state.id+'_clientinput') as HTMLInputElement).value;
            if(input.length > 0) {
                if(input === 'me' || input === 'Me' || input === 'loggedin' || input === 'owner') input = this.props.parentUser._id;
                this.setState({clients:[...this.state.clients,input]});
            }
        } else if (value !== 'none')  this.setState({clients:[...this.state.clients,value]});
    }

    _submit = () => {
        let name = (document.getElementById(this.state.id+'_name') as HTMLInputElement).value;
        let details = (document.getElementById(this.state.id+'_details') as HTMLInputElement).value;
        let admins = this.state.admins; 
        if(admins.indexOf(this.props.parentUser._id) < 0) admins.push(this.props.parentUser._id); //add yourself as an admin, you are also owner and super admin
        let providers = this.state.providers;
        let clients = this.state.clients;

        this.props.platform.addGroup(
            this.props.parentUser,
            name,
            details,
            admins,
            providers,
            clients
        );

        this.props.updateParent();

    }

    _remove = (route:string, item:Object) => {
        this.setState({[route]: (this.state as any)[route].filter((i: any) => item != i)})
    }

    render() {
        return (
            <div id={this.state.id} className={this.props.editing ? "modal active" : "modal"}>
            <a className="modal-overlay" aria-label="Close" onClick={() => {this.props.toggleEditor()}}></a>
            <div className="modal-container">
                <div className="modal-header">
                <a  className="btn btn-clear float-right" aria-label="Close" onClick={() => {this.props.toggleEditor()}}></a>
                <div className="modal-title h5">New Group</div>
                </div>
                <div className="modal-body">
                <div className="form-group">
                    <label className="form-label">Name</label>
                    <input id={this.state.id+'_name'} className="form-input" type="text" placeholder="e.g. Alyce"/>
                </div>
                <div className="form-group">
                    <label className="form-label">Details</label>
                    <input id={this.state.id+'_details'} className="form-input" type="text" placeholder="We are x doing y"/>
                </div>
                <div className="form-group">
                    <label className="form-label d-inline-block">Admins</label>
                    <small className="text-gray pl-2">Controls who is in the group</small>                    
                    
                    <div className="input-group">
                        <select id={this.state.id+'_admin'} className="form-select" onChange={this._adminOnChange}>
                            <option value='none'>Only Me</option>
                            <option value='input'>Input</option>
                            { renderAuthSelect(this,'authorized','provider') }
                        </select>
                        {this.state.admininput && <input id={this.state.id+'_admininput'} className="form-input" placeholder='admin@myalyce.com'></input>}
                        <button onClick={this._adminOnClick} className="btn btn-primary input-group-btn">Add</button>
                    </div>
                    {this.state.admins.map((item) => 
                         <div className="tile tile-centered p-2">
                         <div className="tile-content">
                         <div className="tile-title">{item}</div>
                         {/* <small className="tile-subtitle text-gray">{item}</small> */}
                         </div>
                         <div className="tile-action">
                                 <button id={this.state.id+'_delete'} onClick={() => {this._remove('admins', item)}} className="btn btn-link">Delete</button>                
                        </div>
                         </div>
                    )}
                </div>
                <div className="form-group">
                    <label className="form-label d-inline-block">Providers</label>
                    <small className="text-gray pl-2">Has access to clients</small>
                    <div className="input-group">
                        <select id={this.state.id+'_provider'} className="form-select" onChange={this._providerOnChange}>
                            <option value='none'>Only Me</option>
                            <option value='input'>Input</option>
                            { renderAuthSelect(this,'authorized','provider') }
                        </select>
                        {this.state.providerinput && <input id={this.state.id+'_providerinput'} className="form-input" placeholder='provider@myalyce.com'></input>}
                        <button onClick={this._providerOnClick} className="btn btn-primary input-group-btn">Add</button>
                    </div>
                    {this.state.providers.map((item) => 
                         <div className="tile tile-centered p-2">
                         <div className="tile-content">
                         <div className="tile-title">{item}</div>
                         {/* <small className="tile-subtitle text-gray">{item}</small> */}
                         </div>
                         <div className="tile-action">
                                 <button id={this.state.id+'_delete'} onClick={() => {this._remove('providers', item)}} className="btn btn-link">Delete</button>                
                        </div>
                         </div>
                    )}
                </div>
                <div className="form-group">
                    <label className="form-label d-inline-block">Clients</label>
                    <small className="text-gray pl-2">Can be accessed by this group</small>
                    <div className="input-group">
                        <select id={this.state.id+'_client'} className="form-select" onChange={this._clientOnChange}>
                            <option value='none'>Only Me</option>
                            <option value='input'>Input</option>
                            { renderAuthSelect(this,'authorizer','provider') }
                        </select>
                        {this.state.clientinput && <input id={this.state.id+'_clientinput'} className="form-input" placeholder='client@myalyce.com'></input>}
                        <button onClick={this._clientOnClick} className="btn btn-primary input-group-btn">Add</button>
                    </div>
                    {this.state.clients.map((item) => 
                        <div className="tile tile-centered p-2">
                        <div className="tile-content">
                        <div className="tile-title">{item}</div>
                        {/* <small className="tile-subtitle text-gray">{item}</small> */}
                        </div>
                        <div className="tile-action">
                                <button id={this.state.id+'_delete'} onClick={() => {this._remove('clients', item)}} className="btn btn-link">Delete</button>                
                       </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="modal-footer">
                <button id={this.state.id+'_submit'} className="btn btn-primary input-group-btn" onClick={this._submit}>Submit</button>
            </div>
            </div>
            </div>
        )
    }
}

export class Group extends Component<GroupProps,S> {
    state = {
        id:randomId('group'),
        group:this.props.platform.groupProps,
        addingadmin:false,
        addingprovider:false,
        addingclient:false,
        admininput:false,
        providerinput:false,
        clientinput:false,
        deleted:false,
        managing: false,
        allUsers: new Array(),
        clientBoolDict: {}
    }

    sub: number|undefined = 0;

    constructor(props:GroupProps) {
        super(props);
        this.state.group = this.props.group;
    }

    componentDidMount() {
        const clientBoolDict = arrToBoolDict(store.getState().currentUser?.clients || []);
        
        if(this.state.group?._id) {

            let clients = this.props.platform.getLocalData('profile');
            clients = clients.filter((o:any)=>{
                if(o.userRoles.indexOf(this.state.group.name+'_client') > -1) {
                    return true;
                } else return false;
            });
            if(clients.length < this.state.group.clients.length) {
                this.props.platform.getUsersByIdsFromServer(this.state.group.clients,(res)=>{
                    this.props.platform.baseServerCallback(res);
                    userApi.all().then((res) => {
                        if (res.type === 'SUCCESS') {
                            let allUsers = new Array();
                            res.data.forEach((u) => {
                                if(this.state.group.clients.indexOf(u._id) > -1 && u._id !== this.props.platform.currentUser._id && this.props.platform.hasLocalAuthorization(u._id)) allUsers.push(u);
                            })
                            this.setState({ allUsers: allUsers });
                        } else {
                            addNotification({ text: 'Failed To Get Users', type: 'error' }, sec(10));
                        }
                    });
                    this.setState({allUsers:res.data, clientBoolDict});
                });
            } else {
                userApi.all().then((res) => {
                    if (res.type === 'SUCCESS') {
                        let allUsers = new Array();
                        res.data.forEach((u) => {
                            if(this.state.group.clients.indexOf(u._id) > -1 && u._id !== this.props.platform.currentUser._id && this.props.platform.hasLocalAuthorization(u._id)) allUsers.push(u);
                        });
                        this.setState({ allUsers: allUsers });
                    } else {
                        addNotification({ text: 'Failed To Get Users', type: 'error' }, sec(10));
                    }
                });
            }
            // this.setState({
            //     allUsers:store.getState().currentUser?.clients?.filter((o:any)=>this.state.group.clients.indexOf(o._id) > -1),
            //     clientBoolDict:clientBoolDict
            // });

            setupComponentRefresh(this,'group');
        }

    }

    componentWillUnmount() {
        if(this.state.group?._id) {
            removeComponentRefresh(this,'group');
        }
    }

    

    _addAdmin = () => {
        this.setState({addingadmin:!this.state.addingadmin})
    }

    _addProvider = () => {
        this.setState({addingprovider:!this.state.addingprovider})
    }

    _addClient = () => {
        this.setState({addingclient:!this.state.addingclient})
    }

    _adminOnChange = () => {
        let select = (document.getElementById(this.state.id+'_admin') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        this.setState({admininput: value === 'input'});
    }

    _providerOnChange = () => {
        let select = (document.getElementById(this.state.id+'_provider') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        this.setState({providerinput: value === 'input'});
    }

    _clientOnChange = () => {
        let select = (document.getElementById(this.state.id+'_client') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        this.setState({clientinput: value === 'input'});
    }

    _removeAdmin = () => {
        let select = (document.getElementById(this.state.id+'_admins') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        let idx = this.state.group.admins.indexOf(value);
        if(idx > -1) {
            this.state.group.admins.splice(idx,1);
            this.props.platform.setGroupOnServer(this.state.group);
            this.setState({})
        }
    }

    _removeProvider = () => {
        let select = (document.getElementById(this.state.id+'_providers') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        let idx = this.state.group.providers.indexOf(value);
        if(idx > -1) {
            this.state.group.providers.splice(idx,1);
            this.props.platform.setGroupOnServer(this.state.group);
            this.setState({})
        }
    }

    _removeClient = () => {
        let select = (document.getElementById(this.state.id+'_clients') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        let idx = this.state.group.clients.indexOf(value);
        if(idx > -1) {
            this.state.group.clients.splice(idx,1);
            this.props.platform.setGroupOnServer(this.state.group);
            this.setState({})
        }
    }

    _adminOnClick = () => {
        let select = (document.getElementById(this.state.id+'_admin') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        let changed = false;
        if(value === 'input') {
            let input = (document.getElementById(this.state.id+'_admininput') as HTMLInputElement).value;
            if(input.length > 0) {
                if(input === 'me' || input === 'Me' || input === 'loggedin' || input === 'owner') input = this.props.parentUser._id;
                this.state.group.admins.push(input)
                this.state.group.users.push(input);
                this.setState({addingadmin:false})
                changed = true;
            }
        } else if (value !== 'none') {
            this.state.group.admins.push(value);
            this.state.group.users.push(value);
            this.setState({addingadmin:false})
            changed = true;
        }
        if(changed) this.props.platform.setGroupOnServer(this.state.group);
    }

    _providerOnClick = () => {
        let select = (document.getElementById(this.state.id+'_provider') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        let changed = false;
        if(value === 'input') {
            let input = (document.getElementById(this.state.id+'_providerinput') as HTMLInputElement).value;
            if(input.length > 0) {
                if(input === 'me' || input === 'Me' || input === 'loggedin' || input === 'owner') input = this.props.parentUser._id;
                this.state.group.providers.push(input)
                this.state.group.users.push(input);
                this.setState({addingprovider:false})
                changed = true;
            }
        } else if (value !== 'none') {
            this.state.group.providers.push(value);
            this.state.group.users.push(value);
            this.setState({addingprovider:false})
            changed = true;
        }

        if(changed) this.props.platform.setGroupOnServer(this.state.group);
    }

    _clientOnClick = () => {
        let select = (document.getElementById(this.state.id+'_client') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        let changed = false;
        if(value === 'input') {
            let input = (document.getElementById(this.state.id+'_clientinput') as HTMLInputElement).value;
            if(input.length > 0) {
                if(input === 'me' || input === 'Me' || input === 'loggedin' || input === 'owner') input = this.props.parentUser._id;
                this.state.group.clients.push(input);
                if(this.state.group.users.indexOf(value) < 0) this.state.group.users.push(input);
                this.setState({addingclient:false})
                changed = true;
            }
        } else if (value !== 'none') {
            this.state.group.clients.push(value);
            if(this.state.group.users.indexOf(value) < 0) this.state.group.users.push(value);
            this.setState({addingclient:false})
            changed = true;
        }
        if(changed) this.props.platform.setGroupOnServer(this.state.group);
    }

    _delete = () => {
        this.props.platform.deleteGroupFromServer(this.props.group._id);
        this.setState({deleted:true});
    }

    _manage = () => {
        this.setState({managing:!this.state.managing});
    }

    toggleUser = async (user: UserObj) => {
        const cu = store.getState().currentUser;
        if (!cu) throw new Error('This shouldn\'t happen');

        const isClient = (this.state.clientBoolDict as any)[user._id];
        const preClients = cu.clients || [];
        const clients = isClient ? preClients.filter((id:string) => id !== user._id) : [...preClients, user._id];

        const res = await userApi.patch(cu._id, { clients });
        if (res.type === 'ERROR') return addNotification({ text: `Error trying to add user "${user.email}"`, type: 'error' }, sec(5));

        await store.setState({ currentUser: getUserCodes(res.data, true) });
        addNotification({ text: `${isClient ? 'Removed' : 'Added'} "${user.email}" as client`, type: 'success' }, sec(5));
        const clientBoolDict = arrToBoolDict(store.getState().currentUser?.clients || []);

        this.setState({ clientBoolDict });
    }

    render() {
        return (
        <div>
            {!this.state.deleted &&
            <div  className="card">
            <div className="card-header">
                <div className="float-right">
                    <button onClick={this._manage} className="btn btn-primary mr-2 btn-sm">Manage</button>
                    {this.state.group.ownerId === this.props.platform.currentUser?._id &&
                        <button onClick={this._delete} className="btn btn-secondary btn-sm">Delete Group</button>
                    }
                </div>
                <div className="float-left">
                    <h6 className="mb-0">{this.state.group.name}</h6>
                    Owner: <small className="text-gray"id={this.state.id+'owner'+this.state.group.ownerId}>{getUserNameById(this,this.state.group.ownerId,this.state.id+'owner'+this.state.group.ownerId)}</small>
                </div>
            </div>
            {this.state.managing && <div className="card-body">
            <div className="input-group p-2">
                <label className="input-group-addon">Admins</label>
                <select id={this.state.id+'_admins'} className="form-select">
                {this.state.group.admins.map((item:any) => 
                    <option value={item} id={this.state.id+'admin'+item}>{getUserNameById(this,item,this.state.id+'admin'+item)}</option>
                )}
                </select>
            </div>
            {(this.state.group.admins.indexOf(this.props.parentUser._id) > -1) &&
                <div>
                    <button onClick={this._addAdmin}>Add</button>
                    {this.state.addingadmin &&
                        <div>
                            <select id={this.state.id+'_admin'} className="form-select" onChange={this._adminOnChange}>
                                <option value='none'>None Selected</option>
                                <option value='input'>Input</option>
                                { renderAuthSelect(this,'authorized','provider') }
                            </select>
                            {this.state.admininput && <input id={this.state.id+'_admininput'} placeholder='admin@myalyce.com'></input>}  
                            <button onClick={this._adminOnClick}>Add</button>
                        </div>
                    }
                    <button onClick={this._removeAdmin}>Remove</button>
                </div>
            }
            <div className="input-group p-2">
                <label className="input-group-addon">Providers</label>
                <select id={this.state.id+'_providers'} className="form-select">
                {this.state.group.providers.map((item:any) => 
                    <option value={item} id={this.state.id+'provider'+item}>{getUserNameById(this,item,this.state.id+'provider'+item)}</option>
                )}
                </select>
            </div>
            {(this.state.group.admins.indexOf(this.props.parentUser._id) > -1) &&
                <div>
                    <button onClick={this._addProvider}>Add</button>
                    {this.state.addingprovider &&
                        <div>
                            <select id={this.state.id+'_provider'} className="form-select" onChange={this._providerOnChange}>
                                <option value='none'>None Selected</option>
                                <option value='input'>Input</option>
                                { renderAuthSelect(this,'authorized','provider') }
                            </select>
                            {this.state.providerinput && <input id={this.state.id+'_providerinput'} placeholder='provider@myalyce.com'></input>}
                            <button onClick={this._providerOnClick}>Add</button>
                        </div>
                    }
                    <button onClick={this._removeProvider}>Remove</button>
                </div>
            }
            <div className="input-group p-2">
                <label className="input-group-addon">Clients</label>
                <select id={this.state.id+'_clients'} className="form-select">
                {this.state.group.clients.map((item:any) => 
                    <option value={item} id={this.state.id+'client'+item}>{getUserNameById(this,item,this.state.id+'client'+item)}</option>
                )}
                </select>
            </div>
            {(this.state.group.admins.indexOf(this.props.parentUser._id) > -1) &&
                <div>
                    <button onClick={this._addClient}>Add</button>
                    {this.state.addingclient &&
                        <div>
                            <select id={this.state.id+'_client'} className="form-select" onChange={this._clientOnChange}>
                                <option value='none'>None Selected</option>
                                <option value='input'>Input</option>
                                { renderAuthSelect(this,'authorizer','provider') }
                            </select>
                            {this.state.clientinput && <input id={this.state.id+'_clientinput'} placeholder='client@myalyce.com'></input>}
                            <button onClick={this._clientOnClick}>Add</button>
                        </div>
                    }
                    <button onClick={this._removeClient}>Remove</button>
                </div>
            }
            </div>}
            <table className="table table-striped table-hover table-scroll">
                <thead>
                    <tr>
                        <th></th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Fitbit</th>
                        <th>+/- Client</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.allUsers.map((user) => {
                        const { initials, profColor, codeName } = getUserCodes(user);
                        
                        return <tr>
                            <td><figure className="avatar avatar-lg" data-initial={initials} style={{backgroundColor: profColor}}></figure></td>
                            <td>{codeName}</td>
                            <td>{user.fullName}</td>
                            <td>{user.email}</td>
                            <td style={{ color: user.fitbit ? 'green' : 'red' }}>{user.fitbit ? '✓' : '✗'}</td>
                            <td>
                                <button
                                    className={`btn btn-${(this.state.clientBoolDict as any)[user._id] ? 'error' : 'success'}`}
                                    onClick={() => this.toggleUser(user)}
                                    style={{ minWidth: '1.6rem' }}
                                >
                                {(this.state.clientBoolDict as any)[user._id] ? '-' : '+'}
                            </button></td>
                        </tr>
                    })}
                    
                </tbody>
            </table>
            <div className="card-footer"></div>
            </div>
            }
        </div>
        )
    }

}

export class GroupRoles extends Component<UserProps,S> {

    state = {
        id:randomId('grouproles'),
        owner:false,
        admin:false,
        provider:false,
        client:false,
        nGroups: this.props.platform.getLocalData('group').filter((struct:any)=>{if(struct.users.indexOf(this.props.parentUser._id) >-1 ) return true; else return false;}).length
    }

    constructor(props:GroupProps) {
        super(props);
    }

    componentDidMount() {
        this._groupselectOnChange();
    }

    componentWillUnmount() {}

    _addRole = async () => {
        let select1 = (document.getElementById(this.state.id+'group') as HTMLSelectElement);
        let select2 = (document.getElementById(this.state.id+'role') as HTMLSelectElement);
        let value = select1.options[select1.selectedIndex].value;

        const groupObj = await groupApi.search({'_id': value})
        let group = groupObj.name;
        let role = select2.options[select2.selectedIndex].value;

        let user = store.getState().currentUser;
        if(!user) return;
        let groups = await groupApi.all()

        let found = groups.find((g:any) => {
            if(g.users.indexOf(this.props.parentUser._id) > -1) { //If you've been added to the role
                return true;
            } else return;
        });

        if(found) {
            if(found[role].indexOf(this.props.parentUser._id) > -1) {
                if(user) {
                    const send = { ...user };
                    let userRole = group+'_'+role.slice(0,-1)
                    if(Array.isArray(send.userRoles)) {
                        if(send.userRoles.indexOf(userRole) < 0) send.userRoles.push(userRole);
                        else return;
                    } else send.userRoles = [userRole];
                
                    userApi.patch(user._id, send).then(async (res) => {
                        if (res.type === 'ERROR') {
                            addNotification({ text: 'Failed to update info, please check for errors and try again', type: 'error' }, sec(15));
                        } else {
                            let changed = await this.props.platform.updateUserOnServer(send,this.props.platform.currentUser);
                            if(changed) this.props.platform.setAuthorizationsByGroupOnServer(this.props.platform.currentUser);
                            addNotification({ text: `Updated information for "${res.data.fullName}"`, type: 'success' }, sec(7.5));
                            store.action({ type: Action.clientsLoad });
                            this.setState({});
                        }
                    });
                }
            }
        }

    }

    _refreshRole = async () => {
        this.props.platform.setAuthorizationsByGroupOnServer(this.props.platform.currentUser);
    }

    _removeRole = async () => {
        let select = (document.getElementById(this.state.id+'roleselect') as HTMLSelectElement);
        let input = select.options[select.selectedIndex].value;
        let user = store.getState().currentUser;
        if(input.length > 0 && user) {
            const send = { ...user };
            if(Array.isArray(send.userRoles)) {
                let index = send.userRoles.indexOf(input);
                if(index > -1) send.userRoles.splice(index,1);
                else return;
            } else return;
        
            userApi.patch(user._id, send).then(async (res) => {
                if (res.type === 'ERROR') {
                    addNotification({ text: 'Failed to update info, please check for errors and try again', type: 'error' }, sec(15));
                } else {
                    let changed = await this.props.platform.updateUserOnServer(send,this.props.platform.currentUser);
                    const auths = await authApi.all()
                    if(changed) auths.forEach((auth:any) => {
                        if(auth.group?.indexOf(input) > -1) this.props.platform.deleteAuthorizationOnServer(auth._id);
                    });
                    addNotification({ text: `Updated information for "${res.data.fullName}"`, type: 'success' }, sec(7.5));
                    store.action({ type: Action.clientsLoad });
                    this.setState({});
                }
            });
        }
    }

    _groupselectOnChange = async () => {
        let select = (document.getElementById(this.state.id+'group') as HTMLSelectElement);
        if(!select) return;
        let groupid = (select.selectedIndex > -1) ? select.options[select.selectedIndex].value : undefined
        if (groupid){
            let group = await groupApi.search({'_id': groupid})
            if(group && this.props.parentUser) {
                let owner, admin, provider, client;


                owner = group.ownerId === this.props.parentUser._id;
                admin = group.admins.indexOf(this.props.parentUser._id) > -1
                provider = group.providers.indexOf(this.props.parentUser._id) > -1
                client = group.clients.indexOf(this.props.parentUser._id) > -1
                this.setState({owner:owner,admin:admin,provider:provider,client:client});
            }
        }
    }



    render() {
        return (
            <div id={this.state.id}>
                 <div className="form-group">
                    <label className="form-label">Setup User Role Permissions</label>
                    { this.state.nGroups > 0 && 
                    <span>
                        <div className="input-group p-2">
                        <span className="input-group-addon">Available Groups</span>
                            <select id={this.state.id+'group'} className="form-select">
                                {renderGroupSelectOptions(this)}
                            </select>
                        </div>
                        <div className="input-group p-2">
                        <span className="input-group-addon">Available Roles</span>
                            <select id={this.state.id+'role'} className="form-select">
                                {this.state.client && <option value='clients' selected>Client</option>}
                                {this.state.provider && <option value='providers'>Provider</option>}
                                {this.state.admin && <option value='admins'>Admin</option>}
                            </select>
                            <button onClick={this._addRole} className="btn btn-primary input-group-btn">Add</button>
                        </div>
                    </span>
                    }
            </div>
                    <br/>
                    <div className="form-group">
                    <div className="input-group p-2">
                        <label className="input-group-addon">Added Roles</label>
                        <select id={this.state.id+'roleselect'} className="form-select">
                        { store.getState().currentUser?.userRoles.map((item) => 
                                <option value={item}>{item}</option>
                            )
                        }
                        </select>
                        <button onClick={this._refreshRole} className="btn btn-primary input-group-btn">Refresh</button>
                        <button onClick={this._removeRole} className="btn btn-secondary input-group-btn">Remove</button>
                    </div>
                </div>
            </div>
        )
    }
}



class GroupsContainer extends Component<ContainerProps,S> {
    state = {
        id:randomId('groups'),
        editing:false,
    }

    sub:number|undefined = 0

    constructor(props:ContainerProps) {
        super(props);   
    }

    componentDidMount() {
        setupContainerRefresh(this,'group',this.sub);
    }

    componentWillUnmount() {
        removeContainerRefresh(this.sub);
    }

    _update = () => {
        this.setState({})
    }

    _editing = () => {
        this.setState({editing:!this.state.editing})
    }

    _refresh = async () => {
        let groups = await groupApi.all()
        this.setState({editing:false})
        this.setState({groups:groups.filter((struct:any)=>{if(struct.users.indexOf(this.props.parentUser._id) >-1 ) return true; else return false;})});
    }

    render() {

        let groupsToView = (this.props.structs) ? this.props.structs.filter(o => o.users.indexOf(this.props.parentUser._id) >-1) : []

        return (
        <div id={this.state.id} className="panel">
            <div className="panel-header">
                <div className="panel-title h6 float-left">Groups</div>
                <div className="float-right">
                    <button onClick={this._editing} className="btn btn-primary mr-2 btn-sm">New</button>
                    <button onClick={this._refresh} className="btn btn-secondary btn-sm">Refresh</button>
                </div>
            </div>
            <div className="panel-body">
                {groupsToView.map((item:any) => 
                    <Group
                        platform={this.props.platform}
                        parentUser={this.props.parentUser}
                        group={item}
                    />
                )}
                <br/>
                <GroupRoles
                    platform={this.props.platform}
                    parentUser={this.props.parentUser}
                />
                <br/>
                <GroupCreator
                    platform={this.props.platform}
                    parentUser={this.props.parentUser}
                    updateParent={this._update}
                    editing={this.state.editing}
                    toggleEditor={this._refresh}
                />
                </div> 
                <div className="panel-footer"></div>
        </div>
        )
    }

}

export default linker((s) => ({ structs: s.group}), GroupsContainer);
