import React, { Component } from 'react'
import { randomId } from '@myalyce/common';
import { UserPlatform } from 'frontend/js/UserPlatform';
import { UserFitbit } from '@myalyce/common/models/user.model';
import SleepChart from '../SleepChart.component';
import { getUserFitBitToken } from 'frontend/utils/general';
import { eventApi } from 'frontend/apis/event.api';
import { authApi } from 'frontend/apis/authorization.api';
import { CgAppleWatch } from 'react-icons/cg';
import { toggleLoader } from 'frontend/utils/store.utils';
import { fitbitTokenApi } from 'frontend/apis/fitbit-auth.api';

interface S {}

type FitBitProps = {platform: UserPlatform, parentUser: any, setting:string, fitbit: UserFitbit}

export class FitBitView extends Component<FitBitProps, S> {
    state = {
        id:randomId('fitbitview'),
        parentUser: (undefined as any),
        fitbit: (undefined as any),//pass a blank object if no token (we'll use the parentUser to get it instead)
        fitbitUid: (undefined as any),
        events: [],
        setting: (undefined as any)
    }

    chartRef=(undefined as any)
    sub:number|undefined=0;

    constructor(props:FitBitProps) {
        super(props);
        this.state.fitbit = this.props.fitbit;
        this.state.setting = this.props.setting;
    }

    componentDidMount = async () => {
        //if user is not owner check that they are authorized
        //then pull the appropriate users' fitbit credentials (or use store.currentUser)
        //check if the appropriate credentials exists
        //pass those credentials SleepChart etc. to pull data/render 
        
        //this.setupFitbit();
        // this.sub = comState.subscribeTrigger('socketResult',(res) =>{//temp
        //     if(res.data?.user) {
        //         this.setupFitbit();
        //     }
        // });
           
    }

    componentWillUnmount() {
        //comState.unsubscribeTrigger('socketResult',this.sub);
    }

    setupFitbit = async () => {

        
        if(this.state.setting === 'sleep') {
            this.chartRef = React.createRef<SleepChart>()
        }

        let events = await eventApi.search({'ownerId':this.props.parentUser._id})
        //should pass the events to the chart to render

        let getFitbit = async () => {
            let passed = true;
                if(this.props.platform.currentUser._id !== this.props.parentUser._id) {
                    passed = false;
                    let auths = await authApi.search({'ownerId':this.props.parentUser._id})
                    auths.find(async (auth:any)=>{
                        if(auth.authorizerId === this.props.parentUser._id 
                            && auth.authorizedId === this.props.platform.currentUser._id
                            && auth.status === 'OKAY' 
                            && auth.authorizations.indexOf('provider') > -1) {
                                passed = true;
                                return true;
                        } else return;
                    });
                }
                if(passed) {
                    let userToken = await getUserFitBitToken(this.props.parentUser);
                    //console.log(userToken);
                    let fitbit = userToken?.fitbit;
                    //console.log(fitbit) 
                    if(fitbit) {
                        this.setState({fitbit:fitbit, fitbitUid: this.props.parentUser._id, events:events});
                    } else {
                        this.setState({fitbit:undefined, fitbitUid: this.props.parentUser._id});
                    }
                } else this.setState({fitbit:undefined, fitbitUid: this.props.parentUser._id});
        }
        
        if(typeof this.state.fitbit === 'object'){ 
            if(!this.state.fitbit.id && !this.state.fitbit.token){
                await getFitbit();
            }
        } else await getFitbit();
    }

    render() {
        if(this.state.fitbitUid !== this.props.parentUser?._id) {
            this.setupFitbit();
            // console.log(this.chartRef)
            // this.chartRef?.current?.setState({data:null});
            // this.chartRef?.current?.loadData(this.state.fitbit);
        }

        return (
        <div id={this.state.id}>
            {this.state.fitbit?.id && 
            <div>
                {this.state.setting === 'sleep' && 
                    <SleepChart
                        fitbit={this.state.fitbit}
                        ref={this.chartRef}
                    />
                }
                {this.state.setting === 'hr' &&
                    <div>

                    </div>
                }
            </div>
            }
        </div>
        )
    }
}

const authorizeFitbit = () => {
    toggleLoader('fitbitAuth', true);
    fitbitTokenApi.authorizeRedirect();

    // in case it gets stuck retry:
    setInterval(fitbitTokenApi.authorizeRedirect, 3500);
}

export const FitbitDisplayEmptyState = (p: { isLoading: boolean; }) => <div className="empty">
    <div className="empty-icon">
        {p.isLoading ? <div className="loading" /> : <CgAppleWatch className="icon" />}
    </div>
    <p className="empty-title h5">{p.isLoading ? 'Loading device data' : 'Client has no devices'}</p>
    <p className="empty-subtitle">Click button to add device.</p>
    <div className="empty-action">
        <button
            // disabled={true}
            onClick={authorizeFitbit}
            className={`btn btn-primary${p.isLoading ? ' loading' : ''}`}
        >Add Device</button>
    </div>
</div>