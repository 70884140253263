import { arrFlatten, hasKey, nonValue, isType, minAppend } from '@giveback007/util-lib';
import { AnyDate } from '@myalyce/common/common-types';
import { dictToUrlParams } from 'frontend/utils/general';
import { Api } from 'rest-api-handler';
import { Badge } from './types/Badges.type';
import { FitbitError } from './types/FitbitError.type';
import { ActivitiesHeartIntraday, ActivitiesHeartRate } from './types/HeartRate.type';
import { FitbitProfile } from './types/profile.type';
import { Sleep, SleepGoal, SleepGoalDetails } from './types/Sleep.type';

/** Returns date string in `yyyy-MM-dd` format */
function toDateStr(date: AnyDate) {
    const dt: Date = hasKey(date, 'y') ? new Date(date.y, date.m, date.d) : isType(date, 'number') || isType(date, 'string') ? new Date(date) : date;

    if (dt.toDateString() === 'Invalid Date') {
        console.error(`date: ${date.toString()} is an invalid date`);
        throw new Error('Invalid Date');
    }

    return isType(date, 'string') ? date : `${dt.getFullYear()}-${minAppend(dt.getMonth() + 1, 2, '0')}-${minAppend(dt.getDate(), 2, '0')}`;
}

type Pagination = {
    afterDate?: string;
    beforeDate?: string;
    limit: number;
    next: string;
    offset: number;
    previous: string;
    sort: 'asc' | 'desc';
}

export class FitbitApi {
    private api = new Api('https://api.fitbit.com');

    private url = (version: 1 | 1.1 | 1.2, namespace: string) =>
        `${version}/user/${this.fitBitUserId}/${namespace}`;

    constructor(
        private accessToken: string,
        private fitBitUserId: string = '-',
        /** Optional function that will atempt to auto refresh the token when expired */
        private getToken?: () => Promise<string>
    ) {
        this.setAccessToken(accessToken);
    }

    getInfo = () => ({
        accessToken: this.accessToken,
        fitBitUserId: this.fitBitUserId,
    });

    sleep = {
        /** Returns a list of a user's sleep log entries before `beforeDate` or after `afterDate` a given date specifying offset, limit and sort order.
         * 
         * https://dev.fitbit.com/build/reference/web-api/sleep/get-sleep-log-list/
         */
        get: async (opts: {
            sort?: 'asc' | 'desc';
            limit?: number;
            offset?: number;
        } & ({
            afterDate: AnyDate;
            /** Set true to load all data afterDate (this will make multiple calls to collect any data from afterDate to most recent) */
            loadAll?: boolean;
        } | {
            beforeDate: AnyDate;
        })) => {
            const dtStr = (anyDt: AnyDate | undefined) => nonValue(anyDt) ? undefined : toDateStr(anyDt);
            type SleepData = {
                pagination: Pagination;
                sleep: Sleep[];
            };
  
            const namespace = this.url(1.2, `sleep/list.json`);
            const send = {
                limit: opts.limit || 100,
                sort: opts.sort || 'asc',
                offset: opts.offset || 0,
                beforeDate: dtStr((opts as any).beforeDate),
                afterDate: dtStr((opts as any).afterDate),
            }
            
            if (send.limit > 100 || send.limit < 1) throw new Error(`Invalid limit: ${send.limit}, needs to be between 1 to 100`);
            if (!send.beforeDate) delete send.beforeDate;
            if (!send.afterDate) delete send.afterDate;

            // if does `not` have key `loadAll`
            if (!hasKey(opts, 'loadAll')) 
                return this.handleData<SleepData>(() => this.api.get(namespace, send));
            
            // -- Load All from afterDate to most recent -- //
            // try to make the first call for data
            const resTry = await this.handleData<SleepData>(() => this.api.get(namespace, send));
            if (resTry.type === 'ERROR') return resTry;

            // while loop
            let res = resTry;
            let next = res.data.pagination.next;
            const allArr = [res.data.sleep];

            while (next) {
                const resLoop = await this.handleData<SleepData>(() => this.api.get(next));
                if (resLoop.type === 'ERROR') return res;

                res = resLoop;
                next = res.data.pagination.next;
                allArr.push(res.data.sleep);
            }

            // flatten arr add all collected sleep data to list
            res.data.sleep = arrFlatten(allArr);
            return res;
        },

        /** Deletes a sleep log with the given log id.
         * 
         * https://dev.fitbit.com/build/reference/web-api/sleep/delete-sleep-log/
         */
        delete: (sleepLogId: string) => this.handleData<null>(() => this.api.delete(this.url(1.2, `sleep/${sleepLogId}.json`))),

        /** Creates a log entry for a sleep event and returns a response in the format requested.
         * (May not be functining correctly due to fitbit servers?)
         * 
         * https://dev.fitbit.com/build/reference/web-api/sleep/create-sleep-log/
         */
        create: (opts: {
            /** Start time includes hours and minutes in the format HH:mm. */
            startTime: string;
            /** Duration in milliseconds. */
            duration: number;
            /** Log entry in the format yyyy-MM-dd. */
            date: string;
        }) =>  this.handleData<{ sleep: Sleep; }>(() => this.api.post(this.url(1.2, `sleep.json${dictToUrlParams(opts)}`))),

        /** Returns a list of a user's sleep log entries for a given date.
         * 
         * https://dev.fitbit.com/build/reference/web-api/sleep/get-sleep-log-by-date/
         */
        getByDate: ({ date }: { date: AnyDate; }) => this.handleData<{
            sleep: Sleep[];
            summary: {
                stages: {
                    deep: number,
                    light: number,
                    rem: number,
                    wake: number,
                },
                totalMinutesAsleep: number;
                totalSleepRecords: number;
                totalTimeInBed: number;
            };
        }>(() => this.api.get(this.url(1.2, `sleep/date/${toDateStr(date)}.json`))),

        /** Returns a list of a user's sleep log entries for a given date range.
         * 
         * https://dev.fitbit.com/build/reference/web-api/sleep/get-sleep-log-by-date-range/
         */
        getByDateRange: (opts: { startDate: AnyDate; endDate: AnyDate }) =>
            this.handleData<{ sleep: Sleep; }>(() => this.api.get(this.url(1.2, `sleep/date/${toDateStr(opts.startDate)}/${toDateStr(opts.endDate)}.json`))),
    } as const;

    sleepGoal = {
        /** Create or update a user's sleep goal.
         * 
         * https://dev.fitbit.com/build/reference/web-api/sleep/create-sleep-goals/
         */
        create: (opts: {
            /** Length of sleep goal in minutes. */
            minDuration: string | number;
        }) => this.handleData<SleepGoal>(() => this.api.post(this.url(1.2, `sleep/goal.json${dictToUrlParams(opts)}`))),

        /** Returns a user's current sleep goal.
         * 
         * https://dev.fitbit.com/build/reference/web-api/sleep/get-sleep-goals/
         */
        get: () => this.handleData<SleepGoalDetails>(() => this.api.get(this.url(1.2, 'sleep/goal.json'))),
    } as const;

    /** The Heart Rate Time Series endpoints are used for querying the user's heart rate data.
     * 
     * https://dev.fitbit.com/build/reference/web-api/heartrate-timeseries/
     */
    heartRate = {
        /** Retrieves the heart rate time series data over a period of time by specifying a date and time period. The response will include only the daily summary values. 
         * 
         * https://dev.fitbit.com/build/reference/web-api/heartrate-timeseries/get-heartrate-timeseries-by-date/
         */
        getByDate: (opts: {
            date: AnyDate;
            period?: '1d' | '7d' | '30d' | '1w' | '1m';
        }) => this.handleData<{
            "activities-heart": ActivitiesHeartRate[];
        }>(() => this.api.get(this.url(1, `activities/heart/date/${toDateStr(opts.date)}/${opts.period || '1d'}.json`))),

        /** Retrieves the heart rate time series data over a period of time by specifying a date range. The response will include only the daily summary values.
         * 
         * https://dev.fitbit.com/build/reference/web-api/heartrate-timeseries/get-heartrate-timeseries-by-date-range/
         */
        getByDateRange: (opts: {
            startDate: AnyDate | 'today';
            endDate: AnyDate | 'today';
        }) => this.handleData<{
            "activities-heart": ActivitiesHeartRate[];
        }>(() => this.api.get(this.url(1, `activities/heart/date/${toDateStr(opts.startDate)}/${toDateStr(opts.endDate)}.json`))),
    } as const;

    intradayHR = {
        getByDate: ({ date, detailLevel }: {
            date: AnyDate | 'today';
            detailLevel: '1sec' | '1min' | '5min' | '15min';
        }) => this.handleData<{
            "activities-heart": ActivitiesHeartRate[];
            "activities-heart-intraday": ActivitiesHeartIntraday;
        }>(() => this.api.get(this.url(1, `activities/heart/date/${date === 'today' ? date : toDateStr(date)}/1d/${detailLevel}.json`))),
    } as const;

    /** The User endpoints display information about the user's profile information, the regional locale & language settings, and their badges collected.
     * 
     * https://dev.fitbit.com/build/reference/web-api/user/
     */
    user = {
        /** Retrieves a list of the user’s badges.
         * 
         * https://dev.fitbit.com/build/reference/web-api/user/get-badges/
         * */
        getBadges: () =>
            this.handleData<{ badges: Badge[]; }>(() => this.api.get(this.url(1, `badges.json`))),

        /** Retrieves the user's profile data.
         * 
         * https://dev.fitbit.com/build/reference/web-api/user/get-profile/
         */
        getProfile: () =>
            this.handleData<{ user: FitbitProfile; }>(() => this.api.get(this.url(1, `profile.json`))),

        /** Modifies a user's profile data. (Doesn't work becouse of fitbit servers?)
         * 
         * https://dev.fitbit.com/build/reference/web-api/user/update-profile/
         */
        updateProfile: async (opts: Partial<{
            /** The sex of the user; (MALE/FEMALE/NA). */
            gender: "MALE" | "FEMALE" | "NA",
            /** The date of birth in the format of yyyy-MM-dd. */
            birthday: string,
            /** The height in the format X.XX in the unit system that corresponds to the Accept-Language header provided. */
            height: string,
            /** This is a short description of user. */
            aboutMe: string,
            /** The fullname of the user. */
            fullname: string,
            /** The country of the user's residence. This is a two-character code. */
            country: string,
            /** The US state of the user's residence. This is a two-character code if the country was or is set to US. */
            state: string,
            /** The US city of the user's residence. */
            city: string,
            /** Walking stride length in the format X.XX, where it is in the unit system that corresponds to the Accept-Language header provided. */
            strideLengthWalking: string,
            /** Running stride length in the format X.XX, where it is in the unit system that corresponds to the Accept-Language header provided. */
            strideLengthRunning: string,
            /** Default weight unit on website (which doesn't affect API); one of (en_US, en_GB, 'any' for METRIC). */
            weightUnit: 'en_US' | 'en_GB' | 'any',
            /** Default height/distance unit on website (which doesn't affect API); one of (en_US, en_GB, 'any' for METRIC). */
            heightUnit: 'en_US' | 'en_GB' | 'any',
            /** Default water unit on website (which doesn't affect API); one of (en_US, en_GB, 'any' for METRIC). */
            waterUnit: 'en_US' | 'en_GB' | 'any',
            /** Default glucose unit on website (which doesn't affect API); one of (en_US, en_GB, 'any' for METRIC). */
            glucoseUnit: 'en_US' | 'en_GB' | 'any',
            /** The timezone in the format, eg: 'America/Los_Angeles'. */
            timezone: string,
            /** The food database locale in the format of xx.XX. */
            foodsLocale: string,
            /** The locale of the website (country/language); one of the locales, currently – (en_US, fr_FR, de_DE, es_ES, en_GB, en_AU, en_NZ, ja_JP). */
            locale: 'en_US' | 'fr_FR' | 'de_DE' | 'es_ES' | 'en_GB' | 'en_AU' | 'en_NZ' | 'ja_JP',
            /** The Language in the format 'xx'. You should specify either locale or both - localeLang and localeCountry (locale is higher priority). */
            localeLang: string,
            /** The Country in the format 'xx'. You should specify either locale or both - localeLang and localeCountry (locale is higher priority). */
            localeCountry: string,
            /** The Start day of the week, meaning what day the week should start on. Either Sunday or Monday. */
            startDayOfWeek: 'Sunday' | 'Monday',
        }>) => this.handleData<{ user: FitbitProfile; }>(() => this.api.post(this.url(1, `profile.json${dictToUrlParams(opts)}`))),
    } as const;

    // /** 
    //  * The Subscription endpoints allow an application to subscribe to user specific data.
    //  * Fitbit will send a webhook notification informing the applicaton that the user has new data to download.
    //  * This functionality prevents the application from polling our services looking for new data. 
    //  * 
    //  * https://dev.fitbit.com/build/reference/web-api/subscription/
    //  */
    // subscription = {
    //     create: () => '',
    //     delete: () => '',
    //     getList: () => '',
    // } as const;

    setAccessToken(token: string) {
        this.accessToken = token;
        this.api.setDefaultHeader('Authorization', `Bearer ${this.accessToken}`);
    }

    private async handleData<T>(dataCall: () => Promise<Response>, nRecur = 0): Promise<({
        type: "ERROR";
        isSuccess: false;
        /** https://dev.fitbit.com/build/reference/web-api/troubleshooting-guide/error-messages/ */
        error: FitbitError;
    } | {
        type: "SUCCESS";
        isSuccess: true;
        data: T;
    }) & {
        code: number;
        response: Response;
    }> {
        const response = await dataCall();
        let json: T | FitbitError;

        try {
            json = await response.json();
        } catch {
            if (!response.ok) {
                console.error(response);
                throw new Error('Unhandled error.');
            }

            json = null as any;
        }
        
        if (hasKey(json, 'errors')) {
            const { errorType: err } = json.errors[0];
            if ((err === "expired_token" || err === "invalid_token") && this.getToken && nRecur < 2) {
                try {
                    const token = await this.getToken();
                    this.setAccessToken(token);
                    return await this.handleData(dataCall, nRecur + 1);
                } catch(err) {
                    console.error(err);
                }
            }
    
            return {
                type: 'ERROR',
                isSuccess: false,
                code: response.status,
                error: json,
                response,
            };
        }
    
        return {
            type: 'SUCCESS',
            isSuccess: true,
            code: response.status,
            data: json,
            response,
        };
    }
}
