import React, { Component } from 'react';
import Plot from 'react-plotly.js';
import { AiFillDatabase } from 'react-icons/ai'
import { UserFitbit, UserObj } from '@myalyce/common/models/user.model';
import { average, days, hasKey, weeks } from '@giveback007/util-lib';
import { genDateStr, minToHr } from 'frontend/utils/general';
import { getSleepData } from 'frontend/apis/fitbit-data/fitbit-sleep';
import { AnyDate } from '@myalyce/common/common-types';
import { Sleep } from 'frontend/apis/fitbit-data/types/Sleep.type';

type SleepDataProps = {
    startDay: string;
    endDay: string;
    nOfDays: number;
    dateMarkers: string[];
    sleepData: (Sleep | null)[];
    sma30: (number | null)[];
    sma7: (number | null)[];
    sleepLevels: {
        deep: (number | null)[];
        light: (number | null)[];
        rem: (number | null)[];
        wake: (number | null)[];
        asleep: (number | null)[];
    };
};

interface P {
    fitbit: UserObj['fitbit'];
    zoom?: {
        /** Start date of x marker */
        startDate: AnyDate,
        /** n amount of days from startDate */
        nDays: number,
    } | {
        /** How many days back from today */
        nDaysBack: number,
    };
}

interface S {
    data: SleepDataProps | null;
    isLoading: boolean;
}

export default class SleepChart extends Component<P, S> {
    state: S = {
        data: null,
        isLoading: true,
    }

    prevFitbitId: string | null = null;

    loadData = async (fitbit: UserFitbit, afterDate = Date.now() - weeks(52)) => {
        this.setState({ isLoading: true });
        const sleepDataResult = await getSleepData(fitbit, afterDate, true);

        if (!sleepDataResult) return this.setState({ data: null, isLoading: false });
        if (sleepDataResult.fitbitId !== this.props.fitbit?.id) return;

        const { sleepData } = sleepDataResult;
        const y = sleepData.map((x) => minToHr(x ? x.minutesAsleep : 0));

        const sma30: (number | null)[] = [];
        const sma7: (number | null)[] = [];

        let nOfMissing = 0;
        let i30 = 0;
        let i7 = 0;
        
        for (let i = 0; i < sleepData.length; i++) {
            nOfMissing = !y[i] ? nOfMissing + 1 : 0;

            if (i > 30) i30++;
            if (i > 7) i7++;

            const arr30 = y.slice(i30, i + 1).filter(x => x) as number[];
            // if length < 2/3 return null
            sma30.push((i30 >= 20 && nOfMissing < 15) ? Number(average(arr30).toFixed(1)) : null);
            
            const arr7 = y.slice(i7, i + 1).filter(x => x) as number[];
            sma7.push(i7 >= 5 && nOfMissing < 4 ? Number(average(arr7).toFixed(1)) : null);
        }

        let data = {...sleepDataResult, sma30, sma7};
        this.setState({ data: data, isLoading: false });
        return data;
    }

    componentDidMount() {
        const { fitbit } = this.props;

        if (fitbit)
            this.loadData(fitbit);
        else
            this.setState({ isLoading: false, data: null });
    }

    componentDidUpdate() {
        const { fitbit } = this.props;

        if (this.prevFitbitId !== (fitbit?.id || null)) {
            this.prevFitbitId = fitbit?.id || null;
            this.setState({ data: null });

            if (fitbit) this.loadData(fitbit);
        }
    }

    render() {
        const { data, isLoading } = this.state;
        const { fitbit, zoom } = this.props;
        
        let text = '';
        if (!fitbit) text = "User hasn't activated fitbit data";
        if (fitbit && isLoading && !data) text = 'Loading sleep data...';

        return <div style={{ overflow: 'hidden' }}>
            {text ? <div className="empty">
                <div className="empty-icon"><AiFillDatabase /></div>
                <p className="empty-title h5">{text}</p>
                {isLoading && <div className="loading loading-lg" />}
            </div> : <Chart {...{ data, zoom }} />}
        </div>
    }
}

function Chart(p: { data: SleepDataProps | null, zoom: P['zoom'] }) {
    if (!p.data) return null;

    const { zoom, data } = p;
    const { dateMarkers, sleepLevels, sma7, sma30 } = data;
    const { asleep, deep, rem, light, wake } = sleepLevels;

    let range: string[] | undefined;
    if (zoom) {
        const now = Date.now();
        let startDate: string;
        let endDate: string;

        if (hasKey(zoom, 'nDaysBack')) {
            const start = genDateStr(now - days(zoom.nDaysBack), 'fitbit');
            if (!start || zoom.nDaysBack < 0) throw new Error(`zoom.nDaysBack: ${zoom.nDaysBack} is invalid`);

            startDate = start;
            endDate = genDateStr(now, 'fitbit') as string
        } else {
            const start = genDateStr(zoom.startDate);
            if (!start) throw new Error(`zoom.startDate: ${zoom.startDate} is invalid`);
            
            const end = genDateStr(new Date(start).getTime() - days(zoom.nDays), 'fitbit');
            if (!end) throw new Error(`zoom.end: ${zoom.nDays} is invalid`);

            startDate = start;
            endDate = end;
        }

        range = [startDate, endDate];
    }

    return <Plot
        style={{ width: '100%' }}
        useResizeHandler={true}
        data={[{
            x: dateMarkers,
            y: asleep,
            name: 'Un-categorized Sleep',
            type: 'bar',
            marker: { color: 'green' }

        }, {
            x: dateMarkers,
            y: deep,
            name: 'Deep-Sleep',
            type: 'bar',
            marker: { color: '#0C0458' }
        }, {
            x: dateMarkers,
            y: rem,
            name: 'Rem-Sleep',
            type: 'bar',
            marker: { color: '#094571' }
        }, {
            x: dateMarkers,
            y: light,
            name: 'Light-Sleep',
            type: 'bar',
            marker: { color: '#339BFF' }
        }, {
            x: dateMarkers,
            y: wake,
            name: 'Awake',
            type: 'bar',
            marker: { color: 'orange' }
        },

        // SMA:
        {
            x: dateMarkers,
            y: sma30,
            name: '30 Days SMA',
            type: 'scatter',
            mode: 'lines',
            marker: { color: 'red' },
        }, {
            x: dateMarkers,
            y: sma7,
            name: '7 Days SMA',
            type: 'scatter',
            mode: 'lines',
            marker: {color: 'green'},
        }]}

        layout={{
            title: 'Sleep',
            barmode: 'stack',
            xaxis: { range, },
            autosize: true,
            // plot_bgcolor:  '#F7F8F9',
            // paper_bgcolor: '#F7F8F9',
        }}
    />;
}
