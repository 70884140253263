import { initModel } from "..";
import { BaseObj } from "./_base.model";
import { CommentObj } from './comment.model';

export const type = 'chatroom';
export class ChatroomObj extends BaseObj<typeof type> {
        message: string = '';
        authorId: string = '';
        attachments: string[] = new Array();
        comments: string[] = new Array();
        replies: string[] | CommentObj[] = new Array(); // string references or direct comment object
        users: string[] = new Array();
        audioChatActive: boolean = false;
        videoChatActive: boolean = false;

        webrtc: boolean = false;
        // ...this.structProps

    constructor(p?: Partial<ChatroomObj>) {
        super(type, p);
        initModel(this, p);
    }
}
