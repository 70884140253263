import { isType } from '@giveback007/util-lib';
import { UserObj_S } from 'frontend/store/store';
import React, { ReactNode, useState } from 'react';
import { MouseEventHandler } from 'react';
import { IconType } from 'react-icons/lib';
import { genChatTime } from "../../utils/general";
import { getUser } from '../../utils/store.utils';

type ChipSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const ChatTitle = (p: {name: string, time: string | Date | number}) =>
    <>{p.name} - <span>{genChatTime(p.time)}</span></>

export type ChipProps = {
    label: string;
    img?: AvatarProps['img'];
    chipColor?: string;
    onClose?: () => any;
    style?: React.CSSProperties;
}
/** https://picturepan2.github.io/spectre/components/chips.html#chips */
export const Chip = (p: ChipProps) =>
    <div
        className="chip"
        style={{ backgroundColor: p.chipColor, ...(p.style || {}) }}
    >
        {p.img && <Avatar
            img={p.img}
            size='sm'
        />}{p.label}
        {p.onClose && <a className="btn btn-clear" aria-label="Close" role="button" onClick={p.onClose} />}
    </div>;

export type AvatarProps = {
    img: { src?: string; initials: string | number; backgroundColor?: string };
    badge?: boolean | number;
    status?: 'online' | 'offline' | 'away' | 'busy' | null;
    size: ChipSize;
    style?: React.CSSProperties;
    onClick?: MouseEventHandler;
};
/** https://picturepan2.github.io/spectre/components/avatars.html#avatars */
export const Avatar = (p: AvatarProps) =>
    <figure
        className={`avatar avatar-${p.size} ${p.badge ? ' badge' : ''} ${(p.onClick) ? 'clickable' : '' }`}
        data-initial={p.img.initials}
        data-badge={(isType(p.badge, 'number') && p.badge) || undefined}
        style={{ backgroundColor: p.img.backgroundColor, ...(p.style || {}) }}
        onClick={p.onClick}
    >
        {p.img.src && <img src={p.img.src}/>}
        {p.status && <i className={`avatar-presence ${p.status}`} />}
    </figure>;

/** TODO: get this working */
export const UserAvatar = (p: { userId: string; type: 'chip' | 'avatar' }) => {
    const user = getUser(p.userId);
    if (user.isLoading) {

    } else {

    }
}

// subToSuspenseDict
    // when subbing to suspense-dictionary you have 4 possible values
    // T | 'error' | 'loading' | undefined

/** internally this will make the necessary http request to get the value */
// store.subToSuspenseDict('dictKeyOnState', 'keyOnDict', (val, dict, state, prevState) => {

// }, 'instant');

// store.refreshDictKeys('dictKeyOnState', ['keyOnDict'] | 'keyOnDict') => Promise<T | 'error'>

/** This isn't functioning yet */
export const ExpandableTd = (p: { expand: boolean | 'onClick'; children?: ReactNode | undefined; style?: React.CSSProperties }) => {
    const [expand, setExpand] = useState(false);
    if (p.expand !== 'onClick') setExpand(p.expand);
    
    return <><div className="divider" /><p
        className={`expandable ${expand ? 'expand' : 'collapse'}`}
        onClick={() => setExpand(!expand)}
        style={p.style}
    >{p.children}</p></>
}

type ProfileBadgeProps = { user: UserObj_S; badge?: AvatarProps['badge']; status?: AvatarProps['status']; className?: string; };
export function ProfileBadge(p: ProfileBadgeProps) {
    return <div className={`profile-badge ${p.className || ''}`}>
        <Avatar
            img={{
                initials: p.user.initials,
                backgroundColor: p.user.profColor,
                // src: logo 
            }}
            size='md'
            badge={p.badge}
            status={p.status}
        />
        <div className="profile-info">
            {p.user.firstName && <p>{`${p.user.firstName} ${p.user.lastName}`}</p>}
            <small className="tile-subtitle text-gray">{p.user.email}</small>
        </div>
    </div>
}

export function PanelSliceTitle(p: {
    title?: JSX.Element | string;
    subtitle?: JSX.Element | string;
    titleAction?: { icon: IconType, onClick: MouseEventHandler; }
}) {
    return <div className="tile tile-centered">
        <div className="tile-content">
            {p.title && <div className="tile-title text-bold">{p.title}</div>}
            {p.subtitle && <div className="tile-subtitle">{p.subtitle}</div>}
        </div>
        {p.titleAction && <div className="tile-action">
            <button
                className="btn btn-link btn-action btn-lg"
                onClick={p.titleAction.onClick}
            >{p.titleAction.icon}</button>
            {/* <i className="icon icon-edit"></i> */}
        </div>}
    </div>
}