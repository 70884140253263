import { dataInstanceApi } from "frontend/apis/dataInstance.api";
import { eventApi } from "frontend/apis/event.api";
import { randomId } from "frontend/js/UserPlatform";
import React, { Component } from "react";
import { DataCreator } from "./Data.components";
import { EventCreator } from "./Events.component";
import { AttachmentProps } from "./Templates.shared";

interface S {}

export class AttachmentCreator extends Component<AttachmentProps, S> {
    
    state = {
        id:randomId('attachmentcreator'),
        editingdata:false,
        editingevent:false,
        attachmentList:[]
    }

    constructor(props:AttachmentProps) {
        super(props);
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {}

    getData = async () => {
        let att = await dataInstanceApi.search({'ownerId': this.props.parentUser._id});
        let att2 = await eventApi.search({'ownerId': this.props.parentUser._id});

        if ('data' in att) att = att.data
        if ('data' in att2) att2 = att2.data
        console.log(att, att2)
        let attachments: any[] = []
        if(att.length > 0 ) attachments = [...attachments, ...att]
        if(att2.length > 0 )  attachments = [...attachments,...att2]
        this.setState({attachmentList:attachments});
    }

    _submit = () => {
        let select = (document.getElementById(this.state.id+'_select') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        this.props.updateParent(value);
    }

    _onchange = () => {
        let select = (document.getElementById(this.state.id+'_select') as HTMLSelectElement);
        let value = select.options[select.selectedIndex].value;
        if(value === 'newdata') {
            select.value = 'none';
            this.setState({editingdata:true,editingevent:false});
        } else if (value === 'newevent') {
            this.setState({editingdata:false,editingevent:true});
        } else this.setState({editingdata:false,editingevent:false});
    }

    _updateAttachments = (attachment:any) => {
        console.log('UPDATE ATTACHMENTS', this.state.attachmentList,attachment)
        //console.log(this.state.attachments, attachment);
        let updated = [...this.state.attachmentList,attachment];
        this.setState({attachmentList:updated, editingdata:false, editingevent:false});
    }

    _gettileinfo = (item:any) => {
        if(item.title) return item.title;
        else if(item.event) return item.event;
        else return 'unknown';
    }

    render() {
        return (
                <div className="input-group">
                    <select id={this.state.id+'_select'} className="form-select" onChange={this._onchange}>
                        <option value='none' selected>None</option>
                        {/* <option value='newdata'>New Data</option>
                        <option value='newevent'>New Event</option> */}
                        {this.state.attachmentList.map((item:any) => //this should obviously separate events and data but this is a hack for now
                            <option value={item?._id}>{this._gettileinfo(item)}</option>
                        )}
                    </select>
                    {this.state.editingdata &&
                        <DataCreator
                            platform={this.props.platform}
                            parentUser={this.props.parentUser}
                            updateParent={this._updateAttachments}
                            editing = {this.state.editingdata}
                            toggleEditor = {this._updateAttachments}
                        />
                    }
                    {this.state.editingevent &&
                        <EventCreator
                            platform={this.props.platform}
                            parentUser={this.props.parentUser}
                            updateParent={this._updateAttachments}
                            editing = {this.state.editingdata}
                            toggleEditor = {this._updateAttachments}
                        />
                    }
                    <button id={this.state.id+'_submit'}  onClick={this._submit} className="btn btn-primary input-group-btn">Submit</button>
                </div>
        )
    }
}