// Adapted from Josh Brewster's implementation for the HEGduino
// Garrett Flynn, Nov 2021 (AGPL License) | totally not tested...

import Device from './Device.js'

export default class EventSourceDevice extends Device {

    constructor(constraints) {
        super(constraints)
    
        this.url = constraints.url;
        this.source = null;
        this.customCallbacks = customCallbacks;
    }

// ---------------------- CORE ----------------------

//create a function to post to URLS with optional data, usernames, and passwords
newPostCommand(name="post",url=this.url,data=undefined,user=undefined,pass=undefined) {
    const func = () => {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true, user, pass);
        xhr.send(data); //Accepts: string | Document | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array>
        xhr.onerror = function() { xhr.abort(); };
    }
    this[name] = func;

    return func;
}

send = async (body, url=this.url) => await fetch(url, {method: 'POST', body})

connect = () => this.createEventListeners();

disconnect = () => this.removeEventListeners();

// ---------------------- CALLBACKS ----------------------

onconnect = (e) => console.log("Event source connected!", e.data);

onerror = (e) => {
    console.log("Error:", e.data);
    if (e.target.readyState !== EventSource.OPEN) {
        console.log("Event source disconnected!");
    }
}

ondata = (e) => console.log("event source:", e.data);

// ---------------------- INTERNAL UTILITIES ----------------------

createEventListeners = () => {
    if(this.source !== null) this.removeEventListeners(this.customCallbacks, this.source);
    if(window.EventSource) {
        this.source = new EventSource(this.url);
        this.source.addEventListener('open', this.onconnect, false);
        this.source.addEventListener('error', this.onerror, false);
        this.source.addEventListener('message', this.ondata, false);
        if(this.customCallbacks.length > 0){
            this.customCallbacks.forEach((item,i) => {
                this.source.addEventListener(item.tag, item.callback, false);
            })
        }
    }
    this.onconnect(this)
}

removeEventListeners = () => {
    if (window.EventSource) {
        source.close();
        source.removeEventListener('open', this.openEvent, false);
        source.removeEventListener('error', this.errorEvent, false);
        source.removeEventListener('message', this.messageEvent, false);
        if(customCallbacks.length > 0){
            customCallbacks.forEach((item,i) => {
                source.removeEventListener(item.tag, item.callback, false);
            });
        }
        source = null;
    }
    this.ondisconnect(this)
}

}