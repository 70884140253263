

import {StateManager} from './js/StateManager'
import { UserPlatform } from "./js/UserPlatform";

export const platform = new UserPlatform();

export const comState = new StateManager({
    socketResult:platform.socketResult
},333 as any); //state manager w/ interval updates

export const onResult = (res:any) => {
    comState.setState({socketResult:res});
    let toResolve = [];
    if(Array.isArray(res.data)) {
        res.data.forEach((struct: any) => {
            if(struct._id) {
                if((comState.data as any)[struct._id] !== undefined) {
                    comState.setState({[struct._id]:struct});
                } else if (struct.structType === 'notification' && (comState.data as any)[struct.parentId] !== undefined && struct.ownerId === platform.currentUser?._id) {
                    toResolve.push(struct); //auto resolve these notifications since we are actively viewing the data
                }
            }
        });
    }
    else if(typeof res.data === 'object') {
        if(res.data._id) {
            if((comState.data as any)[res.data._id] !== undefined) {
                comState.setState({[res.data._id]:res.data});
            } else if (res.data.structType === 'notification' && (comState.data as any)[res.data.parentId] !== undefined && res.data.ownerId === platform.currentUser?._id) {
                toResolve.push(res.data); //auto resolve these notifications since we are actively viewing the data
            }
        }
    }
    if(toResolve.length > 0) {
        platform.resolveNotifications(toResolve,true);
    }
}

platform.onResult = onResult;