import React, { Component } from 'react';
import { capFirst } from "frontend/utils/general";
import { UserObj_S } from 'frontend/store/store';
// import { ProfileInfo } from 'frontend/components/ProfileInfo.component';
import {
    BiometricsEmptyState, 
} from './ClientEventsTable.component';
import {DataTileDisplay} from 'frontend/components/UserPlatformTemplates/Data.components';
import { UserPlatform } from 'frontend/js/UserPlatform';
import { EventTileDisplay } from 'frontend/components/UserPlatformTemplates/Events.component';

interface P {
    platform: UserPlatform;
    selectedClient: UserObj_S;
    editClientInfo: () => any;
    loadingClients: boolean;
}

interface S {
    tab: 'profile' | 'biometrics' | 'events' | 'data';
    tabs: S['tab'][];
}

const memState: Partial<S> = { };
export default class DataIntake extends Component<P, S> {
    state: S = {
        tabs: [
            // 'profile', 
            'events', 
            'data',
            'biometrics', 
        ],
        tab: 'events',
        ...memState
    }

    selectTab = (tab: S['tab']) => this.setState({ tab });

    componentWillUnmount() {
        memState.tab = this.state.tab;
    }

    render() {
        const s = this.state;

        return <div className={`panel`}>
                {/* TITLE */}
                {/* <div className="panel-header">
                    <div className="panel-title h6 float-left">Data Intake</div>
                </div> */}

                {/* NAV */}
                <nav className="panel-nav">
                    <ul className="tab tab-block">
                        {s.tabs.map((tab) => (
                            <li className={`tab-item${tab === s.tab ? ' active' : ''}`}>
                                <a onClick={() => this.selectTab(tab)}>{capFirst(tab)}</a>
                            </li>
                        ))}
                    </ul>
                </nav>

                {/* BODY */}
                {/* {s.tab === 'profile' && <ProfileInfo
                    editInfo={p.editClientInfo}
                    selectedClient={sc}
                    showNotes={true}
                />} */}


                {s.tab === 'events' && <>
                    <EventTileDisplay
                        platform={this.props.platform}
                        parentUser={this.props.selectedClient}
                    />
                </>}

                {s.tab === 'biometrics' && BiometricsEmptyState({isLoading: false})}

                {s.tab === 'data' && <DataTileDisplay
                    platform={this.props.platform}
                    parentUser={this.props.selectedClient}
                />}
            </div>
    }
}
