import { Dict, objKeyVals } from "@giveback007/util-lib";
import { getDictFromUrlParams, getUserCodes, handleFitBitUrlParams } from "frontend/utils/general";
import { browserHist, browserHistPush, StateManagerType } from "../store";
import { providerApi } from "frontend/apis/provider.api";
import { userApi } from "frontend/apis/user.api";
import { Action } from "../actions";
import { addNotification } from "frontend/utils/store.utils";
import { platform } from "frontend/shared";

export function urlReducer(store: StateManagerType) {
    browserHist.listen(() => store.action({ type: Action.urlParamsChanged }));
    
    // URL_PARAM reducer
    let previousUrlParams: Dict<string | undefined> = {};
    let didChange = false;
    store.actionSub(Action.urlParamsChanged, () => {
        const params = getDictFromUrlParams();

        let i = 0;
        const kvs = objKeyVals(params);
        while (!didChange && i < kvs.length) {
            if (previousUrlParams[kvs[i].key] !== params[kvs[i].key]) didChange = true;
            i++;
        }

        if (!didChange) return;

        // Fitbit auth code params
        if (params.code && params.state && (params.state.search('is_fitbit=true') > -1))
            handleFitBitUrlParams(params.code, params.state);

        // adding user to a provider by id
        if (params.add_user_to_provider) {
            const { add_user_to_provider } = params;
            const sub = store.stateSub('currentUser', (s) => {
                if (!s.currentUser) return;
                sub.unsubscribe();

                providerApi.addClient({
                    clientId: add_user_to_provider,
                    providerId: s.currentUser._id
                }).then((result) => {
                    if (result.type === 'ERROR')
                        return addNotification({ text: "Couldn't add client!", type: 'error' });

                    userApi.byId(add_user_to_provider).then((x) => {
                        if (x.type === 'SUCCESS' && x.data) {
                            addNotification({ text: `Added ${x.data.email} as a client`, type: 'success' });
                            browserHistPush({ params: { add_user_to_provider: null }})
                        } else {
                            addNotification({ text: "Unexpected error, try refreshing the page!", type: 'error' });
                        }
                    });

                    let currentUser = getUserCodes(result.data, true)
                    store.setState({ currentUser});
                });
            });
        }

        // selected_client
        if (params.selected_client) {
            const { selected_client: newSelId } = params;
            const oldSelId = store.getState().selectedClient?._id;

            if (oldSelId !== newSelId) {
                const sub = store.stateSub('clients', ({ clients }) => {
                    if (!clients) return;
                    sub.unsubscribe();

                    const found = clients.find(({ _id }) => _id === newSelId);
                    if (found) {
                        sub.unsubscribe();

                        let state = store.getState()
                        if (state){
                            let auth = platform.hasLocalAuthorization(found._id, state.currentUser?._id);
                            if(auth) platform.getUserDataByAuthorizationFromServer(auth);
                        }
                        store.setState({ selectedClient: found });
                    } else {
                        // TODO: this should check if the user is in the available group, if available make sure selected client, if not in group only then throw error
                        addNotification({ text: `url: selected_client=${params.selected_client}`, type: 'warning' });
                        addNotification({ text: "Unable to view selected clients data, they are not in your list of clients!", type: 'error' });
                        console.error(`id: ${newSelId} is not in the list of clients`);
                    }
                }, true);
            }
        }

        previousUrlParams = params;
        didChange = false;
    });
}
