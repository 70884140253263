import React, { Component } from 'react'
import { randomId } from '@myalyce/common';
import { ContainerProps, ChatroomProps, CreatorProps, createUserSelect, getUserNameById, setupComponentRefresh, removeComponentRefresh, setupContainerRefresh, removeContainerRefresh } from "./Templates.shared";
import { CommentCreator, Comment } from './Comment.templates'
import { DataTile } from './Data.components'
import {AttachmentCreator} from './Attachments.components'
import { chatroomApi } from 'frontend/apis/chatroom.api';
import { commentApi } from 'frontend/apis/comment.api';
import { EventTile } from './Events.component';
import { linker } from 'frontend/store/store';
// import { BiRefresh, BiTrash } from 'react-icons/bi';
import css from './Chatroom.module.scss'



interface S {}


export class ChatroomCreator extends Component<CreatorProps, S> {
    state = {
        id:randomId('chatroomCreator'),
        attachments:[] as any,
        attaching:false,
        users:[] as any,
        parentUser:this.props.parentUser,
    }

    constructor(props:CreatorProps) {
        super(props);   
    }


    componentDidMount() {
        
    }

    componentWillUnmount() {

    }

    _submit = () => {
        let firstmessage = (document.getElementById(this.state.id+'_title') as HTMLInputElement)?.value;
        let users = this.state.users;
        let att = this.state.attachments;

         this.props.platform.addChatroom(
            this.props.parentUser,
            this.props.platform.currentUser?._id,
            firstmessage,
            att,
            users
        );

        
        this.props.updateParent();
    }

    _adduser = () => {
        let users = [...this.state.users];
        let newu = (document.getElementById(this.state.id+'_userselect') as HTMLInputElement).value;
        if(users.indexOf(newu) < 0) 
        {
            users.push(newu);
            this.setState({users:users});
        }
    }

    _attach = () => {
        this.setState({attaching:!this.state.attaching});
    }

    _updateAttachments = (attachment:any) => {
        //console.log(this.state.attachments, attachment);
        let updated = [...this.state.attachments,attachment];
        this.setState({attachments:updated});
    }

    render() {
        return (
            <div id={this.state.id} className={this.props.editing ? "modal active" : "modal"}>
            <a className="modal-overlay" aria-label="Close" onClick={() => {this.props.toggleEditor()}}></a>
            <div className="modal-container">
                <div className="modal-header">
                <a  className="btn btn-clear float-right" aria-label="Close" onClick={() => {this.props.toggleEditor()}}></a>
                <div className="modal-title h5">New Chatroom</div>
                </div>
                <div className="modal-body">
                <div className="form-group">
                    <label className="form-label">Title</label>
                    <input id={this.state.id+'_title'} className="form-input" type="text" placeholder="Title"/>
                </div>
                <div className="form-group">
                    <label className="form-label">Attachment(s)</label>
                    <button id={this.state.id+'_attach'} onClick={this._attach} className="btn btn-primary">Add Attachment</button>
                    { this.state.attaching && 
                        <AttachmentCreator
                            platform={this.props.platform}
                            parentUser={this.props.parentUser}
                            updateParent={this._updateAttachments}
                        />
                    } 
                    <div id={this.state.id+'_attachments'}>
                        {this.state.attachments.map((item:any) => 
                            <DataTile 
                                platform={this.props.platform}
                                parentUser={this.props.parentUser}
                                dataInstance={commentApi.search({'_id': item})}
                            />
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <label className="form-label">Add Users</label>
                    <div className="input-group">
                    {
                        createUserSelect(this.props.parentUser,this.state.id+'_userselect')
                    }
                    <button id={this.state.id+'_addUsers'} onClick={this._adduser} className="btn btn-primary input-group-btn">Add</button>
                    </div>
                    <div id={this.state.id+'_usersAdded'}>
                        {
                            this.state.users.map((item:any) => 
                            <div id={this.state.id+item}>{getUserNameById(this,item,this.state.id+item)}</div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <button id={this.state.id+'_submit'} className="btn btn-primary input-group-btn" onClick={this._submit}>Submit</button>
            </div>
            </div>
            </div>
        )
    }
}


export class Chatroom extends Component<ChatroomProps, S> {
        
    state = {
        id:randomId('chatroom'),
        replies:[] as any,
        viewing: this.props.chatroom?.webrtc ?? false,
        replying:false,
        deleted:false,
        parentUser:this.props.parentUser
    }

    editorRef = React.createRef<CommentCreator>()
    structRefs = [React.createRef<Comment>()]
    sub = 0


    constructor(props:ChatroomProps) {
        super(props);   
    }

    check = (init=false) => {
        let replies = this.props.platform.getLocalReplies(this.props.chatroom);
        
        // this.structRefs=[];
        // replies.forEach(() => {this.structRefs.push(React.createRef<Comment>())});
        this.setState({replies:replies});

        if(init) this.state.replies = replies;
        else
            this.setState({replies:replies});

    }

    componentDidMount() {
        this.check(true);
        
        if(this.props.chatroom?._id) {
            setupComponentRefresh(this,'chatroom');
        }
    }

    componentWillUnmount() {
        if(this.props.chatroom?._id) {
            removeComponentRefresh(this,'chatroom');
        }
    }

    
    _delete = () => {
        this.props.platform.deleteRoomOnServer(this.props.chatroom);
        this.setState({deleted:true});
    }


    _view = () => {
        if(this.state.viewing) this.setState({viewing:false});
        else this.setState({viewing:true});
    }

    _reply = () => {
        if(this.state.replying) this.setState({replying:false});
        else this.setState({replying:true});
    }

    _refresh = () => {
        this.check();
    }

    _updateFromChild = () => {

        // if (this.props.chatroom.webrtc){
        // } else {
            this._reply();
            this._refresh();
        // }
    }

    componentDidUpdate = () => {
                 
        let body = document.getElementById(this.state.id+'_data')
        if (body) body.scrollTop = body.scrollHeight;
        
    }

    render() {

        // TODO: MIGHT IMPLEMENT A MORE EFFICIENT WAY TO DO THIS
        this.state.replies = this.props.platform.getLocalReplies(this.props.chatroom);

        return (
            <div id={this.state.id}>
             { !this.state.deleted && 
             <div className={`card`}>
                <div className="card-header">

                    {!this.props.chatroom.webrtc && 
                    <div className="float-right">
                        <button id={this.state.id+'_view'} className="btn btn-primary mr-2 btn-sm" onClick={this._view}>View</button>
                        <button id={this.state.id+'_refresh'} className="btn btn-secondary mr-2 btn-sm" onClick={this._refresh}>Refresh</button>
                        <button id={this.state.id+'_button'} className="btn btn-secondary btn-sm" onClick={this._delete}>Delete</button>
                    </div>
                    }
                    
                    <div className="float-left">
                        <h6 className="card-title .h5 mb-0">{this.props.chatroom.message}</h6>
                        <small id={this.state.id+'_users'}  className="card-subtitle text-gray">
                            {this.props.chatroom.users &&
                                this.props.chatroom.users.map((u:any)=> {
                                    // {
                                    // if (u === this.state.chatroom.authorId) return <div><strong>{u}</strong></div>
                                    // else return <div>{u}</div>
                                    // }                                        
                                        return <div id={this.state.id+u}>{getUserNameById(this,u,this.state.id+u)}</div>
                                    }
                                )
                            }
                            </small>
                    </div>
                </div>
                {this.state.viewing && 
                    <div id={this.state.id+'_data'} className={"card-body " + css.max_window}>
                        { this.props.chatroom.attachments?.map((item:any) => {
                            let d = chatroomApi.search({'_id': item}) // expect local data

                            if(!d) {
                                // this.props.platform.getDataFromServer('chatroom',this.state.chatroom.ownerId,{'_id':item},undefined,undefined,(res:any) => { //does async work here? we'll find out!
                                chatroomApi.search({'_id': item, 'ownerId':this.props.chatroom.ownerId}).then((res:any) => {
                                    this.props.platform.baseServerCallback(res);
                                    if(res.data[0]) d = res.data[0];
                                    setTimeout(()=>{this.render()},10);

                                    if (d.structType === 'dataInstance')
                                        { return <DataTile 
                                                platform={this.props.platform}
                                                parentUser={this.props.parentUser}
                                                dataInstance={d}
                                                />
                                        }
                                    else if (d.structType === 'event') {
                                         return <EventTile 
                                            platform={this.props.platform}
                                            parentUser={this.props.parentUser}
                                            event={d}
                                            />
                                    }
                                    return;
                                })
                            }
                            else if (d.structType === 'dataInstance')
                                { return <DataTile 
                                        platform={this.props.platform}
                                        parentUser={this.props.parentUser}
                                        dataInstance={d}
                                        />
                                }
                            else if (d.structType === 'event') {
                                return <EventTile 
                                    platform={this.props.platform}
                                    parentUser={this.props.parentUser}
                                    event={d}
                                    />
                            }
                            return;
                        })
                        }

                        <div id={this.state.id+'_comments'}>
                        
                                {this.state.replies.map((item: any) => 
                                    <Comment 
                                        platform={this.props.platform}
                                        parentUser={this.props.parentUser}
                                        comment={item}
                                        room={this.props.chatroom}
                                        parent={this.props.chatroom}
                                        // ref={this.structRefs[i]}
                                        setting={'chatroom'}
                                    />
                                )}
                        </div>
                    </div>}
                <div className="card-footer pt-2">
                    {this.state.viewing && <CommentCreator 
                        platform={this.props.platform}
                        parentUser={this.props.parentUser}
                        room={this.props.chatroom}
                        parent={this.props.chatroom}
                        ref={this.editorRef}
                        updateParent={this._updateFromChild}
                        onsubmit={this.props.onsubmit}
                    />}
                </div>
                </div>
                }
            </div>
        )
    }
}



class ChatroomsContainer extends Component<ContainerProps, S> {
        
    state = {
        id:randomId('chatrooms'),
        editing:false,
    };
    
    editorRef = React.createRef<ChatroomCreator>()
    structRefs = [React.createRef<Chatroom>()]

    sub:number|undefined = 0

    constructor(props:ContainerProps) {
        super(props);   
    }

    componentDidMount() {
        setupContainerRefresh(this,'chatroom',this.sub);
    }

    componentWillUnmount() {
        removeContainerRefresh(this.sub);
    }

    check = async() => {
        // let structs = [];
        // if(this.props.structs.length === 0) {
        //     if (!this.props.parentUser) return;
        //     structs = await chatroomApi.search({'ownerId': this.props.parentUser._id})
        //     //console.log(structs)
        // }

        // console.log('SETTING STRUCTS', structs)
        // if(structs.length > 0) {
        //     this.structRefs = [];
        //     structs.forEach(()=>{
        //         this.structRefs.push(React.createRef<Chatroom>());
        //     });
        //     this.setState({structs:structs});
        // }
    }

    _click = () => {
        if(this.state.editing) this.setState({editing:false});
        else this.setState({editing:true});
    }

    _refresh = () => {
        this.check();
    }

    _updateFromChild = () => {

        this._click();
        this._refresh();
    }

    render() {

        let chatrooms = this.props.structs
        let chatroomsToView = (chatrooms) ? chatrooms.filter(o => o.ownerId === this.props.parentUser?._id) : []
        return <div id={this.state.id} className="panel">
                <div className="panel-header">
                    <div className="panel-title h6 float-left">Chatrooms</div>
                    <div className="float-right">
                        <button className="btn btn-primary mr-2 btn-sm" onClick={this._click}>New</button>
                        {/* { this.props.structs.length === 0 && 
                            <button className="btn btn-secondary btn-sm"  onClick={this._refresh}>Refresh</button>
                        } */}
                    </div>
                </div>
            <div className="panel-body">
                <div>
                <ChatroomCreator 
                    platform = {this.props.platform}
                    parentUser = {this.props.parentUser}
                    ref = {this.editorRef}
                    updateParent={this._updateFromChild}
                    editing = {this.state.editing}
                    toggleEditor = {this._click}
                />
                </div>
                <div>
                { chatroomsToView.length > 0 &&
                    chatroomsToView.map((item: any) => {

                        return <Chatroom 
                            platform = {this.props.platform}
                            parentUser = {this.props.parentUser}
                            chatroom = {item}
                        />
                    })
                }
                </div>
                </div>
                <div className="panel-footer"/>
            </div>
    }
}

export default linker((s) => ({ structs: s.chatroom}), ChatroomsContainer);

