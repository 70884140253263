import { isType, wait } from "@giveback007/util-lib";
import { Action } from "frontend/store/actions";
import { State, store, UserObj_S } from "frontend/store/store";
import { Notify } from "frontend/types/notify.types";

export function getUser(userId: string) {
    const user = store.getState().users[userId];

    return user === 'loading' || !user ? {
        isLoading: true as const,
        data: new Promise<UserObj_S | null>((resolve) => {
            const sub = store.stateSub('users', ({ users }) => {
                const user = users[userId];
                if (user === undefined)
                    return store.action({ type: Action.userLoad, data: { userId } });

                if (user === 'loading') return;
                
                sub.unsubscribe();
                return resolve(user);
            }, true);
        }),
    } : {
        isLoading: false as const,
        data: user,
    };
}

export const toggleLoader = (loadKey: keyof State['loaders'], bool?: boolean) => {
    let { loaders } = store.getState();
    const isLoading = isType(bool, 'boolean') ? bool : !loaders[loadKey];

    loaders = { ...loaders, [loadKey]: isLoading };
    store.setState({ loaders });
}


export const addNotification = (notify: Pick<Notify, 'text' | 'type'> & { id?: string | number }, msTimeout?: number) => {
    if (!notify.id) notify.id = Math.random();

    const displayNotify = [...store.getState().displayNotify, notify as Notify];
    store.setState({ displayNotify });

    if (msTimeout) wait(msTimeout).then(() => removeNotification(notify.id as number))
}

export const removeNotification = (notifyId: string | number) => {
    const displayNotify = store.getState().displayNotify.filter(nt => nt.id !== notifyId);
    store.setState({ displayNotify });
}
