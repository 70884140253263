import React from 'react'
import { Router, Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import routes from './routes';
import { objKeyVals, objVals } from '@giveback007/util-lib';
import Drawer, { NavBtnType } from './components/NavDrawer';
import TopBar from './components/TopBar.component';
import { CgLogOut } from 'react-icons/cg'
import { browserHist, linker, store } from './store/store';
import { Login } from './views/Login.view';
import DisplayNotify from './components/DisplayNotify.component';
import { TopModal } from './components/TopModal.component';
import SelectedClient from './components/SelectedClient.component';

// later will be role/permission based
const PrivateRoute = linker(
    ({ isLoggedIn }) => ({ isLoggedIn }),
    ({ isLoggedIn, component: Component, ...rest }: RouteProps & { isLoggedIn: boolean }) => {
        if (!isLoggedIn)
            store.setState({ redirectURL: location.pathname + location.search });

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /login page
        return isLoggedIn ?
            <Route component={Component} {...rest} />
            :
            <Route render={() => <Redirect to="/login" />} />
    }
);


const PublicRoute = linker(
    ({ isLoggedIn }) => ({ isLoggedIn }),
    ({ isLoggedIn, component: Component, restricted, ...rest }: RouteProps & { isLoggedIn: boolean, restricted: boolean }) => {

        // restricted = false   -> meaning public route
        // restricted = true    -> meaning restricted route
        return isLoggedIn && restricted ?
            <Redirect to={"/"} />
            :
            <Route component={Component} {...rest} />
    }
);

// temp, this will have a function that handles nav based on roles/permissions
const routesType = objKeyVals(routes).map(({ val }) => ({ ...val, type: 'route' as const }))
let navBtns: NavBtnType[] = [
    ...routesType,
    {
        type: 'action',
        action: { type: 'LOGOUT' },
        name: 'Log Out',
        navIcon: CgLogOut,
        loader: 'logout',
    }
];

navBtns = navBtns.filter(o => !['Profile', 'Log Out'].includes(o.name)) // filter out profile


type AppProps = { 
    isLoggedIn: boolean,
    appIsInitialized: boolean,
}
const App = (p: AppProps) => (p.appIsInitialized ? <Router history={browserHist}>
        <Drawer navBtns={navBtns}/>

        <DisplayNotify />
        <TopModal />

        <div id="view">
            <TopBar />
            <div id='viewcontent'>
                <Switch>
                    {objVals(routes).map((r) =>
                        <PrivateRoute path={r.path} component={r.component}/>)}

                    <PublicRoute
                        path="/login"
                        component={Login}
                        restricted={p.isLoggedIn} // if loggedIn redirects to "/"
                    />

                    {/* "/" needs to be last in the list */}
                    {/* Otherwise all other routes are ignored */}
                    <Redirect from="/" to="/dashboard" />
                </Switch>
            </div>
            {p.isLoggedIn && <SelectedClient />}
        </div>
    </Router>
    : 
    <div id="loader" className="loading loading-lg"></div>
);

export default linker(({ isLoggedIn, appIsInitialized }) => ({ isLoggedIn, appIsInitialized }), App);
