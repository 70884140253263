import React, { Component } from 'react'
import { NotificationsDropdown } from './UserPlatformTemplates/Notification.component';
import { ProfileBadge } from 'frontend/components/utils/component.utils';
import { store, UserObj_S, linker, browserHistPush } from 'frontend/store/store';

import css from './TopBar.module.scss';
import { platform } from 'frontend/shared';
import { BiSearch } from 'react-icons/bi';

interface S { }

type TopBarProps = { 
    currentUser: UserObj_S | null;
}

class TopBar extends Component<TopBarProps, S> {

    render() {
        const user = this.props.currentUser;
        return <>
            {user && <><div id="top-bar" className={css.top_bar}>
                <div
                    className={`p-2 ${css.drawer_head}`}
                    // onClick={() => store.toggle('drawerOpen')}
                >
                    {/* <div className={css.head_icon}>
                        <GiHamburgerMenu />
                    </div> */}
                    {/* <section className="navbar-section"> */}
                    {/* <div className="input-group input-inline"> */}
                        <div className="has-icon-left">
                            <BiSearch className="form-icon"/>
                            <input className="form-input input-sm" type="text" placeholder="Search..."/>
                        </div>
                        <button className="btn btn-primary input-group-btn btn-sm" disabled={true}>Search</button>
                    {/* </div> */}
                {/* </section> */}
                </div>
                <div>
                    <NotificationsDropdown
                        platform={platform}
                        parentUser={user}
                    />
                    <div className="dropdown">
                        <a className="btn btn-link link-btn-hover dropdown-toggle" tabIndex={0}>
                            <ProfileBadge user={user}/>
                        </a>
                        <ul className="menu">
                            <li className="menu-item"><a onClick={() => {
                                browserHistPush({ route: '/profile' });
                            }}>My Account</a></li>
                            <li className="menu-item"><a onClick={() => {
                                store.action('LOGOUT');
                                // this.setState({show: false})
                            }}>Sign Out</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={css.top_bar_spacer} /></>}
     </>
    }
}

export default linker((s) => ({ currentUser: s.currentUser }), TopBar);
