import DataStreamTrackGenerator from "../DataStreamTrackGenerator.js";
import DataStreamTrackProcessor from "../DataStreamTrackProcessor.js";
import DataStreamTrack from "../DataStreamTrack.js";

export default class Device {

    set ondata(func){this.datacallbacks.push(func)}

    constructor(constraints){

        this.datacallbacks = []
        this.constraints = constraints

        this.device = (constraints.device) ? new constraints.device(constraints) : this

        this.dataStream = constraints.dataStream
        
        // Callbacks
        // console.log(constraints)
        this.onconnect = constraints.onconnect ?? this.onconnect;
        this.ondisconnect = constraints.ondisconnect ?? this.ondisconnect;
        let ondata = constraints.ondata ?? this.ondata;
        this.datacallbacks.push(ondata)

        this.onerror = constraints.onerror ?? this.onerror;

        if (constraints.encode) this.encode = constraints.encode 
        else this.encoder = new TextEncoder("utf-8");

        if (constraints.decode) this.decode = constraints.decode 
        else this.decoder = new TextDecoder("utf-8");
    }
   
    // Core Methods 
    connect = async () => {
        if (this.device !== this) await this.device.connect()
        this.onconnect(this)
    }        
    disconnect = async () => {
        if (this.device !== this) await this.device.disconnect()
        this.ondisconnect(this)
    }
    send = async () => {this.onsend()}

    // Auxilliary Methods
    encode = (msg, from) => this.encoder.encode(msg)
    decode = (msg, from) => this.decoder.decode(msg)

    // Events
    onconnect = async () => console.log(`${this.constructor.name} connected!`)

    ondisconnect = async () => console.log(`${this.constructor.name} disconnected!`)

    // ondata = async (data, from) => console.log(`${this.constructor.name}: ${data}`)

    onsend = async () => {}
    onerror = async (err) => console.log(`${this.constructor.name} Error: ${err}`)

    ondata = (data) => {this._ondata(data)}

    // --------------- Internal Methods ---------------
    _ondata(data){

        this.datacallbacks.forEach(f => {

            // Run Data through Decoder Function
            let arr = f(data) // returns array
            
            // Add DataStreamTrack for each Data Channel
            let tracks = this.dataStream.getDataTracks()
            arr.forEach((val,i) => {
                let track = tracks[i]
                if (!track) track = this.dataStream.addTrack(new DataStreamTrack(this.device))
                track.addData(val)
            })
        })
    }

}