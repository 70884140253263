import StreamContext from './StreamContext.js'
import Streamer from './pipes/Streamer.js'

import DataDevices from './DataDevices.js'
import DataStream from './DataStream.js'
import DataStreamTrack from './DataStreamTrack.js'
import DataStreamTrackGenerator from './DataStreamTrackGenerator.js'
import DataStreamTrackProcessor from './DataStreamTrackProcessor.js'
import DataPipeline from './DataPipeline.js'

// ---------------------------------- API initialization ---------------------------------- 
window.DataStreamTrackGenerator = DataStreamTrackGenerator // proposed external usage
window.DataStreamTrackProcessor = DataStreamTrackProcessor // proposed external usage
navigator.dataDevices = new DataDevices() // proposed external usage


export {

    // Context Manager
    StreamContext,
    Streamer,
    
    // Devices
    DataDevices,

    // Stream
    DataStream,

    // Track
    DataStreamTrack,

    // Insertable Stream Format
    DataStreamTrackGenerator,
    DataStreamTrackProcessor,
    DataPipeline
}
