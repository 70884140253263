import React, { ReactNode } from 'react';
import { browserHistPush, linker, State, store } from "frontend/store/store";

export type ModalState = {
    title: string;
    setUrlId?: string;
    body: ReactNode;
    btns?: { title: string; onClick: () => any; color?: 'success' | 'error' | 'primary ' | 'empty' }[];
    loading?: boolean;
    modalId?: string;
};

export const closeTopModal = () => {
    browserHistPush({ params: { modal: null } });
    store.setState({ topModal: null })
};

/** This isn't functioning yet */
export const TopModal = linker(({ topModal }) => ({ modal: topModal }), (p: { modal: State['topModal'] }) => {
    if (!p.modal) return null;
    const { title, body, btns, loading } = p.modal as ModalState;

    const ptEventCss: React.CSSProperties = loading ? { pointerEvents: 'none' } : {};
    return <div className="modal active" id="top-modal">
        <a className="modal-overlay" aria-label="Close" onClick={closeTopModal} style={ptEventCss} />
        <div className="modal-container">
            <div className="modal-header">
                <a className="btn btn-clear float-right" aria-label="Close" onClick={closeTopModal} style={ptEventCss} />
                <div className="modal-title h5">{title}</div>
            </div>

            <div className="modal-body">
                <div className="content">{body}</div>
            </div>

            {btns && <div className="modal-footer">
                {btns.map((btn) => <button
                    disabled={loading}
                    className={`btn btn-${btn.color || 'primary '}${loading ? ' loading' : ''}`}
                    onClick={btn.onClick}
                >{btn.title}</button>)}
                <button
                    className="btn btn-link"
                    aria-label="Cancel"
                    disabled={loading}
                    onClick={closeTopModal}
                >Cancel</button>
            </div>}
        </div>
    </div>;
});

// z-index + nav-drawer-offset