import DataStreamTrack from "./DataStreamTrack"

// Data Channels Behave Just Like Tracks
export default class DataChannel extends DataStreamTrack{

    constructor(parent){
        super()

        this.id = parent.id
        this.label = parent.label
        this.parent = parent 
        
        this.sendMessage = () => {}
    }


    send = (data) => this.parent.send(data)
}