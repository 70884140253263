import Router from "./Router.js";
import DataChannel from "../DataChannel.js";


/*

Known Bugs
- Adding a DataChannel after an RTCPeerConnection has already been opened will not work reliably (see addDataTracks)

*/

export default class Streamer extends Router{

    get [Symbol.toStringTag]() { return 'Streamer' }

    constructor(settings={}){
        super('stream', settings)

        this.add(settings.source)

        this.config = {
            iceServers: [
              {
                urls: ["stun:stun.l.google.com:19302"]
              }
            ]
          };

        this.channels = null
        this.peers = new Map()
        this.dataChannelQueueLength = 0
        this.dataChannels = new Map()
        this.rooms = new Map()

        this.sources = new Map()

        this.toResolve = {} // for tracking DataChannel callbacks
        this.hasResolved = {} // for tracking DataChannel callbacks

        // ---------------------------- Event Listeners ----------------------------

        this.onpeerdisconnect = () => {}
        this.addEventListener('peerdisconnect', (ev) => { this.peers.delete(ev.detail.id)})
        this.addEventListener('peerdisconnect', (ev) => { this.onpeerdisconnect(ev)})
        this.onpeerconnect = () => {}
        this.addEventListener('peerconnect', (ev) => { this.peers.set(ev.detail.id, ev.detail.peer) })
        this.addEventListener('peerconnect', (ev) => { this.onpeerconnect(ev)})
        this.ondatachannel = (ev) => {}
        this.addEventListener('datachannel', (ev) => { this.ondatachannel(ev) })
        this.onroom = (ev) => {}
        this.addEventListener('room', (ev) => { this.onroom(ev)})
        this.ontrack = (ev) => {}
        this.addEventListener('track', (ev) => { this.ontrack(ev) })
        this.ontrackremoved = (ev) => {}
        this.addEventListener('trackremoved', (ev) => { this.ontrackremoved(ev)})
        this.onroomclosed = (ev) => {}
        this.addEventListener('roomclosed', (ev) => { this.onroomclosed(ev)})
        
        // ---------------------------- Socket Messages ----------------------------
        let prevSocketCallback = this.socket.onmessage
        this.socket.onmessage = async (res) => {

            prevSocketCallback(res)

            // ----------------- Generic Message Handlers -----------------
            if (res.cmd === 'rooms') { 
                res.data.forEach(room => {this.rooms.set(room.uuid, room)})
                this.dispatchEvent(new CustomEvent('room', {detail: {rooms: res.data}}))
            } else if (res.cmd === 'roomadded'){
                this.rooms.set(res.data.uuid, res.data)
                this.dispatchEvent(new CustomEvent('room', {detail: {room: res.data, rooms: Array.from(this.rooms, ([name,value]) => value)}}))
            }

            else if (res.cmd === 'roomclosed') this.dispatchEvent(new CustomEvent('roomclosed'))
            
            
            // automatically passed to subscriptions

            // ----------------- Local Initiation Handlers -----------------
            else if (res.cmd === 'connect') {
                this.createPeerConnection(res.data) // connect to peer
                for (let arr of this.sources) {
                    let dataTracks = arr[1].getDataTracks()
                    await this.addDataTracks(res.data, dataTracks) // add data tracks from all sources
                }
            }
            else if (res.cmd === 'answer') {
                let peer = this.peers.get(res.data.id)
                peer.setRemoteDescription(res.data.msg);
            } else if (res.cmd === 'candidate'){
                let peer = this.peers.get(res.data.id)
                let candidate = new RTCIceCandidate(res.data.msg)
                peer.addIceCandidate(candidate).catch(e => console.error(e)); // thrown multiple times since initial candidates aren't usually appropriate
            } else if (res.cmd === 'disconnectPeer') {
                this.closeConnection(res.data, this.peers.get(res.data.id))
            }

            // ----------------- Remote Initiation Handlers -----------------
            else if (res.cmd === 'offer') this.onoffer(res.data, res.data.msg, res.id)
        }
    }

    // Add DataStreamTracks from DataStream (in series)
    addDataTracks = async (id, tracks) => {
        for (let track of tracks) {
            await this.openDataChannel({name: `DataStreamTrack${this.dataChannelQueueLength}`, peer:id, reciprocated: false}).then(o => track.subscribe(o.sendMessage)) // stream over data channel
        }
    }


    onoffer = async (peerInfo, sdp, peerId) => {
        let myPeerConnection = await this.createPeerConnection(peerInfo, peerId)
        const description = new RTCSessionDescription(sdp);
        myPeerConnection.setRemoteDescription(description).then(() => myPeerConnection.createAnswer()).then(sdp => myPeerConnection.setLocalDescription(sdp))
        .then(() => {
            this.send({cmd: 'answer', data: {id:peerInfo.id, msg: myPeerConnection.localDescription}})
        });
    }

    handleNegotiationNeededEvent = (localConnection, id) => {
        localConnection.createOffer()
        .then(sdp => localConnection.setLocalDescription(sdp))
        .then(() => {
            this.send({cmd: 'offer', data: {id, msg: localConnection.localDescription}})
        });
    }

    handleICECandidateEvent = (event, id) => {
        if (event.candidate) this.send({cmd: 'candidate', data: {id, msg: event.candidate}})
    }

    handleTrackEvent = (event, id) => {
        if (event.track){
            let track = event.track
            this.dispatchEvent(new CustomEvent('track', {detail: {track, id}}))
            return true
        }
    }


    // NOTE: This data channel will always be the one that can send/receive information
    handleDataChannelEvent = async (ev) => {

        // console.log('NEW DATA CHANNEL TO HANDLE')

        // Filter for Expected Channels (or allow all)
        if (!this.channels || this.channels.includes(ev.channel.label)){

            // Receive Data from Remote
            let o = await this.openDataChannel({channel: ev.channel, callback: (msg, channel) => channel.addData(msg)})
            const toResolve = this.toResolve[o.label]

            if (toResolve) {
                delete this.toResolve[o.label]
                toResolve(o)
            }

            this.hasResolved[o.label] = o // keep track of channels already resolved
            this.dispatchEvent(new CustomEvent('datachannel', {detail: o}))
        }

    }

    handleRemoveTrackEvent = (ev,id) => {
        if (ev.track){
            let track = ev.track
            this.dispatchEvent(new CustomEvent('trackremoved', {detail: {track, id}}))
            return true
        }
    }


    handleICEConnectionStateChangeEvent = (ev, info) => {
        const peer = this.peers.get(info.id) 
        switch(peer?.iceConnectionState) {
            case "closed":
            case "failed":
            this.closeConnection(info, peer);
              break;
          }
    }

    handleICEGatheringStateChangeEvent = (ev) => {}

    handleSignalingStateChangeEvent = (ev, info) => {
        const peer = this.peers.get(info.id) 
        switch(peer?.signalingState) {
            case "closed":
            this.closeConnection(info, peer);
            break;
        }
    }

    closeConnection = (info, peer) => {
        this.dispatchEvent(new CustomEvent('peerdisconnect', {detail: Object.assign(info, {peer})}))
    }

    createPeerConnection = async (peerInfo, peerId) => {

        const localConnection = new RTCPeerConnection(this.config);          
        localConnection.onicecandidate = (e) => this.handleICECandidateEvent(e,peerInfo.id) // send candidates to remote
        localConnection.onnegotiationneeded = (e) => this.handleNegotiationNeededEvent(localConnection,peerInfo.id) // offer to remote
        localConnection.ondatachannel = (e) => this.handleDataChannelEvent(e,peerInfo.id)

        peerInfo.peer = localConnection
        console.log('Creating a peer connection', peerInfo, peerId)
        if (!peerId) this.dispatchEvent(new CustomEvent('peerconnect', {detail: peerInfo}))
        else {

            // Only respond to tracks from remote peers
            localConnection.ontrack = (e) => this.handleTrackEvent(e, peerId); 
            localConnection.onremovetrack = (e) => this.handleRemoveTrackEvent(e, peerId);
            localConnection.oniceconnectionstatechange = (e) => this.handleICEConnectionStateChangeEvent(e,peerInfo);
            localConnection.onicegatheringstatechange = (e) => this.handleICEGatheringStateChangeEvent(e,peerInfo);
            localConnection.onsignalingstatechange = (e) => this. handleSignalingStateChangeEvent(e,peerInfo);
            
        }

        // Add Local MediaStreamTracks to Peer Connection
        this.sources.forEach(s => {
            s.getTracks().forEach( async track => {
                if (track instanceof MediaStreamTrack) localConnection.addTrack(track, s) // ensure connection has track
            });
        })

        return localConnection
    }

    add = async (source) => {
        if (source){
            this.sources.set(source.id, source)
            source.addEventListener('track', (ev) => {
                let kind = ev.detail.kind
                if (!kind || (kind !== 'video' && kind !== 'audio')){
                    for (let arr of this.peers) {
                        this.addDataTracks(arr[0], [ev.detail])
                    }
                }
            })
        }
    }

    remove = (id) => {
        let source = this.sources.get(id)
        this.sources.delete(id)
        source.removeEventListener('track', source)
    }

    getRooms = async (auth) => {
        let res = await this.send({cmd: 'rooms', data:auth})
        return res.data
    }
    
    joinRoom = async (room, info, auth) => {
        return await this.send({cmd: "connect", data:{auth, info, room}});
    }

    createRoom = async (data) => this.send({cmd: 'createroom', data})

    leaveRoom = async (data) => {
        this.peers.forEach((p,key) => this.peers.delete(key))
        return this.send({cmd: 'disconnect', data}) 
    }

    send = async (data) => await this.socket.send(data, 'webrtc')

    openDataChannel = async ({peer, channel, name, callback, reciprocated} = {callback: () => {}}) => {

        let local = false
        this.dataChannelQueueLength++ // increment name

        if (!(channel instanceof RTCDataChannel)) {
            local = true
            let peerConnection = this.peers.get(peer)
            channel = peerConnection.createDataChannel(name);
        }

        let o = await this.useDataChannel(channel, callback, local, reciprocated)

        return o
    }

    closeDataChannel = async (id) => {
        let dC = this.dataChannels.get(id)
        if (dC) dC.close()
        this.dataChannels.delete(id)
    }

    useDataChannel = (dataChannel, onMessage, local, reciprocated=true) => {

        return new Promise((resolve) => {

            // Assign Event Listeners on Open
            dataChannel.addEventListener("open", () => {

                // Track DataChannel Instances
                const dC = new DataChannel(dataChannel)
                if (local) this.dataChannels.set(dC.id, dC) // only save local

                let toResolve = (channel) => {
                    
                    // Set OnMessage Callback
                    channel.parent.addEventListener("message", (event) => {
                        if (onMessage) onMessage(JSON.parse(event.data), channel); // parse messages from peer
                    })
                    
                    // Resolve to User
                    channel.sendMessage = (message) => {this.sendMessage(message, channel.id, reciprocated)}
                    
                    resolve(channel)
                }

                // If you know this won't be reciprocated, then resolve immediately
                if (!local || !reciprocated) toResolve(dC)
                
                // Otherwise mark to resolve OR resolve if this channel already has been
                else {
                    let existingResolve = this.hasResolved[dC.label]
                    if (existingResolve) {
                        toResolve(existingResolve)
                        delete this.hasResolved[dC.label]
                    } else this.toResolve[dC.label] = toResolve
                }
            });

            dataChannel.addEventListener("close", () =>{console.error('Data channel closed', dataChannel)});
        });
    };


    sendMessage = (message, id, reciprocated) => {
        let data = JSON.stringify(message)

        // Ensure Message Sends to Both Channel Instances
        let check = () => {
            let dC =  this.dataChannels.get(id)
            if (dC) {
                if (dC.parent.readyState === 'open') dC.send(data); // send on open instead
                else dC.parent.addEventListener('open', () => {dC.send(data);}) // send on open instead
            } else if (reciprocated) setTimeout(check, 500)
        }
        check()
    }
}