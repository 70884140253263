import { platform } from 'frontend/shared';
import React, { Component } from 'react'
import AuthorizationsContainer from 'frontend/components/UserPlatformTemplates/Authorization.components';
import { linker, UserObj_S } from 'frontend/store/store';



interface Props {
    currentUser?: UserObj_S | null;
}
interface State {
    
}

class Authorizations extends Component<Props, State> {
    state = {};

    componentDidMount() {
    }

    render() {
        return (
            <div className={`p-2`}> 
                <AuthorizationsContainer 
                    platform={platform}
                    parentUser={this.props.currentUser} // only user with account can assign authorizations
                />
            </div>
        )
    }
}
export default linker((s) => ({ currentUser: s.currentUser}), Authorizations);
