import { arrToDict, hasKey, objVals, sec } from "@giveback007/util-lib";
import { clientEventApi } from "frontend/apis/client-event.api";
import { fitbitTokenApi } from "frontend/apis/fitbit-auth.api";
import { userApi } from "frontend/apis/user.api";
import { getUserCodes } from "frontend/utils/general";
import { addNotification, removeNotification, toggleLoader } from "frontend/utils/store.utils";
import { browserHistPush, StateManagerType } from "../store";

export function providerReducer(store: StateManagerType) {
    store.actionSub('CLIENTS_LOAD', () => {
        const { currentUser } = store.getState();
        if (!currentUser)
            return addNotification({ text: "Can't load clients the user is not logged in or loaded", type: 'error' });

        const clientIds = currentUser?.clients || [];
        if (!clientIds.length)
            return; // TODO handle this correctly: addNotification({ text: "No clients to load, please make sure you have client added to your list", type: 'error' });
        
        initClientData(clientIds, store);
    });

    store.actionSub('LOAD_CLIENT_EVENTS', async ({ data }) => {
        const { clientId: ownerId } = data;
        const { clientEvents } = store.getState();
        if (clientEvents[ownerId] === 'loading') return;
        
        store.setState({ clientEvents: { ...clientEvents, [ownerId]: 'loading' } });
        const eventsRes = await clientEventApi.search({ ownerId });

        if (eventsRes.type === 'ERROR') {
            addNotification({ text: "Unable to view clients events, please refresh and try again.", type: 'error' });
            store.setState({ clientEvents: { ...clientEvents, [ownerId]: [] } });
        } else {
            store.setState({ clientEvents: { ...clientEvents, [ownerId]: eventsRes.data } });
        }
    });

    store.stateSub('clients', ({ clients }) => {
        const sel = store.getState().selectedClient;
        if (!sel || !clients?.length) return;

        const newSel = clients.find(({ _id }) => _id === sel._id) || null;

        if (!newSel) {
            store.setState({ selectedClient: null });
            addNotification({ text: "Unable to view selected clients data, they are not in your list of clients.", type: 'error' });
            console.error(`id: ${sel._id} is not in the list of clients`);
        } else {
            store.setState({ selectedClient: newSel });
        }
    }, true);

    store.stateSub('selectedClient', ({ selectedClient }) => {
        // will not update selected_client in url if client is set to null, this needs to be done explicitly if need to deselect in code
        if (selectedClient?._id) browserHistPush({ params: { selected_client: selectedClient._id } });;
    }, false);
}

// TODO: fix this, this shouldn't be like this
async function initClientData(clientIds: string[], store: StateManagerType) {
    const notifyId = 'loading_client_data';
    toggleLoader('clients', true);
    addNotification({ text: 'Loading client data...', type: 'primary', id: notifyId });

    const userRes = await userApi.getIds(clientIds);

    if (userRes.type === 'ERROR')
        return addNotification({ text: 'Failed to load client data!', type: 'error' });

    const clients = userRes.data.found.map(u => getUserCodes(u, true));
    const clientDict = arrToDict(clients, '_id');

    // TODO: in the future this will be bypassed by automagick token refreshing
    // ! this way works for now, but has potential issues when multiple people are refreshing same token
    const statusRes = await fitbitTokenApi.checkAuths(clients.map(x => x._id));

    if (hasKey(statusRes, 'errors')) {
        toggleLoader('clients', false);
        return addNotification({ text: 'Something went wrong, try refreshing the page', type: 'error' });
    }

    const promises = statusRes.map(async (x) => {
        const user = clientDict[x.userId];
        if (x.fitbitIsActive && !x.tokenIsActive) {
            const result = await fitbitTokenApi.refresh(x.userId);
            if (hasKey(result, 'errors')) {
                clientDict[x.userId].fitbit = null;
                return addNotification({ text: `Failed to get fitbit data for: "${user.email}"`, type: 'error' }, sec(10));
            }

            clientDict[x.userId] = getUserCodes(result, true);
        }
    });
    
    await Promise.all(promises);
    toggleLoader('clients', false);
    removeNotification(notifyId);
    store.setState({ clients: objVals(clientDict) });
}
