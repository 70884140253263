import { arrToDict } from "@giveback007/util-lib";

export type EventTag = { emoji: string, name: string; color: string }

export function getEventTag(name: string, severity = 0) {
    return { ...eventTagDict[name], color: SeverityColor[severity] }
}

export const eventTags = [
    { emoji: '💊', name: 'Medication' },
    { emoji: '😱', name: 'Emotional Event' },
    { emoji: '🚑', name: 'Hospitalized' },
    { emoji: '🙁', name: 'Mood Swings' },
    { emoji: '😵', name: 'Craving' },
    { emoji: '💉', name: 'Relapse' },
    { emoji: '🤸‍♀️', name: 'Exercise' },
    { emoji: '😴', name: 'Sleep' },
    { emoji: '🍔', name: 'Meal' },
    { emoji: '🍴', name: 'Snack' },
    { emoji: '💫', name: 'Routine' },
    { emoji: '❗', name: 'Other' },
];

const eventTagDict = arrToDict(eventTags, 'name');

enum SeverityColor {
    /** Teal */
    'hsl(75, 100%, 45%)' = 0,
    /** Aqua */
    'hsl(68, 100%, 45%)' = 1,
    /** Yellow/green */
    'hsl(62, 100%, 45%)' = 2,

    'hsl(56, 100%, 45%)' = 3,

    'hsl(50, 100%, 45%)' = 4,

    'hsl(44, 100%, 45%)' = 5,

    'hsl(36, 100%, 45%)' = 6,

    'hsl(32, 100%, 45%)' = 7,

    'hsl(27, 100%, 45%)' = 8,

    'hsl(18, 100%, 45%)' = 9,

    /** Red */
    'hsl(10, 100%, 45%)' = 10,
}

export const SeverityColors = [
    /** Teal */
    'hsl(75, 100%, 45%)',
    /** Aqua */
    'hsl(68, 100%, 45%)',
    /** Yellow/green */
    'hsl(62, 100%, 45%)',

    'hsl(56, 100%, 45%)',

    'hsl(50, 100%, 45%)',

    'hsl(44, 100%, 45%)',

    'hsl(36, 100%, 45%)',

    'hsl(32, 100%, 45%)',

    'hsl(27, 100%, 45%)',

    'hsl(18, 100%, 45%)',
    /** Red */
    'hsl(10, 100%, 45%)'
]