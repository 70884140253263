import { platform } from 'frontend/shared';
import React, { Component } from 'react'
import GroupsContainer from 'frontend/components/UserPlatformTemplates/Groups.components';
import { linker, UserObj_S } from 'frontend/store/store';



interface Props {
    currentUser?: UserObj_S | null;
}

interface State {
    
}

class Groups extends Component<Props, State> {
    state = {};

    componentDidMount() {}

    render() {
        return (
            <div className={`p-2`}> 
                <GroupsContainer 
                    platform={platform}
                    parentUser={this.props.currentUser} // only user with account can assign group roles
                />
            </div>
        )
    }
}

export default linker((s) => ({ currentUser: s.currentUser}), Groups);
