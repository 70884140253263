


import React, { Component } from 'react'
import { randomId } from '@myalyce/common';
import { platform } from 'frontend/shared'
import { ContainerProps, CreatorProps, DataTileProps, getUserNameById, removeComponentRefresh, removeContainerRefresh, setupComponentRefresh, setupContainerRefresh } from "./Templates.shared";

import { FitBitView } from './FitBitDisplays.component';
import { UserFitbit } from '@myalyce/common/models/user.model';
import { linker } from 'frontend/store/store';
import { dataInstanceApi } from 'frontend/apis/dataInstance.api';
import { AiOutlineDatabase } from 'react-icons/ai';
interface S { }

export class DataCreator extends Component<CreatorProps, S> {

    state = {
        id: randomId('dataTileCreator'),
        parent: this.props.platform.Struct(),
        dataType: '',
        attaching: false,
        attachments: [] as any,
        parentUser: this.props.parentUser
    }

    constructor(props: CreatorProps) {
        super(props);
    }


    componentDidMount() {

    }

    componentWillUnmount() {

    }

    _submit = () => {
        let title = (document.getElementById(this.state.id + '_title') as HTMLInputElement)?.value;
        let typ = (document.getElementById(this.state.id + '_type') as HTMLInputElement)?.value;
        let expir = (document.getElementById(this.state.id + '_expires') as HTMLInputElement)?.value;
        let att = this.state.attachments;
        let newData = this.props.platform.addData(
            this.props.parentUser,
            this.props.platform.currentUser._id,
            title,
            typ,
            att,
            expir
        );

        this.props.updateParent(newData);
    }

    _onchange = () => {
        let value = (document.getElementById(this.state.id + '_type') as HTMLSelectElement).value;
        this.setState({ dataType: value });
    }

    _onset = () => {
        let dataType = (document.getElementById(this.state.id + '_type') as HTMLSelectElement).value;
        let attachment = { dataType: dataType, data: (0 as any) };
        if (dataType === 'link') {
            let link = (document.getElementById(this.state.id + '_link') as HTMLInputElement).value;
            attachment.data = link;
        }
        if (dataType === 'notes') {
            let notes = (document.getElementById(this.state.id + '_notes') as HTMLInputElement).value;
            attachment.data = notes;
        }

        this.setState({ attachments: [attachment, ...this.state.attachments] });
    }

    _attach = () => {
        this.setState({ attaching: !this.state.attaching });
    }

    _splice = (i: number) => {
        let att = [...this.state.attachments];
        att.splice(i, 1);
        this.setState({ attachments: att });
    }

    render() {

        //console.log(this.state.attachments)
        return (
            <div className={this.props.editing ? "modal active" : "modal"} >
                <a className="modal-overlay" aria-label="Close" onClick={() => { this.props.toggleEditor() }}></a>
                <div className="modal-container">
                    <div className="modal-header">
                        <a className="btn btn-clear float-right" aria-label="Close" onClick={() => { this.props.toggleEditor() }}></a>
                        <div className="modal-title h5">New Data</div>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="form-label">Title</label>
                            <input id={this.state.id + '_title'} className="form-input" type="text" placeholder="Title" />
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <label className="form-label">Add Attachments</label>
                                <button id={this.state.id + '_add'} className="btn btn-primary ml-2 input-group-btn bt-sm" onClick={this._attach}>Add</button>
                            </div>
                            {this.state.attaching &&
                                <div>
                                    <div className="form-group">
                                        <label className="form-label">Type</label>
                                        <div className="input-group">
                                            <select id={this.state.id + '_type'} className="form-select" onChange={this._onchange}>
                                                <option value='fitbitsleep'>Fitbit Sleep</option>
                                                <option value='fitbithr'>Fitbit HR</option>
                                                <option value='link'>Link</option>
                                                <option value='notes'>Notes</option>
                                                {/* <option value='custom'>Custom Data</option> */}
                                            </select>
                                            <button id={this.state.id + '_setattachment'} className="btn btn-primary  input-group-btn" onClick={this._onset}>Set</button>
                                        </div>
                                    </div>
                                    {this.state.dataType === 'link' &&
                                        <div className="form-group">
                                            <input id={this.state.id + '_link'} type='text' className="form-input input-sm" placeholder='Input valid URL'></input>
                                        </div>
                                    }
                                    {this.state.dataType === 'notes' &&
                                        <div className="form-group">
                                            <textarea id={this.state.id + '_notes'} className="form-input input-sm" placeholder='Notes'></textarea>
                                        </div>
                                    }
                                    {/* { this.state.dataType === 'custom' &&
                                <textarea id={this.state.id+'_custom'} placeholder='Custom'></textarea>
                            } */}
                                </div>
                            }
                            <div id={this.state.id + '_attachments'}>
                                {this.state.attachments.map((item: any) =>

                                    <div className="tile tile-centered p-2">
                                        <div className="tile-icon">
                                            <div className="example-tile-icon">
                                                <i className="icon icon-file centered"></i>
                                            </div>
                                        </div>
                                        <div className="tile-content">
                                            <div className="tile-title">{item.dataType[0].toUpperCase() + item.dataType.slice(1)}</div>
                                            {item.data !== null && <small className="tile-subtitle text-gray">{item.data}</small>}
                                        </div>
                                        <div className="tile-action">
                                            <button className="btn btn-link disabled ">Delete</button>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                            <div className="form-group">
                                <label className="form-label">Expires</label>
                                <input type='date' className="form-input" id={this.state.id + '_expires'}></input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button id={this.state.id + '_submit'} className="btn btn-primary" onClick={this._submit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export class DataTile extends Component<DataTileProps, S> {

    state = {
        id: randomId('dataTile'),
        dataInstance: platform.dataInstanceProps,
        deleted: false,
        parentUser: this.props.parentUser,
        view: false
    };

    sub = 0;

    constructor(props: DataTileProps) {
        super(props);
        this.state.dataInstance = this.props.dataInstance;
    }

    componentDidMount() {

        if (this.state.dataInstance?._id) {
            setupComponentRefresh(this, 'dataInstance');
        }
    }

    componentWillUnmount() {
        if (this.state.dataInstance?._id) {
            removeComponentRefresh(this, 'dataInstance');
        }
    }

    _delete = () => {
        this.props.platform.deleteDataFromServer([this.state.dataInstance._id]);
        this.setState({ deleted: true });
    }

    _click = () => {
        this.setState({ view: !this.state.view })
    }

    render() {

        return (
            <div id={this.state.id} className="p-2">
                {!this.state.deleted &&
                    <div className="card">
                        <div className="card-header">

                            <div className="float-right">
                                <button id={this.state.id + '_view'} className="btn btn-primary mr-2 btn-sm" onClick={this._click}>View</button>
                                <button id={this.state.id + '_button'} className="btn btn-secondary btn-sm" onClick={this._delete}>Delete</button>
                            </div>

                            <div className="float-left">
                                <h6 className="card-title .h5 mb-0">{this.state.dataInstance.title}</h6>
                                <small id={this.state.id + '_users'} className="card-subtitle text-gray">
                                    {getUserNameById(this,this.state.dataInstance.author,this.state.id + '_users')}
                                </small>
                            </div>
                        </div>
                        {this.state.view &&
                            <div id={this.state.id + '_data'} className="card-body">
                                {this.state.dataInstance.data.map((item: { dataType: string, data: [] }) => {
                                    if (item.dataType === 'fitbithr') {
                                        return <FitBitView
                                            platform={this.props.platform}
                                            parentUser={this.props.parentUser}
                                            setting='heartrate'
                                            fitbit={({} as UserFitbit)}
                                        />
                                    } else if (item.dataType === 'fitbitsleep') {
                                        return <FitBitView
                                            platform={this.props.platform}
                                            parentUser={this.props.parentUser}
                                            setting='sleep'
                                            fitbit={({} as UserFitbit)}
                                        />
                                    }
                                    else return (
                                        <div>
                                            <div>{item.dataType}:</div>
                                            <div>{item.data}</div>
                                        </div>)
                                })}
                            </div>}
                        <div className="card-footer"></div>
                    </div>
                }
            </div>
        )
    }
}



class DataTileContainer extends Component<ContainerProps, S> {

    state = {
        id: randomId('dataTiles'),
        structs: this.props.structs,
        editing: false,
        parentUser: this.props.parentUser
    };

    editorRef = React.createRef<DataCreator>()
    structRefs = [React.createRef<DataTile>()]

    sub:number|undefined = 0

    constructor(props:ContainerProps) {
        super(props);   
    }

    componentDidMount() {
        //this.check();
        setupContainerRefresh(this,'dataInstance',this.sub);
    }

    componentWillUnmount() {
        removeContainerRefresh(this.sub);
    }

    check = async () => {
        let structs = ([] as any);
        if(this.props.structs?.length === 0) {
            structs = await dataInstanceApi.search({'ownerId': this.props.parentUser._id})
            //console.log(this.props.parentUser, structs, this.props.platform.collections)
        }
        if(structs.length > 0) {
            this.structRefs = [];
            structs.forEach(()=>{
                this.structRefs.push(React.createRef<DataTile>());
            });
            this.setState({structs:structs});
        }
    }

    _click = () => {
        if (this.state.editing) this.setState({ editing: false });
        else this.setState({ editing: true });
        this.check();
    }

    _refresh = () => {
        this.check();
    }

    render() {

        let dataToView = (this.props.structs) ? this.props.structs.filter(o => o.ownerId === this.props.parentUser?._id) : []

        return (
            <div id={this.state.id} className="panel">
                <div className="panel-header">
                    <div className="panel-title h6 float-left">Data</div>
                    <div className="float-right">
                        <button className="btn btn-primary mr-2 btn-sm" onClick={this._click}>New</button>
                        {dataToView.length === 0 &&
                            <button className="btn btn-secondary btn-sm" onClick={this._refresh}>Refresh</button>
                        }
                    </div>
                </div>
                <div className="panel-body">
                    {this.state.editing &&
                        <DataCreator
                            platform={this.props.platform}
                            parentUser={this.props.parentUser}
                            ref={this.editorRef}
                            updateParent={this._click}
                            editing={this.state.editing}
                            toggleEditor={this._click}
                        />
                    }
                    {dataToView.map((item: any, idx: number) =>
                        <DataTile
                            platform={this.props.platform}
                            parentUser={this.props.parentUser}
                            dataInstance={item}
                            ref={this.structRefs[idx]}
                        />
                    )
                    }
                </div>
                <div className="panel-footer" />
            </div>
        )
    }
}

export default linker((s) => ({ structs: s.dataInstance }), DataTileContainer);

class DataTileDisplayClass extends DataTileContainer {

    constructor(props:ContainerProps) {
        super(props);   
    }

    dataEmptyState = (p: { isLoading: boolean; }) => <div className="empty">
        <div className="empty-icon">
            {p.isLoading ? <div className="loading" /> : <AiOutlineDatabase className="icon" />}
        </div>
        <p className="empty-title h5">{p.isLoading ? 'Loading data' : 'Client has no data'}</p>
        <p className="empty-subtitle">Click button to add client data.</p>
        <div className="empty-action">
            <button
                // disabled={true}
                onClick={this._click}
                className={`btn btn-primary${p.isLoading ? ' loading' : ''}`}
            >Add Data</button>
        </div>
    </div>

    render() {

        let dataToView = (this.props.structs) ? this.props.structs.filter(o => o.ownerId === this.props.parentUser?._id) : []

        return (
            <>
             {this.state.editing &&
                <DataCreator
                    platform={this.props.platform}
                    parentUser={this.props.parentUser}
                    ref={this.editorRef}
                    updateParent={this._click}
                    editing={this.state.editing}
                    toggleEditor={this._click}
                />
            }
            {dataToView.length > 0 ? <>
            <div className="pt-2 px-2">
                {dataToView.map((item: any, idx: number) =>
                        <DataTile
                            platform={this.props.platform}
                            parentUser={this.props.parentUser}
                            dataInstance={item}
                            ref={this.structRefs[idx]}
                        />
                    )
                }
            </div> 
            <div className="panel-footer">
                        <button
                            className={`btn btn-primary btn-block`}
                            onClick={this._click}
                        >Add Data</button>
                    {/* <button
                        className={`btn btn-primary btn-block${loading ? ' loading' : ''}`}
                        disabled={loading}
                        onClick={editInfo}
                    >Edit Profile</button> */}
                </div>
            </>
            
            : this.dataEmptyState({isLoading: false})}
            </>
        )
    }
}

export const DataTileDisplay = linker((s) => ({ structs: s.dataInstance }), DataTileDisplayClass);





export function getDataTile(parent: any, dataId: string) {
    let d = parent.props.platform.getStruct(dataId);
    if (!d) {
        parent.props.platform.getServerData([dataId], parent.state.event.ownerId, (res: any) => { //does async work here? we'll find out!
            parent.props.platform.defaultCallback(res);
            if (res.data[0]) d = res.data[0];
            setTimeout(() => { parent.render() }, 100);
            return <DataTile
                platform={parent.props.platform}
                parentUser={parent.props.parentUser}
                dataInstance={d}
            />
        });
    }
    else
        return <DataTile
            platform={parent.props.platform}
            parentUser={parent.props.parentUser}
            dataInstance={d}
        />
    return;
}