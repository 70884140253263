import { loadSleepData } from "frontend/apis/fitbit-data/fitbit-sleep";
import { getUserFitBitToken } from "frontend/utils/general";
import { platform } from '../shared'

export async function getFitBitData(user,type='sleep') {
    if(typeof user === 'string') {
        let struct = platform.getLocalData('profile',{_id:user});
        if(!struct) user = {_id:user}; 
        else user = struct;
    }
    let userToken = await getUserFitBitToken(user);
    //console.log(userToken);
    let fitbit = userToken?.fitbit;

    if(fitbit) {
        if(type === 'sleep') {
            let data = await loadSleepData(fitbit);
            console.log(data);
            return data;
        }
        else return fitbit;
    } else return null;
}

export class DataTablet {

    constructor(platform) {
        this.data = {
            byTime:{}, //everything is arranged by time
            notes:{},
            events:{},
            sleep:{}, //or everything is arranged by type (then by time)
            food:{},
            hr:{},
            ppg:{},
            ecg:{},
            emg:{},
            eeg:{},
            fnirs:{}
        }

        this.platform = platform;

    }

    async sortStructsIntoTable(datastructs=[]) {
        //sort by timestamp 
        let ascending = function(a,b) { return a.timestamp - b.timestamp; }
        /**
         * let descending = function(a,b) { return b.timestamp-a.timestamp };
         */
        datastructs.sort(ascending); //reorder

        let newdata = [];

        //now distribute into data
        for(let i = 0; i < datastructs.length; i++) {
            let struct = datastructs[i];
            if(!struct.timestamp) continue;
            let timestamp = struct.timestamp;

            if(!this.data.byTime[timestamp])
                this.data.byTime[timestamp] = [struct];
            else this.data.byTime[timestamp].push(struct);

            if(struct.structType === 'dataInstance') {
                //we should sort instanced fitbit data into timestamped bins with markers for different resolutions
                //other data in dataInstance.data array will be like {dataType:'notes',data:'abcdefg'} 
                struct.data.forEach(async (dat) => {
                    if(typeof dat === 'object' && !Array.isArray(dat)) {
                        let typ = dat.dataType;
                        if(typeof typ === 'object') typ.ownerId = struct.ownerId;
                        if(typ === 'notes' || typ === 'note' || typ === 'link') {
                            dat.timestamp = timestamp;
                            if(!this.data.notes[timestamp])
                                this.data.notes[timestamp] = [dat];
                            else this.data.notes[timestamp].push(dat);
                                                
                            if(!this.data.byTime[timestamp])
                                this.data.byTime[timestamp] = [dat];
                            else this.data.byTime[timestamp].push(dat);
                            this.onUpdate(timestamp, dat);
                            newdata.push(dat);
                        } else if (typ === 'fitbitsleep') { //grab the sleep data for this user and parse it into the tablet system
                            let data = await getFitBitData(struct.ownerId,'sleep');
                            console.log('sorting',data)
                            if(data?.sleepData) {
                                data.sleepData.forEach((obj) => {
                                    if(obj) {
                                        let sleepdata = {
                                            structType:'fitbitsleep',
                                            startTime:Date.parse(obj.startTime),
                                            endTime:Date.parse(obj.endTime),
                                            timestamp:Date.parse(obj.endTime),
                                            duration:obj.duration, //ms
                                            levels:obj.levels,
                                            ownerId:struct.ownerId
                                        };
                                        if(!this.data.sleep[sleepdata.timestamp])
                                            this.data.sleep[sleepdata.timestamp] = [sleepdata];
                                        else this.data.sleep[sleepdata.timestamp].push(sleepdata);

                                        if(!this.data.byTime[timestamp])
                                            this.data.byTime[timestamp] = [sleepdata];
                                        else this.data.byTime[timestamp].push(sleepdata);
                                        
                                        this.onUpdate(timestamp, sleepdata);
                                        newdata.push(sleepdata);
                                    }
                                });
                            }
                        } else if (typ === 'fitbithr') {

                        } else if (typ) {
                            if(!this.data[typ]) this.data[typ] = {};

                            dat.timestamp = timestamp;
                            if(!this.data[typ][timestamp]) 
                                this.data[typ][timestamp] = [dat];
                            else this.data[typ][timestamp].push(dat);
                            if(!this.data.byTime[timestamp])
                                this.data.byTime[timestamp] = [dat];
                            else this.data.byTime[timestamp].push(dat);
                            this.onUpdate(timestamp, dat);
                            newdata.push(dat);
                        }
                    }
                });
            }
            else if (struct.structType === 'event') {
                if(!this.data.events[timestamp])
                    this.data.events[timestamp] = [struct];
                else this.data.events[timestamp].push(struct);

                if(struct.event === 'sleep') {
                    if(!this.data.sleep[timestamp])
                        this.data.sleep[timestamp] = [struct];
                    else this.data.sleep[timestamp].push(struct);
                }

                this.onUpdate(timestamp, struct);
                newdata.push(struct);
            } else {
                let typ = struct.structType;
                if(!this.data[typ]) this.data[typ] = {};
                if(!this.data[typ][timestamp])
                    this.data[typ][timestamp] = [struct];
                else this.data[typ][timestamp].push(struct);
                this.onUpdate(timestamp, struct);
                newdata.push(struct);
            }
        }

        for(const prop in this.data) {
            this.data[prop] = this.sortObjectByPropName(this.data[prop]); //should arrange the object by timestamp
        }

        this.onSorted(newdata);
    }

    onUpdate(timestamp, struct, data=this.data) {}

    onSorted(newdata=[]) {}

    getDataByTimestamp(timestamp,ownerId) {
        let result = this.data.byTime[timestamp];
        if(ownerId && result) result = result.filter((o)=>{if(!ownerId) return true; else if(ownerId === o.ownerId) return true;});
        return result;
    }

    getDataByTimeRange(begin,end,type,ownerId) {
        let result = {};
        if(type) {
            for(const key in this.data[type]) {
                let t = parseInt(key);
                if(t > begin && t < end){
                    result[key] = [...this.data[type][key]];
                }
            }
            if(type === 'sleep') {
                result = this.filterSleepResults(result);
            }
            
        }
        else {
            for(const key in this.data.byTime) {
                let t = parseInt(key);
                if(t > begin && t < end){
                    result[key] = [...this.data.byTime[key]];
                }
            }
        }
        if(ownerId && result) {
            for(const key in result) {
                let popidx = [];
                result[key] = result[key];
                result[key].forEach((o,i) => {
                    if(o.ownerId !== ownerId) {
                        popidx.push(i);
                    }
                });
                popidx.reverse().forEach((idx) => {
                    result[key].splice(idx,1);
                });
                if(result[key].length === 0) delete result[key];
            }
        }
        return result;
    }

    getDataByType(type,timestamp,ownerId) {
        if(!this.data[type]) return undefined;
        let result = {...this.data[type]};
        if(timestamp) result = [...result[timestamp]];

        if(ownerId && result) {
            for(const key in result) {
                let popidx = [];
                result[key] = [...result[key]];
                result[key].forEach((o,i) => {
                    if(o.ownerId !== ownerId) {
                        popidx.push(i);
                    }
                });
                popidx.reverse().forEach((idx) => {
                    result[key].splice(idx,1);
                });
                if(result[key].length === 0) delete result[key];
            }
        }
        if(type === 'sleep') {
            result = this.filterSleepResults(result);
        }
        
        return result;
    }

    filterSleepResults(unfiltered = {}) {
        //need to check if any events are overlapping with fitbit data then pop any fitbit data, assuming events are more accurate
        let events = [];
        for(const key in unfiltered) {
            unfiltered[key] = [...unfiltered[key]]; //copy result
            events.push(...unfiltered[key].filter((o) => {
                if(o.structType === 'event') return true;
            }));
        }

        events.forEach((ev) => {
            let foundidx = undefined;
            for(const key in unfiltered) {
                unfiltered[key].forEach((o) => {
                    //assume sleep data within 12 hours and longer than 2 hours is to be replaced
                    if(o.structType === 'fitbitsleep' && ev.startTime && ev.endTime) {
                        if(Math.abs(o.startTime - ev.startTime) < 1000*12*3600 && Math.abs(o.endTime - ev.endTime) < 1000*12*3600 && (ev.endTime - ev.startTime) > 1000*2*3600) {
                            foundidx = i;
                            return true;
                        }  
                    }
                }); 
                if(foundidx) unfiltered[key].splice(foundidx,1);
            }   
        });
        
        let result = unfiltered;
        return result;
    } 

    sortObjectByPropName(object) {

        const ordered = Object.keys(object).sort().reduce(
            (obj, key) => { 
              obj[key] = object[key]; 
              return obj;
            }, 
            {}
          );
    
        return ordered;
    }

}