import { UserObj } from '@myalyce/common/models/user.model';
import { arrToBoolDict, Dict, rand, sec, wait } from '@giveback007/util-lib';
import { userApi } from 'frontend/apis/user.api';
import { store } from 'frontend/store/store';
import { genRandText, getUserCodes } from 'frontend/utils/general';
import { getData } from 'frontend/utils/fetch-methods';
import React, { Component } from 'react';
import { addNotification } from 'frontend/utils/store.utils';
import { Avatar } from 'frontend/components/utils/component.utils';

import logo from '../assets/squarelogo.png';

type S = {
    connecting: boolean;
    server: boolean;
    database: false | string;
    counter: string;
    username: string;
    usernameLoader: boolean;
    currentUser: UserObj | null;
    allUsers: UserObj[];
    clientBoolDict: Dict<boolean>,
}

export default class Test extends Component<{}, S> {
    state: S = {
        connecting: true,
        server: false,
        database: false,
        counter: '-4s',
        username: '',
        usernameLoader: false,
        currentUser: null,
        allUsers: [],
        clientBoolDict: {},
    }

    sub = store.stateSub('currentUser', ({ currentUser }) => {
        const clientBoolDict = arrToBoolDict(currentUser?.clients || []);
        const username = currentUser?.username || '';

        this.setState({ username, currentUser, clientBoolDict });
    }, true);

    componentWillUnmount() {
        this.sub.unsubscribe();
    }

    componentDidMount() {
        const t1 = Date.now();

        getData<{ itWorks: boolean }>('/test/be').then((x) => this.setState({
            server: x.data?.itWorks || false, connecting: false,
        }));

        getData<{ connected: boolean, type: string }>('/test/db').then((x) => {
            if (x.type === 'SUCCESS') this.setState({
                database: x.data.type, connecting: false
            })
        });
        
        setInterval(() => {
            const t2 = Date.now();
            this.setState({ counter: Math.floor((t2 - t1) / 1000) - 3 + 's' });
        }, 1000);

        userApi.all().then((res) => {
            if (res.type === 'SUCCESS') {
                this.setState({ allUsers: res.data });
            } else {
                addNotification({ text: 'Failed To Get Users', type: 'error' }, sec(10));
            }
        });
    }

    changeUsername = async () => {
        this.setState({ usernameLoader: true })
        await wait(500);
        const { username } = this.state;
        const user = store.getState().currentUser;

        let errTxt = '';

        if (!user) errTxt = 'No user!';
        if (user && user.username === username) errTxt = 'No change made!';
        if (username.length < 6) errTxt = 'Username must be more than 5 characters long!';

        if (errTxt) {
            this.setState({ usernameLoader: false })
            return addNotification({ text: errTxt, type: 'error' }, sec(10));
        }

        const res = await userApi.patch((user as any)._id, { username });
        this.setState({ usernameLoader: false });
        if (res.type === 'ERROR')
            return addNotification({ text: 'Something failed!', type: 'error' }, sec(10));

        addNotification({ text: `Username changed. "${(user as any).username}" -> "${res.data.username}"`, type: 'success' }, sec(10));
        store.setState({ currentUser: getUserCodes(res.data, true) });
    }

    toggleUser = async (user: UserObj) => {
        const s = this.state;
        const cu = s.currentUser;
        if (!cu) throw new Error('This shouldn\'t happen');
        
        const isClient = s.clientBoolDict[user._id];
        const preClients = cu.clients || [];
        const clients = isClient ? preClients.filter((id) => id !== user._id) : [...preClients, user._id]

        const res = await userApi.patch(cu._id, { clients });
        if (res.type === 'ERROR') return addNotification({ text: `Error trying to ${isClient ? 'remove' : 'add'}: ${user.fullName || user.email}`, type: 'error' }, sec(5));

        store.setState({ currentUser: getUserCodes(res.data, true) });
        addNotification({ text: `${isClient ? 'Removed' : 'Added'} ${user.fullName || user.email} as client`, type: 'success' }, sec(5));
    }

    render = () =>  {
        const s = this.state;
        let be = 'Looking for server...', db = be;
        
        if (!s.connecting) {
            be = s.server ? 'Connected' : '?';
            db = s.database ? `Connected to ${s.database}` : '?';
        }
        
        return <div className={`p-2`}> 

            <Avatar
                img={{ initials: 'AB', src: logo }}
                size='xl'
                badge={99}
                status='online'
            />

            <Avatar
                img={{ initials: 'CD', backgroundColor: 'red' }}
                size='lg'
                badge={true}
                status='offline'
            />

            <Avatar
                img={{ initials: 'EF', backgroundColor: 'orange' }}
                size='md'
            />

            <br/><br/>

            <button className="btn btn-primary" onClick={() => {
                const x = ['primary', 'success', 'warning', 'error'] as const;
                
                addNotification({
                    text: genRandText({ min: 15, max: 65 }),
                    type: x[rand(0, 3)],
                });
            }}>Add Random Notification</button>

            <br/><br/>

            <div className="input-group">
                <span className="input-group-addon">Username</span>
                <input
                    type="text"
                    className="form-input"
                    placeholder="Username"
                    value={s.username}
                    disabled={s.usernameLoader}
                    onChange={(e) => this.setState({ username: e.target.value })}
                />
                <button
                    disabled={s.usernameLoader}
                    className={`btn btn-primary input-group-btn${s.usernameLoader ? ' loading' : ''}`}
                    onClick={this.changeUsername}
                >Update</button>
            </div>            

            <br/><br/>

            <h2>Frontend: It works</h2>
            <h2>Backend: {be}</h2>
            <h2>Database: {db}</h2>
            <h3>{s.counter}</h3>

            <br/><br/>

            <table className="table table-striped table-hover table-scroll">
                <thead>
                    <tr>
                        <th></th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Fitbit</th>
                        <th>+/- Client</th>
                    </tr>
                </thead>
                <tbody>
                    {s.allUsers.map((user) => {
                        const { initials, profColor, codeName } = getUserCodes(user);
                        
                        return <tr>
                            <td><figure className="avatar avatar-lg" data-initial={initials} style={{backgroundColor: profColor}}></figure></td>
                            <td>{codeName}</td>
                            <td>{user.fullName}</td>
                            <td>{user.email}</td>
                            <td style={{ color: user.fitbit ? 'green' : 'red' }}>{user.fitbit ? '✓' : '✗'}</td>
                            <td><button
                                className={`btn btn-${s.clientBoolDict[user._id] ? 'error' : 'success'}`}
                                onClick={() => this.toggleUser(user)}
                                style={{ minWidth: '1.6rem' }}
                            >
                                {s.clientBoolDict[user._id] ? '-' : '+'}
                            </button></td>
                        </tr>
                    })}
                    
                </tbody>
            </table>
        </div>
    }
}
