


import React, { Component } from 'react'
import { randomId } from '@myalyce/common';
//import {comState} from 'frontend/shared'
import { ContainerProps, getUserNameById, NotificationProps, removeComponentRefresh, removeContainerRefresh, setupComponentRefresh, setupContainerRefresh } from "./Templates.shared";
import { genChatTime } from 'frontend/utils/general';
import { BiBell } from 'react-icons/bi';
import css from '../TopBar.module.scss';
import { notificationApi } from 'frontend/apis/notification.api';
import { linker } from 'frontend/store/store';

interface S {}

export class Notification extends Component<NotificationProps, S> {
        
    state = {
        id:randomId('notification'),
        notification: this.props.platform.notificationProps,
        deleted:false,
        parentUser:this.props.parentUser
    };

    sub = 0;

    constructor(props:NotificationProps) {
        super(props);   
        this.state.notification = this.props.notification;
    }

    componentDidMount() {
        if(this.state.notification?._id) {
            setupComponentRefresh(this,'notification');
        }
    }

    componentWillUnmount() {
        if(this.state.notification?._id) {
            removeComponentRefresh(this,'notification');
        }
    }

    _click = () => {
        this.props.platform.resolveNotifications([this.state.notification],true,this.props.parentUser);
        this.setState({deleted:true});
    }

    render() {
        return (
            <div id={this.state.id}>
                {!this.state.deleted && <div className="tile tile-centered p-2">
                    <div className="tile-content">
                        <div className="tile-title">{this.state.notification.parent.structType}</div>
                        <small className="tile-subtitle text-gray">{genChatTime(this.state.notification.timestamp)}</small>
                    </div>
                    <div className="tile-action">
                        <button onClick={this._click} className="btn btn-link">Resolve</button>                
                    </div>
                </div>}
            </div>
        )
    }
}

export class NotificationsDropdownClass extends Component<ContainerProps, S> {
    state = {
        id:randomId('notifications'),
        structs:this.props.structs,
        parentUser:this.props.parentUser,
        sorted:new Array(),
        sortedResolved:new Array()
        //editing:false
    };

    structRefs = [React.createRef<Notification>()]

    constructor(props:ContainerProps) {
        super(props);   
    }

    componentDidMount() {
 
        // this.check();
        // comState.setState({[this.state.id]: this.state.structs});
        // comState.subscribe(this.state.id,()=>{
        //     this.render(); //state updated re-render component
        // });
    }

    componentWillUnmount() {
        // comState.unsubscribeAll(this.state.id);
    }

    check = async () => {
        // let structs = [];
        // if (this.props.structs){
        //     if(this.props.structs.length === 0) {
        //         structs = await notificationApi.search({'ownerId': this.props.parentUser._id})
        //     }
        //     if(structs.length > 0) {
        //         this.structRefs = [];
        //         let sorted = this.sortNotifications(structs);
        //         structs.forEach((s:any)=>{
        //             if(s.ownerId !== this.props.parentUser._id && s.parent.structType !== 'comment')
        //                 this.structRefs.push(React.createRef<Notification>());
        //         });
        //         this.setState({structs:structs,sorted:sorted});
        //     }
        // }
    }

    sortNotifications(structs:any[]) {
        let sorted = new Array();
        structs.forEach((struct) => {
            if(struct.ownerId !== this.props.parentUser?._id) {
                let owner = struct.ownerId;
                let found = sorted.find((o:any) => {
                    if(o.ownerId === owner) {
                        return true;
                    } return false;
                });
                if(found) {found.counter++; found.structs.push(struct);}
                else sorted.push({ownerId:owner, counter:1, structs:[]});
            }
        });
        return sorted;
    }

    _click = () => {
       
    }

    _refresh = () => {
        this.check();
    }

    render() {

        let notificationsToView = this.props.structs ?? []

        return (<>
            <div className="dropdown link-btn-hover">
                <a tabIndex={0} className="btn btn-link dropdown-toggle"><BiBell/><div className={css.barBadge + (this.state.structs && this.state.structs.length > 0 && " badge")} data-badge={this.state.structs?.length}/></a>
                <ul className="menu">
                <li className="divider" data-content="NOTIFICATIONS"/>
                {this.state.sorted.length > 0 &&
                        this.state.sorted.map((item,i) => {
                            this.state.sortedResolved[i] = false;
                            let onclick = () => { //hax
                                //console.log(this.state.auth.authorizerId)
                                this.props.platform.getUserFromServer(item.ownerId);
                                //this.props.platform.getAuthorizations(this.state.auth.authorizerId); 
                                this.props.platform.getUserFromServer(item.ownerId);
                                this.props.platform.resolveNotifications(item.structs,false);//for now
                                let resolved = this.state.sortedResolved;
                                resolved[i] = true;
                                this.setState({sortedResolved:resolved});
                            }
                            return <div>
                                        { !this.state.sortedResolved[i] &&
                                        <button onClick={onclick}>
                                            <div><p id={this.state.id+'_'+item.ownerId}>{getUserNameById(this,item.ownerId,this.state.id+'_'+item.ownerId)}</p>:<p>{item.counter}</p></div>
                                        </button>
                                        }
                                    </div>;
                    })}
                    { notificationsToView && notificationsToView.length > 0 &&
                    notificationsToView.map((item: any, idx:number) => {
                        // if(item.parent.structType !== 'comment' && item.parentUserId === this.props.parentUser._id)
                            return <li className="menu-item">
                                <a  href="#">
                                <Notification 
                                platform = {this.props.platform}
                                parentUser = {this.props.parentUser}
                                notification = {item}
                                ref = {this.structRefs[idx]}
                                />
                                </a>
                                </li>
                        // else return;
                    })
                    }
                </ul>
            </div>
            </>)
        
    }
}

export const NotificationsDropdown = linker((s) => ({ structs: s.notification }), NotificationsDropdownClass)

export class NotificationsContainer extends Component<ContainerProps, S> {
        
    state = {
        id:randomId('notifications'),
        structs:this.props.structs,
        parentUser:this.props.parentUser,
        sorted:new Array(),
        sortedResolved:new Array()
        //editing:false
    };

    structRefs = [React.createRef<Notification>()]

    sub:number|undefined = 0

    constructor(props:ContainerProps) {
        super(props);   
    }

    componentDidMount() {
        this.check();
        setupContainerRefresh(this,'notification',this.sub);
    }

    componentWillUnmount() {
        removeContainerRefresh(this.sub);
    }

    check = async () => {
        let structs = [];
        if (this.props.structs){
            if(this.props.structs.length === 0) {
                structs = await notificationApi.search({'ownerId': this.props.parentUser._id})
            }
            if(structs.length > 0) {
                this.structRefs = [];
                let sorted = this.sortNotifications(structs);
                structs.forEach((s:any)=>{
                    if(s.ownerId !== this.props.parentUser._id && s.parent.structType !== 'comment')
                        this.structRefs.push(React.createRef<Notification>());
                });
                this.setState({structs:structs,sorted:sorted});
            }
        }
    }

    sortNotifications(structs:any[]) {
        let sorted = new Array();
        structs.forEach((struct) => {
            if(struct.ownerId !== this.props.parentUser?._id) {
                let owner = struct.ownerId;
                let found = sorted.find((o:any) => {
                    if(o.ownerId === owner) {
                        return true;
                    } return false;
                });
                if(found) {found.counter++; found.structs.push(struct);}
                else sorted.push({ownerId:owner, counter:1, structs:[]});
            }
        });
        return sorted;
    }

    _click = () => {
       
    }

    _refresh = () => {
        this.check();
    }

    render() {
        return (
            <div id={this.state.id} className="panel">
                <div className="panel-header">
                    <div className="panel-title h6 float-left">Notifications</div>
                    <div className="float-right">
                        <button className="btn btn-primary mr-2 btn-sm" onClick={this._click}>New</button>
                        { this.props.structs && this.props.structs.length === 0 && 
                            <button className="btn btn-secondary btn-sm"  onClick={this._refresh}>Refresh</button>
                        }
                    </div>
                </div>
                <div className="panel-body">
                    {this.state.sorted.length > 0 &&
                        this.state.sorted.map((item,i) => {
                            this.state.sortedResolved[i] = false;
                            let onclick = () => { //hax
                                //console.log(this.state.auth.authorizerId)
                                this.props.platform.getUserFromServer(item.ownerId);
                                //this.props.platform.getAuthorizations(this.state.auth.authorizerId); 
                                this.props.platform.getUserFromServer(item.ownerId);
                                this.props.platform.resolveNotifications(item.structs,false);//for now
                                let resolved = this.state.sortedResolved;
                                resolved[i] = true;
                                this.setState({sortedResolved:resolved});
                            }
                            return <div>
                                        { !this.state.sortedResolved[i] &&
                                        <button onClick={onclick}>
                                            <div><p id={this.state.id+'_'+item.ownerId}>{getUserNameById(this,item.ownerId,this.state.id+'_'+item.ownerId)}</p>:<p>{item.counter}</p></div>
                                        </button>
                                        }
                                    </div>;
                    })}
                    { this.state.structs && this.state.structs.length > 0 &&
                    this.state.structs.map((item: any, idx:number) => {
                        if(item.parent.structType !== 'comment' && item.parentUserId === this.props.parentUser._id)
                            return <Notification 
                                platform = {this.props.platform}
                                parentUser = {this.props.parentUser}
                                notification = {item}
                                ref = {this.structRefs[idx]}
                                />
                        else return;
                    })
                    }
                </div>
                <div className="panel-footer"/>
            </div>
        )
    }
}
