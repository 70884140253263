import { hasKey, wait } from '@giveback007/util-lib';
import { fitbitTokenApi } from 'frontend/apis/fitbit-auth.api';
import { linker, store, UserObj_S } from 'frontend/store/store';
import React, { Component } from 'react';
import { capFirst, getUserCodes } from 'frontend/utils/general';
import { ProfileInfo } from 'frontend/components/ProfileInfo.component';
// import { openClientInfoFormModal } from './dashboard/components/ClientInfoForm.component';
import { copyToClipboard } from '@giveback007/browser-utils';
import { Avatar } from 'frontend/components/utils/component.utils';
import { addNotification, removeNotification, toggleLoader } from 'frontend/utils/store.utils';
import { openClientInfoFormModal } from 'frontend/components/forms/ClientInfoForm.component';


// TODO: blocking loader state.toolBarLoader
    // dict { [id]: true }

// TODO: toolbar loader state.toolBarLoader
    // dict { [id]: true }

// TODO:
    // have a share data btn, this will copy `${url}/?allow=${realmId}` to clipboard

// TODO:
    // 1. generic forms
    // 2. generic modals

interface P {
    authLoad: boolean;
    user: UserObj_S | null;
    appIsInitialized: boolean;
}

interface S {
    /** Fake UI for UX loader */
    clipBoardLoader: boolean;
    tabs: S['tab'][],
    tab: 'profile' | 'fitbit',
    editInfoModal: boolean,
}

class Profile extends Component<P, S> {
    oopsId: number | null = Math.random();
    state: S = {
        clipBoardLoader: false,
        tabs: ['profile', 'fitbit'],
        tab: 'profile',
        editInfoModal: false,
    }

    sub = store.stateSub('currentUser', async (s) => {
        log('USER:', s.currentUser);
    }, true);

    componentWillUnmount() {
        this.sub.unsubscribe();
    }

    selectTab = (tab: S['tab']) => this.setState({ tab });

    render() {
        const s = this.state;
        const p = this.props;
        const { user, authLoad, appIsInitialized } = p;
        
        if (!user) {
            if (!this.oopsId && appIsInitialized) {
                this.oopsId = Math.random();
                // TODO: FIX THIS!
                addNotification({ text: 'Oops... something went wrong on loading user. Try refreshing', type: 'error', id: this.oopsId });
            }
            return null;
        } else {
            if (this.oopsId) removeNotification(this.oopsId);
            this.oopsId = null;
        }

        return <div className={`p-2`}> 
            <div className="column">
                <div className="panel" style={{ maxWidth: '20rem', margin: 'auto' }}>

                    {/* TITLE */}
                    <div className="panel-header text-center">
                        <Avatar
                            img={{ backgroundColor: user.profColor, initials: user.initials }}
                            size='lg'
                        />
                        <div className="panel-title h5 mt-10">{user.fullName}</div>
                        <div className="panel-subtitle">{user.username}</div>
                        <button
                            onClick={() => {
                                this.setState({ clipBoardLoader: true });

                                copyToClipboard(`${APP_URL}?add_user_to_provider=${user._id}`);

                                // trick for smoother UX
                                wait(300).then(() => {
                                    addNotification({ text: 'Shareable link copied to clipboard', type: 'success' }, 8500);
                                    this.setState({ clipBoardLoader: false });
                                })
                            }}
                            className={`btn ${s.clipBoardLoader ? 'loading' : ''}`}
                        >Copy Share URL To Clipboard</button>
                    </div>

                    {/* NAV */}
                    <nav className="panel-nav">
                        <ul className="tab tab-block">
                            {s.tabs.map((tab) => (
                                <li className={`tab-item${tab === s.tab ? ' active' : ''}`}>
                                    <a onClick={() => this.selectTab(tab)}>{capFirst(tab)}</a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    
                    {/* BODY */}
                    {s.tab === 'profile' ? <ProfileInfo
                        // TODO: username
                        // editInfo={() => this.setState({ editInfoModal: true })}
                        editInfo={() => openClientInfoFormModal({ client: false })}
                        selectedClient={user}
                        showNotes={false}
                    />: null}

                    {s.tab === 'fitbit' ? <FitbitTab {...{ user, authLoad }} /> : null}

                    {/* MODALS */}
                    {/* {s.editInfoModal && <div className="modal active" style={{ zIndex: 1002 }}>
                        <a className="modal-overlay" aria-label="Close" onClick={() => this.setState({ editInfoModal: false })} />
                        <div className={`modal-container`} style={{ maxHeight: '95vh' }}>
                            <div className="modal-header">
                                <a className="btn btn-clear float-right" aria-label="Close" onClick={() => this.setState({ editInfoModal: false })}></a>
                                <div className="modal-title h5">Edit Your Info</div>
                            </div>
                            <AddClientInfoForm {...{
                                closeModal: () => this.setState({ editInfoModal: false }),
                                notes: false,
                                selectedClient: user,
                            }} />
                        </div>
                    </div>} */}
                </div>
            </div>
        </div>
    }
}

export default linker(s => ({
    user: s.currentUser,
    authLoad: s.loaders.fitbitAuth,
    appIsInitialized: s.appIsInitialized
}), Profile);

function FitbitTab(p: { user: UserObj_S; authLoad: boolean }) {
    const { user, authLoad } = p;
    const msg = user.fitbit ? {
        main: `Your fitbit data is authorized 👍.`,
        id: <h5>{`Fitbit-Id: ${user.fitbit.id}`}</h5>,
        fct: async () => {
            toggleLoader('fitbitAuth', true);
            const data = await fitbitTokenApi.revokeAuth(user._id);

            if (hasKey(data, 'errors'))
                addNotification({ text: 'Failed to revoke token', type: 'error' });
            else
                store.setState({ currentUser: getUserCodes(data) });

            toggleLoader('fitbitAuth', false)
        },
        btnTxt: 'Revoke Fitbit',
        btnType: 'btn-error',
    } : {
        main: 'Please authorize your fitbit data 🙏.',
        id: null,
        fct: () => {
            toggleLoader('fitbitAuth', true);
            fitbitTokenApi.authorizeRedirect();

            // in case it gets stuck retry:
            setInterval(fitbitTokenApi.authorizeRedirect, 3500);
        },
        btnTxt: 'Add Fitbit',
        btnType: 'btn-success',
    };

    return <>
        <div className="panel-body">
            {/* <h5>{msg.main}</h5>{msg.id} */}
        </div>
        <div className="panel-footer">
            <button
                onClick={msg.fct}
                className={`btn btn-block ${msg.btnType} ${authLoad ? 'loading' : ''}`}
            >{msg.btnTxt}</button>
        </div>
    </>
}
