import { authReducer } from "./reducers/auth.reducer";
import { fitbitReducer } from "./reducers/fitbit.reducer";
import { generalReducer } from "./reducers/general.reducer";
import { providerReducer } from "./reducers/provider.reducer";
import { urlReducer } from "./reducers/url.reducer";
import { userReducer } from "./reducers/user.reducer";
import { StateManagerType } from "./store";

const reducers: ((store: StateManagerType) => void)[] = [
    authReducer,
    fitbitReducer,
    providerReducer,
    urlReducer,
    userReducer,
    generalReducer,
]

export function initReducers(store: StateManagerType) {
    reducers.forEach((fct) => fct(store));
}
