import React from 'react';
import { getEventTag } from '@myalyce/common/data/client-events';
import { ClientEventObj } from '@myalyce/common/models/client-event.model';
import { EvChip } from './ClientEventsTable.component';

export const EventDetails = ({ event: ev }: { event: ClientEventObj }) => {
    const tag = getEventTag(ev.tag, ev.negSeverity);

    return <div className="panel">
        <div className="panel-header">
            <div className="panel-title"><EvChip tag={tag} /></div>
            {/* <div className="panel-subtitle">{ev.title}</div> */}
        </div>
        <div className="panel-body">
            
        </div>
        <div className="panel-footer">
            <button
                className="btn btn-primary btn-block"
                onClick={() => ''}
            >Edit Event</button>
        </div>
    </div>
}
