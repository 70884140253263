import { RealmUser } from "frontend/auth";

// export type act<D, S> = (data: D) => any;
// export const act = <D>() => <S extends string>(type: S): act<D, S> =>
//     (data: D) => store.action({ type, payload: data });

// export const ACTION = {...(new class {
//     LOGIN = act<boolean | void>()(TYPES.loginSuccess);
// })};

// export const TYPES = {...(new class {
//     readonly loginSuccess = 'LOGIN_SUCCESS';
// })};

class TYPES {
    readonly loginRefresh = 'LOGIN_REFRESH';
    readonly loginSuccess = 'LOGIN_SUCCESS';
    readonly logOut = 'LOGOUT';
    readonly urlParamsChanged = 'URL_PARAMS_CHANGED'; 
    readonly clientsLoad = 'CLIENTS_LOAD';
    readonly clientEventsLoad = 'LOAD_CLIENT_EVENTS';
    readonly userLoad = 'USER_LOAD';
    readonly debounceWinResize = 'DEBOUNCE_WINDOW_RESIZE_200ms'
}

export const Action = new TYPES;

export interface AnyAction { readonly type: string; data?: any; }

type LoginSuccess = { type: TYPES['loginSuccess']; data: RealmUser; }
type LoginRefresh = { type: TYPES['loginRefresh']; }
type LogOut = { type: TYPES['logOut']; }

type ClientsLoad = { type: TYPES['clientsLoad']; }
type LoadClientEvents = { type: TYPES['clientEventsLoad']; data: { clientId: string }; }

type UrlParams = { type: TYPES['urlParamsChanged']; }

type UserLoad = { type: TYPES['userLoad']; data: { userId: string }; };

type DebounceResize = { type: TYPES['debounceWinResize']; data: { height: number; width: number; } }

export type AllActions =
    | LoginSuccess
    | LogOut
    | LoginRefresh
    | UrlParams
    | ClientsLoad
    | LoadClientEvents
    | UserLoad
    | DebounceResize;

