import Router from "./Router.js";

export default class Offloader extends Router {

    get [Symbol.toStringTag]() { return 'Offloader' }

    constructor(settings) {
        
        // Socket Created on Route
        super('server', settings)

        this.queue = []

        // Adding Custom Callbacks
        this.socket.onopen = () => {

            // Strip Self
            let data = {}
            for (let key in settings){
                if (key != 'source') data[key] = settings[key]
            }

            this.send({cmd: 'settings', data});
        }

        this.socket.onmessage = (o) => {
            if (o.cmd === 'ready') {
                this.readyForData = true
                this.queue.forEach(f => f())
            }
            // else if (o.cmd === 'data') this.ondata(o)
        }

        this.socket.onclose = (msg) => {
            this.readyForData = false
        }
    }


    send = (o) => {
        return new Promise(resolve => {
            let sendFunction = async () => resolve(await this.socket.send(o, 'offload'));
            if (this.readyForData || o.cmd === 'settings') sendFunction()
            else this.queue.push(sendFunction)
        })

    }

    sendData = async (data) => {
        return await this.send({cmd: 'data', data});
    }

    // Process by sending to server
    process = async (args) => {
        if (!Array.isArray(args)) args = [args]
        let res = await this.sendData(...args)
        this.ondata(res) // pass to subscriptions
        return res.data
    }


    // Hook for Insertable Streams Transform
    transform = async (chunk, controller) => {
        let res = await this.sendData(chunk) // applies function on server
        if (res) controller.enqueue(res.data)
    }
}