

import * as vis from 'vis-timeline/standalone' // create groups
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { UserPlatform } from 'frontend/js/UserPlatform';
import { randomId } from '@myalyce/common';


// Examples: https://visjs.github.io/vis-timeline/examples/timeline/
// Docs: https://visjs.github.io/vis-timeline/docs/timeline/
// Github: https://github.com/visjs/vis-timeline

// // Group
// var GroupTemplate = (group:any) => {
//   console.log(group)
//     return <div>
//         <label>{group.content}</label>
//     </div>
// }

// Item
class ItemTemplate extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {

    console.log(this.props)

    return <div>
      <label>{this.props.item.content}</label>
      <button onClick={() => { return console.log('Do thing') }}>View</button>
    </div>
  }
}

// // Visible Frame
// class VisibleFramTemplate extends Component {
//     constructor(props:any){
//         super(props);
//     }
//     render() {

//       console.log('frame', this.props)

//         return <div>
//             id: {this.props.item.id}
//             <button onClick={()=> { return console.log('aaaaaa') }}>More Info</button>
//         </div>
//     }
// }


type Props = { platform: UserPlatform }
interface S { }

export class Timeline extends Component<Props, S> {

  onInitialDrawComplete =  () => {
    this.timeline.setItems(this.items)
  }

  template = (item: any, element: any) => {
    if (!item) { return }
    return ReactDOM.createPortal(ReactDOM.render(<ItemTemplate item={item} />, element), element, () => { this.timeline.redraw() });
  }

  groups: vis.DataSet<{}> = new vis.DataSet()
  items: vis.DataSet<{}> = new vis.DataSet()

  state = {
    // Render Options
    options: {
      stack: true,
      orientation: "top",
      height: 400,
      horizontalScroll: true,
      verticalScroll: true,
    
      // only zoom when holding ctrl
      zoomKey: "ctrlKey",
    
      // allow selecting multiple items using ctrl+click, shift+click, or hold.
      multiselect: true,
    
      // allow manipulation of items
      editable: {
        add: true,
        updateTime: true,
        updateGroup: true,
        remove: true
      },
    
      showCurrentTime: true,
    
      start: new Date(),
      end: new Date(1000 * 60 * 60 * 24 + (new Date()).valueOf()),
      onInitialDrawComplete: this.onInitialDrawComplete,
      template: this.template,
    
      // groupTemplate: function (group: any, element: any) {
      //     if (!group || !group.content) { return }
      //     return ReactDOM.createPortal( ReactDOM.render( <GroupTemplate group={group} />, element ), element );
      // },
    
      // visibleFrameTemplate: function (item: any, element: any) {
      //     if (!item || !element) { return }
      //     if (element.className.indexOf('timeline-item-visible-frame') === -1) { return }
      //     return ReactDOM.createPortal( ReactDOM.render( <VisibleFramTemplate item={item} />, element ), element );
      // },
    }
  };

  timeline: any = null
  groupList: string[] = []

  filter: string = 'sleep'

  constructor(props: Props) {
    super(props);


    // Add Initial Data
    let data = this.props.platform.tablet.getDataByType(this.filter) // this.props.platform.tablet.data.byTime
    for (let t in data) data[t].forEach(this.addObject)

    // Pass Updated Data to Timeline
    this.props.platform.tablet.onUpdate = (t:number, o:any) => {this.addObject(o)}
  }

  componentDidMount() {
    return this.initTimeline()
  }

  addObject = (o:AnyDate) => {
    
    console.log('Proessing', o)
    if (o.structType && o.structType.includes(this.filter)){ // only allow specific updates

      if (!o.structType && o.dataType) o.structType = o.dataType
      // Add Structure Type as a Group
      if (!this.groupList.includes(o.structType)){

        let group = {}
        group.id = this.groupList.length
        group.content = o.structType
        group.order = this.groupList.length
        this.groups.add(group)
        this.groupList.push(o.structType)
      }
      
      let item = {}
      item.id = randomId()
      item.group = this.groupList.findIndex(str => str === o.structType)
      if (o.startTime ?? o.timestamp) item.start = new Date(o.startTime ?? o.timestamp)
      if (o.endTime) item.end = new Date(o.endTime)
      // if (!('end' in item)) item.type = 'point'
      item.content = 'Data '// + this.state.items.length
      item.data = o

    if (item.start) this.items.add(item);
    }
  }

  initTimeline() {
    var container = document.getElementById('visualization');
    if (container) {
      
      this.timeline = new vis.Timeline(container, this.items, this.groups, this.state.options);
  
      this.timeline.on('rangechange', function (properties: any) {
        logEvent('rangechange', properties);
      });
  
      this.timeline.on('rangechanged', function (properties: any) {
        logEvent('rangechanged', properties);
      });
  
      this.timeline.on('select', function (properties: any) {
        logEvent('select', properties);
      });
  
      this.timeline.on('itemover', function (properties: any) {
        logEvent('itemover', properties);
        setHoveredItem(properties.item);
      });
  
      this.timeline.on('itemout', function (properties: any) {
        logEvent('itemout', properties);
        setHoveredItem('none');
      });
  
      this.timeline.on('click', function (properties: any) {
        logEvent('click', properties);
      });
  
      this.timeline.on('doubleClick', function (properties: any) {
        logEvent('doubleClick', properties);
      });
  
      this.timeline.on('contextmenu', function (properties: any) {
        logEvent('contextmenu', properties);
      });
  
      this.timeline.on('mouseDown', function (properties: any) {
        logEvent('mouseDown', properties);
      });
  
      this.timeline.on('mouseUp', function (properties: any) {
        logEvent('mouseUp', properties);
      });
    }
  }

  render() {

    return (
      <div>
        <div id="visualization"></div>
      </div>
    )
  }
};


function stringifyObject(object: any) {
  if (!object) return;
  var replacer = function (key: string, value: any) {
    if (value && value.tagName) {
      return "DOM Element";
    } else {
      return value;
    }
  }
  return JSON.stringify(object, replacer)
}

function logEvent(event: any, properties: any) {
  let output = 'event=' + JSON.stringify(event) + ', ' +
    'properties=' + stringifyObject(properties);
  // console.log(output)
}

function setHoveredItem(id: any) {
  console.log('hovered id: ', id)
}