import { AnyObj } from "@giveback007/util-lib";
import { BaseObj } from "@myalyce/common/models/_base.model";
import { platform } from "frontend/shared";

export const Api = <T extends AnyObj>(collection: string | undefined) => {
    
        // TODO: Reconcile WebSocket and REST APIs by defaulting to WS
            // If you pass a callback, start a subscription

        // TODO: Implement the required logic to check if you need to pull from Server or Local Data

        // TODO: Implement type inference

        return {
            byId: (id: string) => platform.getDataFromServer(collection, id),

            getIds: (ids: string[]) => {
                const res:any[] = []
                Promise.all(ids.map(async id => res.push(await platform.getDataFromServer(collection, undefined, {'_id':id}))))
                return res
            },

            all: () => {
                // Grab from Server (if collection map is empty)
                return getData<T>(collection)
            },

            create: (obj: Omit<T, keyof BaseObj<any>> & Partial<BaseObj<any>>) => platform.updateServerData([obj]),

            patch: (_id: string, obj: Partial<T>) => {

                // TODO: Not sure if this assigns the id correctly
                // if (!('_id' in obj)) obj._id = _id
                return platform.updateServerData([obj])

            },

            search: (searchParams: Partial<T>, limit?: number, skip?: number) => {

                // TODO: Convert to Store references
                return getData<T>(collection, searchParams, limit, skip)
            }
        }

}

const getData = <T extends AnyObj>(collection?:string, searchParams?: Partial<T>, limit?: number, skip?: number) => {

    // No Collection
    if (!collection){
        let res =  platform.getLocalData(undefined, searchParams)
        console.log('LOCAL DATA', res)
        if (!res) return platform.getDataFromServer(undefined, undefined, searchParams, limit, skip)
        else return res
    } 
    
    // Check Collection
    else {
        let mapRes = platform.collections.get(collection)
        if (!mapRes) return platform.getDataFromServer(collection, undefined, searchParams, limit, skip)
        else return platform.getLocalData(collection,searchParams)
    }
}
