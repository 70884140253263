import React from 'react'
import { IoIosArrowForward } from 'react-icons/io';
import { linker, State, store } from 'frontend/store/store';
import { navBtnHandler } from 'frontend/utils/general';

import css from './NavDrawer.module.scss';
import logo from "../assets/logo.png";
import { RouteObj } from 'frontend/routes';
import { AllActions } from 'frontend/store/actions';
import { IconType } from 'react-icons';

export type NavBtnType =
    RouteObj & { 
        type: 'route';
        loader?: keyof State['loaders'];
    } | {
        type: 'action';
        action: AllActions
        name: string;
        navIcon: IconType;
        loader?: keyof State['loaders'];
        dev?: boolean;
        userType?: 'dev' | 'personal' | 'client';
    } | {
        type: 'function';
        function: () => any;
        name: string;
        navIcon: IconType;
        loader?: keyof State['loaders'];
        dev?: boolean;
        userType?: 'dev' | 'personal' | 'client';
    };


type btnP = {
    navBtn: NavBtnType;
    navIsOpen: boolean;
    closeOnClick?: boolean;
    isLoading: boolean;
};
function NavBarBtn(p: btnP) {
    const f = (() => {
        const { type } = p.navBtn;
        if (type === 'action') return { type, data: p.navBtn.action };
        if (type === 'function') return { type, data: p.navBtn.function };
        
        return { type, data: p.navBtn.path };
    })();

    return <li className={`${css.nav_item} link-btn-hover ${p.isLoading ? ' loading' : ''}`}>
        <a 
            onClick={() => navBtnHandler(f)}
            className={`${css.nav_link} ${p.navIsOpen ? '' : 'tooltip tooltip-right'}`}
            data-tooltip={p.navBtn.name}
        >
            <div className={css.nav_link_icon}><p.navBtn.navIcon /></div>
            <div className={css.nav_link_text}>{p.navBtn.name}</div>
        </a>
    </li>
}

type P = {
    open: boolean;
    navBtns: NavBtnType[];
    isLoggedIn: boolean;
    loaders: State['loaders'];
}
function Drawer({ open, navBtns, isLoggedIn, loaders }: P) {
    const bodyCL = document.body.classList;
    const bOpen = bodyCL.contains('drawer-open');
    
    if (bOpen && !open)
        bodyCL.remove('drawer-open');
    else if (!bOpen && open)
        bodyCL.add('drawer-open');


    const clientButtons: NavBtnType[] = []
    const personalButtons: NavBtnType[] = []
    const developerButtons: NavBtnType[] = []

    navBtns.forEach(btn =>  {
        switch(btn?.userType) {
            case 'dev':
                developerButtons.push(btn)
                break;
            case 'client':
                clientButtons.push(btn)
                break;
            case 'personal':
                personalButtons.push(btn)
                break;
        }
    });

    return isLoggedIn ? <>
        <nav
            className={css.drawer + ` ${open ? css.drawer_open : ''}`}
            id="drawer"
        >
            <div
                className={css.drawer_head}
                onClick={() => store.toggle('drawerOpen')}
            >
                <div className={`${css.head_arrow} link-btn-hover`}>
                    <IoIosArrowForward />
                </div>
                <div className={css.head_logo}>
                    <img src={logo} />
                </div>
            </div>
            <ul className={css.drawer_nav} id="drawer-nav" style={{maxHeight: ''}}>
                {/* <li className="divider" /> */}


                {open && <li className="divider" data-content="CLIENT"></li>}
                {clientButtons.map((x) => {
                    const isLoading = x.loader ? loaders[x.loader] : false;
                    return <NavBarBtn navIsOpen={open} isLoading={isLoading} navBtn={x} />
                })}

                {open && <li className="divider" data-content="PERSONAL"></li>}
                {personalButtons.map((x) => {
                    const isLoading = x.loader ? loaders[x.loader] : false;
                    return <NavBarBtn navIsOpen={open} isLoading={isLoading} navBtn={x} />
                })}


                {open && <li className="divider" data-content="DEVELOPER"></li>}
                {developerButtons.map((x) => {
                    const isLoading = x.loader ? loaders[x.loader] : false;
                    return <NavBarBtn navIsOpen={open} isLoading={isLoading} navBtn={x} />
                })}
            </ul>
        </nav>
        <span
            className={css.drawer_backdrop + ` ${open ? css.drawer_open : ''}`}
            onClick={() => store.setState({ drawerOpen: false })}
        />
        <div className={css.drawer_spacer} />
    </> : null;
}

export default linker(s => ({
    open: s.drawerOpen,
    isLoggedIn: s.isLoggedIn,
    loaders: s.loaders
}), Drawer);
