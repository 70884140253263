import React from 'react';
import { ClientEventObj } from "@myalyce/common/models/client-event.model";
import { store } from 'frontend/store/store';
import { BsClipboardData, BsInfoCircle } from 'react-icons/bs';
import { capFirst, genChatTime } from 'frontend/utils/general';
import { Chip } from 'frontend/components/utils/component.utils';
import { FiAlertCircle } from 'react-icons/fi';
import { getEventTag } from '@myalyce/common/data/client-events';
import { EventDetails } from './EventDetails.component';
import { openClientEventFormModal } from 'frontend/components/forms/ClientEventForm.component';
import { EventObj } from '@myalyce/common/models/event.model';

type EvChipProps = { tag: ReturnType<typeof getEventTag>; chipColor?: string; labelColor?: string };
export const EvChip = (p: EvChipProps) => <Chip
    img={{ initials: p.tag.emoji, backgroundColor: p.tag.color }}
    label={p.tag.name}
    chipColor={p.chipColor}
    style={{ color: p.labelColor }}
/>;

/** Biometrics Empty State */
export const BiometricsEmptyState = (p: { isLoading: boolean; }) => <div className="empty">
    <div className="empty-icon">
        {p.isLoading ? <div className="loading" /> : <BsClipboardData className="icon" />}
    </div>
    <p className="empty-title h5">{p.isLoading ? 'Loading biometrics' : 'Client has no recorded biometrics'}</p>
    <p className="empty-subtitle">Click button to add client biometrics.</p>
    <div className="empty-action">
        <button
            disabled={true}
            onClick={openClientEventFormModal}
            className={`btn btn-primary${p.isLoading ? ' loading' : ''}`}
        >Record Biometrics</button>
    </div>
</div>


/** Simplified version of the Events Table */
export const EventsTableSimple = (p: {events: ClientEventObj[] | EventObj[]}) => <table className="table table-scroll">
    <thead>
        <tr>
            <th></th>
            <th className="min-width"><a
                data-tooltip="Severity, 0 to 10"
                className="tooltip tooltip-bottom"
            ><FiAlertCircle/></a></th>
            <th>Event</th>
            <th>Status</th>
            <th>Time</th>
        </tr>
    </thead>
    <tbody>
        {p.events.map((ev) => {
            return createTableRow(ev)
        })}
    </tbody>
</table>

// Generate Table Row
export const createTableRow = (ev:any) => {
    let evEndTime = ev.endTime ?? ev.end
    let severity = ev.negSeverity ?? ev.grade
    let time = ev.timeOfEvent ?? ev.startTime ?? ev.begin
    let status = ev.status ?? 'Done'
    let tagName = ev.tag ?? ev.event
    const tag = getEventTag(tagName, severity);

    console.log(ev,tag)

    return (<tr>
<td className="min-width">
    <div className="popover popover-right">
        <button
            className="btn btn-primary"
            onClick={() => store.setState({ topModal: { body: <EventDetails event={ev} />, title: ev.title } })} 
        ><BsInfoCircle /></button>

        <div className="popover-container">
            <div className="card text-dark">
                <div className="card-header">
                    <div className="card-title h5"><EvChip tag={tag} /></div>
                    <div className="card-subtitle text-bold">{ev.title}</div>
                </div>
                {(ev.notes || (evEndTime)) && <div className="card-body">
                    {ev.notes}
                    <br/>
                    {evEndTime && `Ended: ${genChatTime(evEndTime, false)}`}
                </div>}
                <div className='card-footer'>Click <BsInfoCircle /> For More Details</div>
            </div>
        </div>
    </div>
</td>
<td className="min-width">{severity}</td>
<td><EvChip tag={tag} /></td>
<td>{capFirst(status)}</td>
<td>{genChatTime(time, false)}</td>
</tr>)}

/** Full version of the Events Table */
const EventsTable = (_p: { events: ClientEventObj[]; }) => <h1>not-implemented</h1>;

/** Client Events Table */
export const ClientEventsTable = (p: {
    clientId: string;
    useAddEventButton: boolean;
    type?: 'simple' | 'full';
    events: any[];
}) => {
    const { events} = p;
    // const events = clientEvents[clientId];

    // if (!events || !events.length || events === 'loading') {
    //     if (!events) store.action({ type: Action.clientEventsLoad, data: { clientId } });
    //     return <EventsEmptyState isLoading={!events || events === 'loading'}/>;
    // }

    console.log(p, events)
    return <>
    {p.type === 'full' ?  
        <EventsTable events={events} />
        :
        <EventsTableSimple events={events} />
        }
        {/* <div className="panel-footer">
            <button
                className={`btn btn-primary btn-block`}
                onClick={openClientEventFormModal}
            >Add Event</button>
        </div> */}
        </>
};

// export const openClientTableModal = (clientId: string) => store.setState(({ topModal: {
//     title: 'Events',
//     body: <ClientEventsTable clientId={clientId} useAddEventButton={false} type='full' />,
// } }));
