import { debounceTimeOut } from "@giveback007/util-lib";
import { Action } from "../actions";
import { StateManagerType } from "../store";

export function generalReducer(store: StateManagerType) {
    const debounce = debounceTimeOut();
    window.addEventListener('resize', () => debounce(() => {
        const { innerHeight: height, innerWidth: width } = window;
        store.action({ type: Action.debounceWinResize, data: { width, height } })
    }, 200), true);

    store.actionSub([
        Action.debounceWinResize
    ], (a) => {
        store.setState({ windowSize: a.data });
    })
}
