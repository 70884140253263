import { RealmUser } from 'frontend/auth';
import { UserObj } from '@myalyce/common/models/user.model';
import { objExtract } from '@giveback007/util-lib';
import { userApi } from 'frontend/apis/user.api';
import { fitbitTokenApi } from 'frontend/apis/fitbit-auth.api';
import { hasKey } from '@giveback007/util-lib';
import { getUserCodes } from './general';
import { addNotification } from './store.utils';


export const initializeCurrentUser = async (realmUser: RealmUser) => {
    let userRes = (await userApi.byId(realmUser.id));
    
    // TODO: this is a point of attack, need to check on backend if this is the real realmUser
    if (!userRes.data) userRes = await (async () => {
        const { data } = realmUser.profile;

        const send = new UserObj({
            ...objExtract(data, ['email', 'firstName', 'lastName', 'pictureUrl']),
            fullName: data.name,
            identities: realmUser.profile.identities,
            _id: realmUser.id,
        });
        
        return await userApi.create(send);
    })();

    if (userRes.type === 'ERROR' || !userRes.data) {
        console.error('Failed on: initializeCurrentUser(RealmUser)');
        addNotification({ text: 'FAILED TO CONNECT TO SERVER', type: 'error' });
        return false;
    }

    let user = userRes.data;

    // TODO: if can't load data before expiration refresh token also
    if (user.fitbit && (user.fitbit.expiresOn < Date.now())) {
        const res = await fitbitTokenApi.refresh(user._id);
        if (hasKey(res, 'errors')) {
            fitbitTokenApi.revokeAuth(user._id);
            user.fitbit = null;
        } else {
            user = res;
        }
    }

    return getUserCodes(user, true);
}
