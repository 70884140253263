import { arrLast, days, Dict, hasKey, nonValue, average, weeks, sec } from '@giveback007/util-lib';
import { toDate } from '@myalyce/common/';
import { AnyDate } from '@myalyce/common/common-types';
import { UserFitbit } from '@myalyce/common/models/user.model';
import { genDateStr, minToHr } from "frontend/utils/general";
import { addNotification } from 'frontend/utils/store.utils';
import { FitbitApi } from './fitbit.api';
import { Sleep } from './types/Sleep.type';

const sleepDataCache: Dict<GetSleepDataResult | undefined> = { };

export type GetSleepDataResult = {
    fitbitId: string;
    cacheId: string;
    startDay: string;
    endDay: string;
    nOfDays: number;
    dateMarkers: string[];
    sleepData: (Sleep | null)[];
    sleepLevels: {
        deep: (number | null)[];
        light: (number | null)[];
        rem: (number | null)[];
        wake: (number | null)[];
        asleep: (number | null)[];
    };
} | null;

export async function getSleepData(fitbit: UserFitbit, afterDate: AnyDate, xMarkersToToday = false): Promise<GetSleepDataResult> {
    const api = new FitbitApi(fitbit.token, fitbit.id);
    const getTime = (t: string) => new Date(t).getTime();
    const nowDate = new Date();

    const toDateObj = toDate(afterDate);
    if (!toDateObj) throw new Error('Invalid Date');
    afterDate = toDateObj;
    
    const cacheId = `${fitbit.id}:::${genDateStr(afterDate, 'fitbit')}:<TO>:${genDateStr(nowDate, 'fitbit')}`;
    const cachedData = sleepDataCache[cacheId];
    if (!nonValue(cachedData)) return cachedData;
    
    const res = await api.sleep.get({ afterDate, loadAll: true });
    if (res.type === 'ERROR') {
        addNotification({ text: 'Failed to load users Fitbit data', type: 'error' }, sec(7.5));
        return null;
    }
    
    if (!res.data.sleep) return sleepDataCache[cacheId] = null;
    const data = res.data.sleep.sort((a, b) => getTime(a.startTime) - getTime(b.startTime));

    const sleepDict: Dict<Sleep> = {};
    data.forEach(x => sleepDict[x.dateOfSleep] = x);

    const startDay = data[0].dateOfSleep;
    /** if `xMarkersToToday is true` endDay string will be generated upto today, else upto last dateOfSleep */
    const endDay = xMarkersToToday ? genDateStr(nowDate, 'fitbit') as string : arrLast(data).dateOfSleep;
    const sleepData: (Sleep | null)[] = [];
    const dateMarkers: string[] = [startDay];

    const sleepLevels = {
        // asleep is there for when the levels aren't available
        deep: [] as (number | null)[],
        light: [] as (number | null)[],
        rem: [] as (number | null)[],
        wake: [] as (number | null)[],
        asleep: [] as (number | null)[],
    };

    let i = 0;
    let doDateLoop = true;
    let timeLoop = getTime(startDay) + (days(1) / 2);
    // "days(1) / 2" -> count from middle of day in case timezone shifts enough to change date

    while (doDateLoop) {
        timeLoop += days(1);
        const date = genDateStr(timeLoop, 'fitbit');
        if (!date) throw new Error('Invalid Date');
        
        const data = sleepDict[date] || null;
        const summary = data ? data.levels.summary : null;

        dateMarkers[i] = date;
        sleepData[i] = data;

        if (!summary || hasKey(summary, 'asleep')) {
            sleepLevels.asleep[i] = summary ? minToHr(data.minutesAsleep) : null;
            sleepLevels.wake[i] = data ? minToHr(data.minutesAwake) : null;
            sleepLevels.deep[i] = null;
            sleepLevels.light[i] = null;
            sleepLevels.rem[i] = null;
        } else {
            sleepLevels.asleep[i] = null;
            sleepLevels.deep[i] = minToHr(summary.deep.minutes);
            sleepLevels.light[i] = minToHr(summary.light.minutes);
            sleepLevels.rem[i] = minToHr(summary.rem.minutes);
            sleepLevels.wake[i] = minToHr(summary.wake.minutes);
        }
        
        i++;
        if (date === endDay) doDateLoop = false;
    }
    
    return sleepDataCache[cacheId] = {
        fitbitId: fitbit.id,
        cacheId,
        startDay,
        endDay,
        nOfDays: dateMarkers.length,
        dateMarkers,
        sleepData,
        sleepLevels
    };
};



export const loadSleepData = async (fitbit: UserFitbit) => {
    if(!fitbit) return null;
    const sleepDataResult = await getSleepData(fitbit, Date.now() - weeks(52),  true);
    if (!sleepDataResult) return null;
    if (sleepDataResult.fitbitId !== fitbit?.id) return null;

    const { sleepData } = sleepDataResult;
    const y = sleepData.map((x) => minToHr(x ? x.minutesAsleep : 0));

    const sma30: (number | null)[] = [];
    const sma7: (number | null)[] = [];

    let nOfMissing = 0;
    let i30 = 0;
    let i7 = 0;
    
    for (let i = 0; i < sleepData.length; i++) {
        nOfMissing = !y[i] ? nOfMissing + 1 : 0;

        if (i > 30) i30++;
        if (i > 7) i7++;

        const arr30 = y.slice(i30, i + 1).filter(x => x) as number[];
        // if length < 2/3 return null
        sma30.push((i30 >= 20 && nOfMissing < 15) ? Number(average(arr30).toFixed(1)) : null);
        
        const arr7 = y.slice(i7, i + 1).filter(x => x) as number[];
        sma7.push(i7 >= 5 && nOfMissing < 4 ? Number(average(arr7).toFixed(1)) : null);
    }

    let data = {...sleepDataResult, sma30, sma7};
    return data;
}
