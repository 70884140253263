// ICONS //
// FOR MORE ICONS: https://react-icons.github.io/react-icons/
import { MdDashboard, 
    // MdChat 
} from 'react-icons/md';
// import { GoGraph } from 'react-icons/go';
import { BsFillPersonFill, BsSoundwave } from 'react-icons/bs';
import { BiTestTube , BiWorld} from 'react-icons/bi';

import {IoMdChatboxes, 
    // IoMdAttach
} from 'react-icons/io'

import {RiUserShared2Line} from 'react-icons/ri'
// ICONS //

// VIEWS //
import Test from './views/Test.view';
// import Dataview from './views/Dataview.view';
// VIEWS //

import { IconType } from 'react-icons';
import { Dict } from '@giveback007/util-lib';
import Profile from './views/Profile.view';
// import Discussions from './views/Discussions.view';
import Dashboard from './views/newdash/Dashboard.view';
import Chatrooms from './views/Chatrooms.view';
import Groups from './views/Groups.view';
// import Data from './views/Data.view';
import Authorizations from './views/Authorizations.view';
import Realtime from './views/Realtime.view';


export type RouteObj = {
    private: boolean; // later this will be role/permission based
    path: string;
    name: string;
    navIcon: IconType;
    component: React.ComponentType;
    dev?: boolean;
    userType: 'dev' | 'client' | 'personal'
};

// Can use this to moderate which routes are available
// based on user permissions
// https://levelup.gitconnected.com/role-based-authentication-with-react-router-and-typescript-b707af568ccf
const routes: Dict<RouteObj> = {
    dashboard: {
        path: "/dashboard",
        name: "Dashboard",
        navIcon: MdDashboard,
        component: Dashboard,
        private: true,
        userType: 'client'
    },
    // dataview: {
    //     path: "/dataview",
    //     name: "Data View",
    //     navIcon: GoGraph,
    //     component: Dataview,
    //     private: true,
    // },

    // discussions: {
    //     path: "/discussions",
    //     name: "Discussions",
    //     navIcon: MdChat,
    //     component: Discussions,
    //     private: true,
    //     userType: 'client'
    // },

    chats: {
        path: "/chats",
        name: "Chats",
        navIcon: IoMdChatboxes,
        component: Chatrooms,
        private: true,
        userType: 'client'
    },

    realtime: {
        path: "/realtime",
        name: "Real Time",
        navIcon: BsSoundwave,
        component: Realtime,
        private: true,
        userType: 'client'
    },

    groups: {
        path: "/groups",
        name: "Groups",
        navIcon: BiWorld,
        component: Groups,
        private: true,
        userType: 'personal'
    },

    // data: {
    //     path: "/data",
    //     name: "Data",
    //     navIcon: IoMdAttach,
    //     component: Data,
    //     private: true,
    //     userType: 'client'
    // },

    authorizations: {
        path: "/authorizations",
        name: "Authorizations",
        navIcon: RiUserShared2Line,
        component: Authorizations,
        private: true,
        userType: 'personal'
    },

    profile: { // turned into reference in settings
        path: "/profile",
        name: "Profile",
        navIcon: BsFillPersonFill,
        component: Profile,
        private: true,
        userType: 'client'
    },

    test: {
        path: "/test",
        name: "Test",
        navIcon: BiTestTube,
        component: Test,
        private: false,
        userType: 'dev'
    },
    // TODO: settings route
        // this is also where profile data will be
        // (like in discord)
}

export default routes;
