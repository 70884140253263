


import React, { Component } from 'react'
import { randomId } from '@myalyce/common';
//import {platform} from 'frontend/shared'
import { ContainerProps, CreatorProps, EventTileProps, removeComponentRefresh, removeContainerRefresh, setupComponentRefresh, setupContainerRefresh } from "./Templates.shared";
// import { DataTile} from './Data.components'
import {AttachmentCreator} from './Attachments.components'
import { eventApi } from 'frontend/apis/event.api';
import { eventTags, SeverityColors } from '@myalyce/common/data/client-events';
// import { Api } from 'frontend/apis/_socket.api';
// import { EventsTableSimple } from 'frontend/views/newdash/components/ClientEventsTable.component';
// import { ClientEventObj } from '@myalyce/common/models/client-event.model';
import { platform } from 'frontend/shared';
// import { DataTile } from './Data.components';
import { linker, store } from 'frontend/store/store';
import {ClientEventsTable, EventsTableSimple } from 'frontend/views/newdash/components/ClientEventsTable.component';
import { BsCalendarEvent } from 'react-icons/bs';
import { AddClientEventForm } from '../forms/ClientEventForm.component';
import { closeTopModal } from '../TopModal.component';

//import { FitBitView } from './FitBitDisplays.component';
//import { UserFitbit } from '@myalyce/common/models/user.model';

interface S {}

//rough event tiling, just to move to a platform-integrated system once dovy's done with the ideating
//

export class EventCreator extends Component<CreatorProps, S> {
        
    state = {
        id:randomId('eventCreator'),
        dataType:'',
        attaching:false,
        attachments:[] as any,
        emoji:'❗',
        parentUser:this.props.parentUser
    }

    constructor(props:CreatorProps) {
        super(props);   
    }


    componentDidMount() {

    }

    componentWillUnmount() {

    }


    updateAttachments = (attachment:any) => {
        //console.log(this.state.attachments, attachment);
        let updated = [...this.state.attachments,attachment];
        this.setState({attachments:updated});
    }


    _submit = () => {
        let typ = (document.getElementById(this.state.id+'_type') as HTMLInputElement)?.value;
        let notes = (document.getElementById(this.state.id+'_notes') as HTMLInputElement)?.value;

        let begval = ((document.getElementById(this.state.id+'_end') as HTMLInputElement)?.value as any); //should return an ISO String
        let beg;
        if(begval.length > 0) beg = new Date(begval).getTime();
        
        let endval = ((document.getElementById(this.state.id+'_end') as HTMLInputElement)?.value as any); //should return an ISO String
        if(endval.length === 0) endval = Date.now();
        let end = new Date(endval).getTime();
        
        let severity = (document.getElementById(this.state.id+'_scale') as HTMLInputElement)?.value
        let event = typ;
        if(typ === 'custom') event = (document.getElementById(this.state.id+'_custom') as HTMLInputElement)?.value;
        let att = this.state.attachments;

         this.props.platform.addEvent(
            this.props.parentUser,
            this.props.platform.currentUser._id,
            event,
            notes,
            beg,
            end,
            severity,
            att,
            this.props.platform.getLocalUserProviderIds(this.props.parentUser) //this is done automatically if left blank but just wanted to highlight the function
        );

        this.props.updateParent();
    }

    _onchange = () => {
        let value = (document.getElementById(this.state.id+'_type') as HTMLSelectElement).value;
        let emoji = '❗';
        eventTags.find((e)=>{
            if(e.name === value) {
                emoji = e.emoji;
                return true;
            } else return false;
        })
        this.setState({dataType:value, emoji:emoji});
    }

    _severity = () => {
        let severity = parseInt((document.getElementById(this.state.id+'_scale') as HTMLInputElement).value);
        (document.getElementById(this.state.id+'_icon') as HTMLDivElement).style.background = SeverityColors[severity];
    }

    // _onset = () => {
    //     let dataType = (document.getElementById(this.state.id+'_type') as HTMLSelectElement).value;
    //     let attachment = {dataType:dataType, data:(0 as any)};
    //     if(dataType === 'link'){
    //         let link = (document.getElementById(this.state.id+'_link') as HTMLInputElement).value;
    //         attachment.data = link;
    //     }
    //     if(dataType === 'notes'){
    //         let notes = (document.getElementById(this.state.id+'_notes') as HTMLInputElement).value;
    //         attachment.data = notes;
    //     }
        
    //     this.setState({attachments:[attachment,...this.state.attachments]});
    // }

    _attach = () => {
        this.setState({attaching: !this.state.attaching});
    }

    _splice = (i:number) => {
        let att = [...this.state.attachments];
        att.splice(i,1);
        this.setState({attachments:att});
    }

    _updateAttachments = (attachment:any) => {
        //console.log(this.state.attachments, attachment);
        let updated = [...this.state.attachments,attachment];
        this.setState({attachments:updated});
    }
    
    render() {
        return (
        <div className={this.props.editing ? "modal active" : "modal"} >
            <a  className="modal-overlay" aria-label="Close" onClick={() => {this.props.toggleEditor()}}></a>
            <div className="modal-container">
                <div className="modal-header">
                <a className="btn btn-clear float-right" aria-label="Close" onClick={() => {this.props.toggleEditor()}}></a>
                <div className="modal-title h5">New Event</div>
                </div>
                <div className="modal-body">
                    <div id={this.state.id+'_icon'}>{this.state.emoji}</div>
                    <div className="form-group">
                        <label className="form-label">Type</label>
                        <select id={this.state.id+'_type'} className="form-select" onChange={this._onchange}>
                            <option value='Exercise'>Exercise</option>
                            <option value='Sleep'>Sleep</option>
                            <option value='Meal'>Meal</option>
                            <option value='Snack'>Snack</option>
                            <option value='Other Routine'>Other Routine</option>
                            <option value='Medication'>Medication</option>
                            <option value='Emotional Event'>Emotional Event</option>
                            <option value='Mood Swings'>Mood Swings</option>
                            <option value='craving'>Craving</option>
                            <option value='Relapse'>Relapse</option>
                            <option value='Hospitalized'>Hospitalized</option>
                            <option value='Other'>Other</option>
                            {/* <option value='custom'>Custom Data</option> */}
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Severity</label>
                        <input id={this.state.id+'_scale'}  className="form-input" type='number' min='0' max='10' value='0' onChange={this._severity}></input>
                    </div>
                    { this.state.dataType === 'custom' &&
                        <div className="form-group">                                
                            <input id={this.state.id+'_custom'} className="form-input" type='text' placeholder='Custom Event'></input>
                        </div>
                    }
                    <div className="form-group">
                        <label className="form-label">Notes</label>
                        <textarea id={this.state.id+'_notes'}  className="form-input" placeholder='Notes'></textarea>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Begin (optional)</label>
                        <input id={this.state.id+'_begin'}  className="form-input" type="datetime-local" value={(new Date().toISOString())}></input>
                    </div>
                    <div className="form-group">
                        <label className="form-label">End</label>
                        <input id={this.state.id+'_end'}  className="form-input" type="datetime-local"></input>
                    </div>
                    {/* <button id={this.state.id+'_setattachment'} className="btn btn-primary" onClick={this._onset}>Set</button> */}
                <button id={this.state.id+'_add'} className="btn btn-primary" onClick={this._attach}>Add Attachments</button>
                { this.state.attaching && 
                        <AttachmentCreator
                            platform={this.props.platform}
                            parentUser={this.props.parentUser}
                            updateParent={this._updateAttachments}
                        />
                    } 
                <div id={this.state.id+'_attachments'}>
                { this.state.attachments.map((item: any) => 
                        <div className="tile tile-centered p-2">
                        <div className="tile-icon">
                        <div className="example-tile-icon">
                            <i className="icon icon-file centered"></i>
                        </div>
                        </div>
                        <div className="tile-content">
                        <div className="tile-title">{item.dataType[0].toUpperCase() + item.dataType.slice(1)}</div>
                        {item.data !== null && <small className="tile-subtitle text-gray">{item.data}</small>}
                        </div>
                            <div className="tile-action">
                                <button className="btn btn-link disabled ">Delete</button>                
                            </div>
                        </div>
                    )
                }
                </div>
            </div>
            <div className="modal-footer">
                <button id={this.state.id+'_submit'} className="btn btn-primary" onClick={this._submit}>Submit</button>
            </div>
        </div>
        </div>
        )
    }
}

export const openEventEditor = () => store.setState({ topModal: {
    title: 'New Event',
    body: <AddClientEventForm
        selectedClient={store.getState().selectedClient}
        onSubmitSuccess={closeTopModal}
        onCancel={closeTopModal}
    />,
    setUrlId: 'new_event'
} });


export class EventTile extends Component<EventTileProps, S> {
        
    state = {
        id:randomId('eventTile'),
        event:platform.eventProps,
        deleted:false,
        parentUser:this.props.parentUser
    };

    sub = 0;

    constructor(props:EventTileProps) {
        super(props);   
        this.state.event = this.props.event;
    }

    componentDidMount() {
        if(this.state.event?._id) {
            setupComponentRefresh(this,'event');
        }
    }

    componentWillUnmount() {
        if(this.state.event?._id) {
            removeComponentRefresh(this,'event');
        }
    }

    _delete = () => {
        this.props.platform.deleteDataFromServer([this.state.event._id]);
        this.setState({deleted:true});
    }

    _click = () => {}

    render() {

        //let event = new ClientEventObj(this.state.event) // Filter through Dovy's event
        //let table = EventsTableSimple({events:[event]})

        return (
            <div id={this.state.id}>
                {!this.state.deleted &&  EventsTableSimple({events: [this.state.event as any]})
                    // <div>
                    //     Event: <div>{this.state.event.event}</div>
                    //     Notes: <div>{this.state.event.notes}</div>
                    //     Began: <div>{this.state.event.begin}</div>
                    //     Ended: <div>{this.state.event.end}  </div>
                    //     Grade: <div>{this.state.event.grade}</div>
                    //     Attachments: {this.state.event.attachments?.map((item:any) => {
                    //         let d = Api(undefined).search({'_id':item, 'ownerId': this.state.event.ownerId})
                    //         if(!d) {
                    //             // NOTE: Replaced with API
                    //             // this.props.platform.getDataFromServer('dataInstance',this.state.event.ownerId,{'_id':item},undefined,undefined,(res:any) => { //does async work here? we'll find out!
                    //             Api(undefined).search({'_id':item, 'ownerId':this.state.event.ownerId}).then((res:any) => {
                    //                 this.props.platform.baseServerCallback(res);
                    //                 if(res.data[0]) d = res.data[0];
                    //                 setTimeout(()=>{this.render()},100);
                    //                 if (d.structType === 'dataInstance')
                    //                 { return <DataTile 
                    //                         platform={this.props.platform}
                    //                         parentUser={this.props.parentUser}
                    //                         dataInstance={d}
                    //                         />
                    //                 }
                    //                 else if (d.structType === 'event') {
                    //                     return <EventTile 
                    //                         platform={this.props.platform}
                    //                         parentUser={this.props.parentUser}
                    //                         event={d}
                    //                         />
                    //                 }
                    //                 return;
                    //             });
                    //         }
                    //         else if (d[0].structType === 'dataInstance')
                    //             { return <DataTile 
                    //                     platform={this.props.platform}
                    //                     parentUser={this.props.parentUser}
                    //                     dataInstance={d[0]}
                    //                     />
                    //             }
                    //         else if (d[0].structType === 'event') {
                    //             return <EventTile 
                    //                 platform={this.props.platform}
                    //                 parentUser={this.props.parentUser}
                    //                 event={d[0]}
                    //                 />
                    //         }
                    //         return;
                    //     })}
                    // </div>
                }
            </div>
        )
    }
}



class EventTileContainer extends Component<ContainerProps, S> {
        
    state = {
        id:randomId('events'),
        structs:this.props.structs,
        editing:false,
        parentUser:this.props.parentUser
    };

    editorRef = React.createRef<EventCreator>()
    structRefs = [React.createRef<EventTile>()]

    constructor(props:ContainerProps) {
        super(props);   
    }

    sub:number|undefined = 0

    componentDidMount() {
        //this.check();
        setupContainerRefresh(this,'event',this.sub);
    }

    componentWillUnmount() {
        removeContainerRefresh(this.sub)
    }

    check = async () => {
        let structs = ([] as any);
        if (this.props.structs){
            if(this.props.structs.length === 0) {
                structs = await eventApi.search({'ownerId': this.props.parentUser._id})
            }
            if(structs.length > 0) {
                this.structRefs = [];
                structs.forEach(()=>{
                    this.structRefs.push(React.createRef<EventTile>());
                });
                this.setState({structs:structs});
            }
        }
    }

    _click = () => {
        if(this.state.editing) this.setState({editing:false});
        else this.setState({editing:true});
        this.check();
    }

    _refresh = () => {
        this.check();
    }

    render() {

        let eventsToView = (this.props.structs) ? this.props.structs.filter(o => o.ownerId === this.props.parentUser?._id) : []


        return (
            <div id={this.state.id} className="panel">
                <div className="panel-header">
                    <div className="panel-title h6 float-left">Events</div>
                    <div className="float-right">
                        <button className="btn btn-primary mr-2 btn-sm" onClick={this._click}>New</button>
                        <button className="btn btn-secondary btn-sm"  onClick={this._refresh}>Refresh</button>
                    </div>
                </div>
                <div className="panel-body">
                    <div>
                {this.state.editing &&
                    <EventCreator 
                        platform = {this.props.platform}
                        parentUser = {this.props.parentUser}
                        ref = {this.editorRef}
                        updateParent={this._click}
                        editing = {this.state.editing}
                        toggleEditor = {this._click}
                    />
                }
                </div>
                <div>
                { eventsToView?.map((item: any, idx:number) => 
                        <EventTile 
                            platform = {this.props.platform}
                            parentUser = {this.props.parentUser}
                            event = {item}
                            ref = {this.structRefs[idx]}
                        />
                    )
                }
                </div>
                </div>
                <div className="panel-footer"/>
            </div>
        )
    }
}


export default linker((s) => ({ structs: s.event }), EventTileContainer);

class EventTileDisplayClass extends EventTileContainer {

    constructor(props:ContainerProps) {
        super(props);   
    }

    emptyState = (p: { isLoading: boolean; }) => <div className="empty">
        <div className="empty-icon">
            {p.isLoading ? <div className="loading" /> : <BsCalendarEvent className="icon" />}
        </div>
        <p className="empty-title h5">{p.isLoading ? 'Loading events' : 'Client has no recorded events'}</p>
        <p className="empty-subtitle">Click button to add client event.</p>
        <div className="empty-action">
            <button
                disabled={false}
                onClick={this._click}
                className={`btn btn-primary${p.isLoading ? ' loading' : ''}`}
            >Add Event</button>
        </div>
    </div>

    render() {

        let eventsToView = (this.props.structs) ? this.props.structs.filter(o => o.ownerId === this.props.parentUser?._id) : []

        return (
            <>
             {this.state.editing &&
                <EventCreator
                    platform={this.props.platform}
                    parentUser={this.props.parentUser}
                    ref={this.editorRef}
                    updateParent={this._click}
                    editing={this.state.editing}
                    toggleEditor={this._click}
                />
            }

            {eventsToView.length > 0 ? <>
            <div className="pt-2 px-2">
                {/* {eventsToView.map((item: any, idx: number) =>
                        <EventTile
                            platform={this.props.platform}
                            parentUser={this.props.parentUser}
                            event={item}
                            ref={this.structRefs[idx]}
                        />
                    )
                } */}
                <ClientEventsTable events={eventsToView} clientId={this.props.parentUser._id} useAddEventButton={true} type={'simple'}></ClientEventsTable>
            </div> 
            <div className="panel-footer">
                    <button
                        className={`btn btn-primary btn-block`}
                        onClick={this._click}
                    >Add Event</button>
                </div>
            </>
            
            : this.emptyState({isLoading: false})}
            </>
        )
    }
}

export const EventTileDisplay = linker((s) => ({ structs: s.event }), EventTileDisplayClass);
