import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
// import { errCss, ErrMsg } from '../../views/dashboard/dashboard.util';
import { sec } from '@giveback007/util-lib';
import { clientEventApi } from 'frontend/apis/client-event.api';
import { store, UserObj_S } from 'frontend/store/store';
import { Action } from 'frontend/store/actions';
import { eventTags, getEventTag } from '@myalyce/common/data/client-events';
import { ClientEventObj } from '@myalyce/common/models/client-event.model';
import { capFirst } from 'frontend/utils/general';
import { Chip } from 'frontend/components/utils/component.utils';
import { closeTopModal } from 'frontend/components/TopModal.component';
import { addNotification } from 'frontend/utils/store.utils';
import { errCss, ErrMsg } from 'frontend/views/dashboard/dashboard.util';

export const openClientEventFormModal = () => store.setState({ topModal: {
    title: 'New Event',
    body: <AddClientEventForm
        selectedClient={store.getState().selectedClient}
        onSubmitSuccess={closeTopModal}
        onCancel={closeTopModal}
    />,
    setUrlId: 'new_event'
} });

type FormData = {
    title: string;
    timeOfEvent: string;
    tag: string;
    notes: string;
    negSeverity: number;
    status: ClientEventObj['status'];
    endTime?: string;
}

type addEventProps = { selectedClient: UserObj_S | null; onSubmitSuccess: () => any; onCancel: () => any };
export function AddClientEventForm(p: addEventProps) {
    const sc = p.selectedClient;
    if (!sc) return <h3>No client selected</h3>;

    const [loading, loadState] = useState(false);

    const onSubmit: SubmitHandler<FormData> = (data) => {
        const { currentUser: provider} = store.getState();
        if (!provider)
            return addNotification({ text: 'Something went wrong, please refresh and try again', type: 'error' });

        loadState(true);
        const ownerId = sc._id;
        const send = { ...data, ownerId, submittedBy: provider._id };

        clientEventApi.create(send).then((res) => {
            loadState(false);
            if (res.type === 'ERROR') {
                addNotification({ text: 'Failed to create event, please check for errors and try again', type: 'error' }, sec(15));
            } else {
                addNotification({ text: `Added event for ${sc.fullName || sc.codeName}`, type: 'success' }, sec(7.5));
                p.onSubmitSuccess();
                store.action({ type: Action.clientEventsLoad, data: { clientId: ownerId } });
            }
        })
    }

    const { register, handleSubmit, formState, watch } = useForm<FormData>();
    
    const { errors: err } = formState;
    const tag = getEventTag(watch('tag'), watch('negSeverity') || 0);
    const status = watch('status');
    const endTimeRequired = status === 'done';

    return <>
        <div className="form-horizontal">

            {/* Tag Chip */}
            <div style={{display: 'flex'}}><Chip
                img={{ initials: tag.emoji || '?', backgroundColor: tag.color }}
                label={tag.name}
                style={{margin: 'auto', marginBottom: '0.5rem'}}
            /></div>

            {/* Tag */}
            <div className={errCss('form-group', err.tag)}>
                <div className="col-3">
                    <label className="form-label">Tag *</label>
                </div>
                <select
                    className="form-select col-9"
                    disabled={loading}
                    {...register('tag', { required: 'Please select a tag for the event.' })}
                >
                    <option></option>
                    {eventTags.map((tag) => <option>{tag.name}</option>)}
                </select>
                <ErrMsg err={err.tag} />
            </div>

            {/* Title/Subject */}
            <div className={errCss('form-group', err.title)}>
                <div className="col-3">
                    <label className="form-label">Title/Subject *</label>
                </div>
                <input
                    className="form-input col-9"
                    placeholder="Title/Subject"
                    disabled={loading}
                    type="text"
                    {...register('title', { required: 'Please input a title/subject for the event.' })}
                />
                <ErrMsg err={err.title} />
            </div>

            {/* Time */}
            <div className={errCss('form-group', err.negSeverity)}>
                <div className="col-3">
                    <label className="form-label">Severity *</label>
                </div>
                <input
                    className="form-input col-9"
                    disabled={loading}
                    min="0"
                    max="10"
                    type='number'
                    {...register('negSeverity', {
                        required: 'Please add severity, 0 to 10.',
                        min: {value: 0, message: "Can't be less than 0"},
                        max: {value: 10, message: "Can't be more than 10"}
                    })}
                />
                <ErrMsg err={err.negSeverity} />
            </div>

            {/* Time */}
            <div className={errCss('form-group', err.timeOfEvent)}>
                <div className="col-3">
                    <label className="form-label">Time *</label>
                </div>
                <input
                    className="form-input col-9"
                    disabled={loading}
                    type="datetime-local"
                    {...register("timeOfEvent", { required: 'Please add time of event.' })}
                />
                <ErrMsg err={err.timeOfEvent} />
            </div>

            {/* Status */}
            <div className={errCss('form-group', err.status)}>
                <div className="col-3">
                    <label className="form-label">Status *</label>
                </div>
                <select
                    className="form-select col-9"
                    disabled={loading}
                    {...register('status', { required: 'Please select a tag for the event.' })}
                >{['', 'ongoing', 'done', 'immediate'].map((name) =>
                    <option value={name}>{capFirst(name)}</option>)
                }</select>
                <ErrMsg err={err.status} />
            </div>

            {/* End Time */}
            {endTimeRequired && <div className={errCss('form-group', err.endTime)}>
                    <div className="col-3">
                    <label className="form-label">End Time *</label>
                </div>
                <input
                    className="form-input col-9"
                    disabled={loading}
                    type="datetime-local"
                    {...register('endTime', { required: {
                        value: endTimeRequired,
                        message: 'Please add an end date for the event'
                    } })}
                />
                <ErrMsg err={err.endTime} />
            </div>}

            {/* Notes */}
            <div className="form-group">
                <label className="form-label">Notes</label>
                <textarea
                    className="form-input"
                    placeholder="Notes related to the event"
                    disabled={loading}
                    {...register('notes', { required: false })}
                />
            </div>
        </div>

        <button
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
            className={`btn btn-primary${loading ? ' loading' : ''}`}
        >Submit</button>
        <button
            className="btn btn-link"
            aria-label="Cancel"
            disabled={loading}
            onClick={p.onCancel}
        >Cancel</button>
    </>
}
