import './init';
import './styles/spectre.css/';
import './styles/spectre.css/dist/spectre-exp.min.css';

import './index.scss';

import React from 'react'
import ReactDOM from 'react-dom';
import App from './app';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store/store';
import { Action } from './store/actions';

log('ENV:', ENV);
log('SERVER_URI:', SERVER_URI);

/** Code to execute while app is loading */
(function bootstrap() {
  store.action({ type: Action.urlParamsChanged });
  store.action({ type: Action.loginRefresh });
})();

ReactDOM.render(
    <BrowserRouter>
      <App/>
    </BrowserRouter>,
    document.getElementById('root'),
);

// css z-index order:
  // DisplayNotify          - 1002    // DisplayNotify.module.scss
  // TopModal               - 1001    // index.scss #top-modal
  // NavDrawer              - 1000    // NavDrawer.module.scss
  // NavDrawerBackdrop      - 999     // NavDrawer.module.scss
  // SelectedClient (badge) - 998     // SelectedClient.module.scss
  // SelectedClient (select)- 997     // SelectedClient.module.scss
  // TopBar                 - 996     // TopBar.module.scss
